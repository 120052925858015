.boiloff-table-container {
    .lng-pie {
        height: 228px;
        width: 263px;
    }
    .light-table-flex-wrapper {
        width: 450px;
    }
    .floating-port-display {
        top: 25px;
        left: 100px;
        position: absolute;
        .origin-label {
            font-size: 14px;
            color: #bfccd6;
        }
        .origin-value {
            font-size: 24px;
            margin-left: 20px;
        }
    }
}

.boiloff-table-container-row {
    display: flex;
    align-items: center;
    position: relative;

    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    > div:not(:last-child) {
        margin-right: 10px;
    }
    > div:not(:first-child) {
        margin-left: 10px;
    }
}
