@import "./Color";

@font-face {
    font-family: "Aeonik";
    src: url("../assets/fonts/Aeonik-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Aeonik";
    src: url("../assets/fonts/Aeonik-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Aeonik";
    src: url("../assets/fonts/Aeonik-RegularItalic.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Aeonik";
    src: url("../assets/fonts/Aeonik-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Aeonik";
    src: url("../assets/fonts/Aeonik-SemiBold.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Aeonik";
    src: url("../assets/fonts/Aeonik-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

$font-danelec: Helvetica, sans-serif;
// $font-danelec: "Aeonik", Helvetica, sans-serif;
$font-nl-main:
    "Nunito",
    -apple-system,
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Open Sans",
    "Helvetica Neue",
    "Icons16",
    sans-serif;
$font-nl-roboto: "Roboto", sans-serif;
$font-nl-avenir-next: "Avenir Next", sans-serif;

@mixin apply-font($font-family: $font-nl-main) {
    font-family: $font-family;

    @at-root .danelec & {
        font-family: $font-danelec;
    }
}

%heading-xxl-bold {
    @include apply-font($font-nl-roboto);
    font-size: 56px;
    font-weight: 700;
}

%heading-xl {
    @include apply-font($font-nl-roboto);
    font-size: 36px;
    font-weight: 200;
}

%heading-large {
    @include apply-font($font-nl-roboto);
    font-size: 20px;
    font-weight: 200;
}

%heading-medium {
    font-size: 16px;
    font-weight: 400;
}

%heading-small {
    font-size: 14px;
    font-weight: 500;
}

%body-large-bold {
    font-size: 20px;
    font-weight: 700;
}

%body-medium {
    font-size: 14px;
    font-weight: 400;
}

%body-small {
    font-size: 12px;
    font-weight: 500;
}

%body-xs {
    font-size: 10px;
    font-weight: 500;
}

%number {
    @include apply-font($font-nl-roboto);
}

%unit {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
}

%unit-light {
    @extend %unit;
    font-weight: 200;
    color: $nl-unit-light;
}

%small-unit {
    font-size: 12px;
    color: $nl-unit-light;
    line-height: 12px;
    font-weight: 200;
    font-style: italic;
}

%currency {
    @extend %unit;
    color: $nl-tertiary;
}

%secondary {
    font-size: 14px;
    font-weight: 400;
    color: $nl-secondary;
}

%footnote {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
}

%footnote-secondary {
    @extend %footnote;
    color: var(--color-secondary);
}

%text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

%tooltip-content {
    text-decoration: underline dotted;
    text-underline-offset: 0.2em;
    cursor: help;
}

%tooltip-label {
    border-bottom: 2px dotted $nl-primary;

    &:hover {
        cursor: help;
    }
}

// Consistent spacing for inline elements based on https://jkorpela.fi/styles/spaces.html
%space-left {
    margin-left: 0.25em;
}

%space-left-italic {
    margin-left: 0.2778em;
}
