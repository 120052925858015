@import "../../styles/Color";

.nl-file-input-label {
    display: block;
    cursor: pointer;

    &:hover {
        > button {
            background-color: $nl-dark-gray-4 !important;
        }
    }

    > button {
        pointer-events: none;
    }
}

#nl-file-input {
    position: fixed;
    top: -100em;
}
