@import "../../../styles/Color";
@import "../../../styles/Text";

.route-select {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;

    h3 {
        margin: 0;
        font-weight: 400;
    }

    .instructions {
        @extend %footnote-secondary;
    }

    .list-map-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        background-color: var(--background-inset);
        box-shadow:
            0 0 0 0 rgba(76, 144, 240, 0),
            0 0 0 0 rgba(76, 144, 240, 0),
            0 0 0 0 rgba(76, 144, 240, 0),
            inset 0 0 0 1px rgba(255, 255, 255, 0.2),
            inset 0 -1px 1px 0 $nl-gray-3;
        padding: 10px 0;

        .list-container {
            height: 100%;
            width: 50%;
            margin-left: 1px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .table-container {
                max-height: 450px;
                width: 100%;
                overflow-y: auto;

                table {
                    width: 100%;

                    thead {
                        height: 20px;

                        th {
                            background-color: var(--background-inset);
                            position: sticky;
                            z-index: 1;
                            vertical-align: bottom;
                            font-weight: 200;
                            color: $nl-gray-5;
                        }

                        tr:not(.divider) {
                            height: 20px;
                            th {
                                top: 0;
                            }
                        }

                        .divider {
                            height: 1px;
                            th {
                                z-index: 2;
                                top: 20px;
                            }
                        }
                    }

                    tbody tr {
                        height: 40px;

                        &:hover {
                            background-color: $nl-light-gray-hover;
                            cursor: pointer;
                        }

                        &.odd {
                            background-color: var(--background-inset);
                        }

                        &.even {
                            background-color: var(--background-table-row-light);
                        }

                        &.disabled {
                            cursor: not-allowed;
                            opacity: 0.5;
                        }

                        &.selected {
                            color: var(--item-active);
                            background: var(--item-active-background);

                            .specifically-enabled svg {
                                fill: white;
                            }
                        }

                        &.child {
                            td {
                                color: $nl-gray-5;

                                &:nth-child(2) {
                                    padding-left: 1em;
                                }
                            }
                        }

                        .route-action-buttons {
                            display: flex;
                            flex-direction: row;
                        }
                    }

                    th,
                    td {
                        color: $white;
                        text-align: left;

                        &:first-child {
                            padding-left: 1em;
                        }

                        &:last-child {
                            padding-right: 20px;
                        }

                        &:not(:last-child) {
                            padding-right: 1em;
                        }

                        &.toggle {
                            width: 30px;
                            padding-right: 0.5em;
                        }
                    }
                }
            }
            .new-passage-plan-button {
                margin-left: 10px;
            }
        }

        .preview-container {
            height: 100%;
            flex: 1;
            padding-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.upload-route-dialog {
    width: 70%;
    height: 80dvh;

    .passage-plan-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px;

        .inputs {
            .form-input-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0.5em;

                &:last-child {
                    margin-bottom: 1em;
                }

                .label {
                    display: inline;
                    width: 200px;
                }

                .text-input-group {
                    display: flex;
                    align-items: center;

                    label {
                        margin-bottom: 0;
                    }

                    .input {
                        width: 250px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .route-create-panel-sim {
            height: 100%;
            flex: 1;

            .side {
                padding: 0;
            }

            .left.side {
                padding-right: 15px;

                & > * {
                    flex: 0;
                }

                .code-area {
                    flex: 1;
                    max-height: 350px;
                }
            }
        }
    }
}
