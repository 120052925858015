@import "../../../styles/Color";

.cargo-composition-table {
    margin-top: 1em;
    margin-left: 20px;

    tr {
        &:not(:last-of-type) {
            border-bottom: 1px solid var(--accent-dark);
        }

        th {
            padding-right: 3em;
        }

        td {
            padding: 0.5em 3em 0.5em 0;

            &.molecule {
                font-weight: bold;
            }
        }

        th,
        td {
            &:not(:first-of-type) {
                text-align: right;
            }
        }
    }
}
