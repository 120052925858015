@import "../../../styles/Color.scss";
@import "../../../styles/Text";

.route-create-panel-sim {
    display: grid;
    grid-template-columns: 542px 1fr;

    & * {
        font-weight: 200;
    }

    & > .side {
        width: 100%;
        height: 100%;
        padding: 20px;

        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    & > .left {
        display: flex;
        flex-direction: column;

        overflow: hidden;

        padding-bottom: 41px;

        & > * {
            flex: 1 0 auto;
        }

        & .instructions {
            @extend %body-small;
            color: $nl-secondary;
        }

        & .code-area {
            flex-shrink: 1;
            max-height: 300px;
        }

        & .generate-preview {
            align-self: flex-end;
        }
    }

    & > .right {
        border-left: solid 1px $nl-dark-gray-1;

        padding: 10px;

        display: flex;
        flex-direction: column;

        & > .preview-container {
            flex-grow: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            & > .preview-placeholder {
                @extend %body-medium;
                color: $nl-secondary;

                width: 320px;
                text-align: center;
            }
        }
    }
}
