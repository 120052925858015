%pillbox-button {
    min-height: unset;
    font-size: 12px;
    font-weight: 600;
    border-radius: 25px;
}

$icon-button-width: 30px;
$button-space-between: 5px;

$add-column-button-width: 115px;
$table-actionables-width: $add-column-button-width + 2 * ($icon-button-width) + 3 * ($button-space-between);

.voyage-column-template-picker-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .voyage-column-templates-wrapper {
        width: calc(100% - #{$table-actionables-width});
        flex: 1 1;
        overflow: auto hidden;
        // Intentionally inverted background and color
        scrollbar-color: var(--scrollbar-background) var(--scrollbar-color);

        .voyage-column-templates {
            min-width: max-content;

            button {
                &:not(:first-child) {
                    margin-left: 10px;
                }

                &.pillbox {
                    @extend %pillbox-button;

                    &.selected {
                        cursor: default;
                    }
                }
            }
        }
    }

    .voyage-column-template-actions {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        margin-left: $button-space-between;
        line-height: $icon-button-width;

        > * {
            margin-right: $button-space-between;
        }
    }
}
