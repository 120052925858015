@import "./NlTableFilter";

.nl-table-date-filter-wrapper {
    @extend %nl-table-filter-wrapper;

    .nl-table-date-filter-container {
        .nl-table-date-filter-footer-container {
            width: $nl-table-filter-content-width;
            padding: 0 5px;

            .nl-table-date-filter-footer {
                padding: 5px 10px;
                word-break: break-word;
                font-style: italic;
            }
        }
    }
}
