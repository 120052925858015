.company-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    margin-right: 1em;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.company-logo {
    height: 30px;
}
