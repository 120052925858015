@import "../styles/Color";

.nl-save-confirm-dialog-body-container {
    display: flex;
    flex-direction: row;

    .overlay-body-icon {
        width: 40px;
        height: 40px;
        margin: 5px;
        color: $nl-gray-5;
    }

    .overlay-body-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5px;
    }
}
