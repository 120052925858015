@import "../../styles/Buttons";

.nl-date-picker-container {
    display: flex;
    flex-direction: column;
    padding-top: 15px;

    .timezone-picker {
        padding-left: 15px;
    }

    .instruction-value-save {
        padding: 0 15px 15px;
    }

    #nl-date-picker-save {
        @extend %primary-button;
    }
}
