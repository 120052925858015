@import "../../styles/Buttons";
@import "../../styles/Color";
@import "../../styles/Input";
@import "../../styles/Text";

.fuel-composition-form {
    padding: 2em 2em calc(1em + 5px) 2em;

    .form-input-row {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        .tooltip {
            width: 52.5%;
            margin-right: 1em;

            .label {
                padding-right: 2px;
                &.error {
                    border-bottom: 1px solid $nl-danger;
                }
            }
        }

        .input-unit {
            .input {
                @extend %number-hide-arrows;

                &.error {
                    border-color: $nl-danger;
                }

                // These should probably live in a ShipSelect style sheet but I didn't want to mess with Events
                &.all-ship-select {
                    border: 1px solid rgb(117, 116, 122);

                    &.error {
                        border-color: $nl-danger;
                    }
                }

                &.file.error span {
                    border: 1px solid $nl-danger;
                    box-shadow: none;
                }
            }

            .unit {
                @extend %unit;
                margin-left: 0.5em;
                font-size: 0.9em;
                letter-spacing: 0.1em;
                color: $nl-secondary;
            }
        }

        .date-select-popover-wrapper {
            width: 188px;
        }
    }

    .error-msg {
        color: $nl-danger;
        margin: 0 0 0 1em;

        &:last-child {
            margin: 0.5em 0;
        }
    }

    .submit-btn,
    .saving-spinner {
        margin-top: 1em;
        float: right;
    }

    .submit-btn {
        @extend %primary-button;
    }
}

// This should probably live in a ShipMenu style sheet but I didn't want to mess with Events
.event-ship-select {
    max-height: 300px;
    overflow-y: auto;

    .ship-select-filters {
        display: flex;
        align-items: center;

        .active-switch {
            flex: 1;
            margin-bottom: 0;
            margin-left: 10px;
        }
    }
}
