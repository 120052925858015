@import "../../../../styles/Color.scss";
@import "../../../../styles/Header";

.form-section {
    .form-section-title {
        @extend %page-header-style;
        height: auto;
        padding: 5px 0;
        border-bottom: 1px solid $nl-dark-gray-1;
    }

    .form-section-fields {
        padding: 10px 0px 0;
    }
}
