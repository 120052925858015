@import "../../nlcomponents/nl";
@import "../../styles/Color";
@import "../../styles/Events";

// TODO: move this to navigation/Header.scss ?
$nav-header-height: 60px;

$summary-table-control-bar-height: 37px;
$card-metrics-panel-width: 550px;

.fleet-widget-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - #{$nav-header-height});

    &.card-metrics-panel-open {
        .fleet-summary-container {
            width: calc(100% - #{$card-metrics-panel-width});
        }
    }

    .fleet-summary-container {
        width: 100%;
        height: 100%;

        // TODO: move to FleetMap.scss
        .fleet-map-container {
            position: relative;
            width: 100%;
            height: 50%;

            .fleet-map {
                width: 100%;
                height: 100%;

                .ship-label {
                    font-size: 12px;
                    white-space: nowrap;
                    background-color: white;
                    color: #30404d;
                    padding: 2px 4px;
                    border-radius: 1px;
                    box-shadow: 0 0 2px 0 #6b7d91;
                    z-index: 98;

                    &.starred {
                        background-color: #fbda88;
                        z-index: 99;
                    }

                    &.out-of-focus {
                        opacity: 0.3;
                        z-index: 97;
                    }
                    > :hover {
                        z-index: 100;
                    }
                }

                .hovered {
                    z-index: 100;
                }

                &.weather {
                    .ol-zoom {
                        bottom: 67px;
                    }

                    .ol-attribution {
                        bottom: 40px;
                    }
                }

                .ol-zoom {
                    bottom: 27px;
                }

                .ol-attribution {
                    bottom: 0;
                }

                // TODO: move this to ForecastSlider component
                .forecast-slider {
                    display: flex;
                    width: 450px;
                    color: black;
                    margin: 12px 10px;

                    svg {
                        cursor: pointer;
                    }

                    span.bp4-popover-target {
                        vertical-align: middle;
                    }

                    .bp4-slider-axis {
                        .bp4-slider-label {
                            display: none;
                        }
                    }
                    .bp4-slider-handle {
                        top: 2px;
                        background-color: white !important;

                        .bp4-slider-label {
                            bottom: 45px;
                        }

                        &:focus {
                            outline: transparent;
                        }

                        .bp4-slider-label {
                            white-space: nowrap;
                        }
                    }
                    .bp4-slider-track {
                        top: 6px;

                        .bp4-slider-progress {
                            border-left: 1px white solid;
                            background-color: #669eff !important;
                            border-right: 1px white solid;
                        }
                    }
                }
            }
        }

        .fleet-summary-table-container {
            width: 100%;
            height: 50%;

            // TODO: move to SummaryTableControlBar.scss
            .fleet-summary-table-control-bar-container {
                width: 100%;
                height: $summary-table-control-bar-height;

                .summary-table-control-bar {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 1px 8px;
                    align-items: center;
                    line-height: $summary-table-control-bar-height;
                    background-color: var(--background-panel);

                    .summay-table-control-button-container {
                        height: 25px;
                        line-height: 25px;

                        .bp4-popover-wrapper {
                            height: 25px;
                        }

                        .summary-table-control-button {
                            @extend %nl-tag-button;
                            line-height: 24px;
                        }
                    }

                    .summary-table-gcu-switch {
                        margin: 0;
                        font-size: inherit;
                    }
                }
            }

            // TODO: move to FleetSummaryTable.scss
            .fleet-summary-table {
                width: 100%;
                height: calc(100% - #{$summary-table-control-bar-height});

                .star-button {
                    color: #6b7d91;
                    line-height: 35px;

                    &:hover {
                        color: #bfccd6;
                    }

                    &.active {
                        color: #fbda88;

                        &:hover {
                            color: #f2b824;
                        }
                    }
                }

                .hover {
                    .vessel-name-container {
                        position: relative;
                    }

                    .name {
                        .action-icon-container {
                            visibility: visible;
                            position: absolute;
                            right: 0;
                            top: 0;
                            background-color: var(--background-table-row-hover);

                            .action-icon {
                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
                .name > span {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .action-icon-container {
                        visibility: hidden;

                        .action-icon {
                            margin: 0px 8px;
                            line-height: 35px;
                        }
                    }
                }

                .events-counter-cell {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 100%;

                    span {
                        position: absolute;
                        display: flex;
                        flex-direction: row;

                        &.issue {
                            width: 50px;
                            left: 0;
                        }
                        &.maintenance {
                            width: 50px;
                            left: 50px;
                        }
                        &.general {
                            width: 50px;
                            left: 100px;
                        }
                    }

                    .event-marker {
                        @extend %nl-event-circle-marker;
                        margin-top: 3px;
                        margin-left: 3px;
                    }
                }

                .composite-fuel-consumption-cell-content {
                    display: flex;
                    flex-direction: row;

                    .divider {
                        width: 10px;
                        color: $nl-gray-3;
                    }

                    .fuel-consumption-value {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        padding-right: 5px;
                        width: 70px;

                        &.percent-difference {
                            width: 95px;
                        }
                    }
                }

                .total-and-expected-fuel-consumption-cell-content {
                    .expected-consumption-value {
                        color: $nl-gray-3;
                    }

                    .percent-sign {
                        color: $nl-gray-3;
                        margin-left: 4px;
                    }

                    .green-total-consumption {
                        color: $nl-table-cell-text-green;
                    }

                    .yellow-total-consumption {
                        color: $nl-table-cell-text-orange;
                    }

                    .red-total-consumption {
                        color: $nl-table-cell-text-red;
                    }
                }
            }
        }
    }

    .fleet-card-metrics-panel-container {
        width: $card-metrics-panel-width;
        height: 100%;
        z-index: 10;
    }
}
