@import "../../styles/Color";
@import "../../styles/Text";

$widget-header-height: 50px;
$widget-header-margin-bottom: 1px;

$filter-select-height: 30px;
$filter-select-margin-bottom: 10px;

.voyage-summary-widget {
    padding: 5px;
    height: 100%;

    .widget-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: $widget-header-height;
        margin-bottom: $widget-header-margin-bottom;
        padding: 0 15px;
        background-color: var(--background-panel);

        .widget-title-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .widget-title {
                @include apply-font($font-nl-avenir-next);
                font-size: 16px;
                font-weight: 300;
            }
        }

        .gcu-toggle {
            margin-bottom: 0;
        }
    }

    .widget-body-container {
        height: calc(100% - #{$widget-header-height} - #{$widget-header-margin-bottom});
        padding: 30px 50px;
        background-color: var(--background-panel);

        .voyage-filter-select-container {
            display: flex;
            flex-direction: row;
            height: $filter-select-height;
            margin-bottom: $filter-select-margin-bottom;
            // background-color: var(--background-panel-light);
        }

        .voyage-summary-table-container {
            width: 100%;
            height: calc(100% - #{$filter-select-height} - #{$filter-select-margin-bottom});
        }
    }
}
