@import "../../styles/Buttons";
@import "../../styles/Color";
@import "../../styles/Input";
@import "../../styles/Text";

.ports-form {
    padding: 2em 2em calc(1em + 5px) 2em;

    .form-input-row {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        .tooltip {
            width: 30%;
            margin-right: 1em;

            .label {
                padding-right: 2px;

                &.has-tooltip {
                    @extend %tooltip-label;
                }

                &.error {
                    border-bottom: 1px solid $nl-danger;
                    cursor: default !important;
                }
            }
        }

        .input-unit {
            width: 70%;

            textarea {
                resize: vertical;
            }

            .input {
                @extend %number-hide-arrows;

                &.long {
                    width: 100%;
                }
                &.error {
                    border-color: $nl-danger;
                }
            }
        }
    }

    .error-msg {
        color: $nl-danger;
        margin: 0 0 0 1em;

        &:last-child {
            margin: 0.5em 0;
        }
    }

    .submit-btn,
    .saving-spinner {
        margin-top: 1em;
        float: right;
    }

    .submit-btn {
        @extend %primary-button;
    }
}
