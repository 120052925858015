@import "../../../styles/Text";

.voyage-leg-selector {
    &.error {
        border: 1px solid $nl-danger;
    }

    .header-node {
        margin: 0;

        &.leg-selector {
            margin-left: 10px;
        }
    }

    .voyage-leg-header-wrapper {
        display: flex;

        .leg-header-wrapper {
            display: flex;
            flex-direction: row;

            .name {
                min-width: 50px;
            }

            .dates {
                @extend %secondary;
                margin-left: 5px;
            }
        }

        .ports {
            margin-left: 1em;
        }
    }
}
