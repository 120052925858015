@import "../../styles/Text";

.row-heading {
    @extend %heading-medium;
    margin-bottom: 10px;
}

.content-leading-text {
    width: 80px;
    margin-right: 20px;
}

.instruction-row {
    .text-input {
        margin-right: 40px;
    }
}

.notes-body {
    height: 100px;
    width: 100%;
}

.email-type-container {
    margin-bottom: 10px;
}
