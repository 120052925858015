@import "../../styles/Text";
@import "../../styles/Color";

.bar-chart-card {
    .header-container {
        .header {
            display: flex;
            flex-direction: row;

            .header-content {
                flex: 1;
            }

            .bp4-popover-wrapper {
                height: 16px;

                .bp4-popover-target {
                    display: inline-flex;
                    height: 16px;

                    .bar-chart-card-menu-button {
                        @extend %body-small;
                        color: $nl-secondary;
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
