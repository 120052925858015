@import "../../styles/Events";
@import "../../styles/Text";

.marker-text-wrapper {
    display: flex;
    flex-direction: row;

    .event-marker {
        @extend %nl-event-circle-marker;
        margin-top: 3.5px;
    }

    .following-text {
        @extend %body-small;
        margin-left: 3px;
        margin-top: 2px;
    }
}
