@import "../../styles/Color";
@import "../../styles/Header";
@import "../../styles/Text";

$small-map-height: 260px;
$large-map-height: 400px;

/* move map zoom buttons to bottom-left when small map to prevent overlap */
.small .bottom .ol-zoom {
    bottom: 0;
    left: 10px;
    right: unset;
}

.small .weather .bottom .ol-zoom {
    bottom: 40px;
}

.charter-widget-container {
    @extend %page-container-style;

    .charter-widget-header {
        @extend %page-header-style;
    }

    .charter-widget-body {
        @extend %page-body-style;

        .charter-map {
            width: 100%;
            height: $small-map-height;
            padding: 7px;

            &.large {
                height: $large-map-height;
            }
        }

        .charter-widget {
            width: 100%;
            height: calc(100% - #{$small-map-height});
            overflow-x: hidden;
            overflow-y: scroll;

            &.with-large-map {
                height: calc(100% - #{$large-map-height});
            }
        }
    }
}

.charter-widget {
    .charts-panel,
    .summary-panel {
        flex: 1;
        display: flex;
        margin: 0px 7px 7px 7px;
        flex-direction: column;
    }

    .charts-panel div.panel-body {
        flex-direction: column;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 1px;
    }

    .summary-panel {
        .cpa-description {
            color: rgb(191, 204, 214);
            padding: 10px 50px;
        }

        .summary-panel-body {
            min-height: 130px;
            display: flex;
            background-color: var(--background-panel);
        }

        .panel-title {
            .bp4-tab {
                font-size: 1.3rem;
            }
            .bp4-tab:focus {
                outline: 0;
            }
            .bp4-tab-indicator {
                margin-bottom: -8px;
                background-color: $nl-cobalt-4;
            }
            .bp4-tab-list > *:not(:last-child) {
                margin-right: 60px;
            }
            .bp4-tab[aria-selected="false"][aria-disabled="false"] {
                color: $nl-disabled;
            }
            .bp4-tab[aria-selected="true"],
            .bp4-tab:not([aria-disabled="true"]):hover {
                color: white;
            }
        }

        .consumption-table,
        .distance-table {
            width: 100%;
            table {
                width: 100%;
            }

            font-size: 14px;
            font-weight: 300;
            padding: 20px 50px;

            th {
                color: #bfccd6;
                height: 30px;
                padding: 10px;
            }

            td:first-child {
                @include apply-font();
            }

            td {
                @include apply-font($font-nl-roboto);
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 10px;
                text-align: right;

                &.row-name {
                    text-align: left;
                    padding-left: 10px;
                    width: 210px;
                }
            }

            tr:first-child {
                background: var(--background-panel);
            }

            tbody tr:nth-child(even) {
                background: #2c404c;
            }

            // Vertical border separators
            td:nth-child(1) {
                white-space: nowrap;
                border-right: 1px solid var(--accent-dark);
            }
            td:nth-child(4) {
                border-right: 1px solid var(--accent-dark);
            }
            td:nth-child(7) {
                border-right: 1px solid var(--accent-dark);
            }

            .meta-header {
                font-style: italic;
            }
            .header {
                border-top: 1px solid var(--accent-dark);
                border-bottom: 1px solid var(--accent-dark);
                text-align: right;
                th:nth-child(1) {
                    text-align: left;
                    border-right: 1px solid var(--accent-dark);
                }
                th:nth-child(4) {
                    border-right: 1px solid var(--accent-dark);
                }
                th:nth-child(7) {
                    border-right: 1px solid var(--accent-dark);
                }
            }
        }
    }

    // used to take the place of bar when it is removed from dom flow
    &.sticky .charts-panel {
        margin-top: 57px; // 50 (trend bar height) + 7 (margin)
    }

    // the starred styles below are used to give the illusion that the
    // fixed (ie. removed from dom flow) element is rendering within
    // its parent container (in Chrome/FF only). Otherwise, the
    // element would draw over the scrollbar (this hack doesn't work
    // in Safari/IE)
    &.sticky .trendbar-container {
        position: fixed;
        width: calc(100% - 240px + 40px); // * full width - sidebar width
        &.minimal {
            width: calc(100% - 65px + 40px);
        }
        top: 0;
        left: 0;
        z-index: 101; // render above chart tooltips
        overflow: hidden scroll; // * force rendering of scrollbar
        .panel-title {
            margin-right: 40px; // * move scrollbar so it doesn't block actual scrollbar
        }
    }

    .trendbar-container {
        margin: 0 7px;
    }

    .trendbar-container .panel-title {
        border-bottom: 1px solid var(--accent-dark);
    }

    .panel-title {
        justify-content: space-between;
        background-color: var(--background-panel);

        .panel-title-left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 300px;

            .bp4-button-text {
                margin-bottom: 3px;
            }
        }

        .panel-title-right {
            display: flex;

            .hover-detail-warn {
                display: inline-block;
                border-left: 1px solid var(--accent-dark);
                margin-left: 5px;
                margin-right: 10px;
                padding-left: 10px;
            }
        }

        .selected-value {
            display: flex;
            align-items: center;
            color: #bfccd6;
            margin: 0px 0px;

            > div {
                border-right: 1px solid var(--accent-dark);
                padding-left: 10px;
                padding-right: 10px;
                font-weight: 200;
                font-size: 14px;
                text-align: center;
                color: white;
                width: auto;

                &:last-child {
                    border-right: none;
                }
            }

            .bp4-intent-danger {
                color: #e56666;
            }

            span {
                padding: 0 5px;

                &.wind-force-good {
                    color: #53bb91;
                }

                &.wind-force-bad {
                    color: #e56666;
                }

                &:first-child {
                    padding-left: 0;
                    color: #bfccd6;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            > div.hovered-value {
                display: flex;
                justify-content: flex-end;
                border: none;
                width: 400px;
                align-items: baseline;

                span {
                    padding-right: 5px;
                }

                .divider {
                    border-left: 1px solid var(--accent-dark);
                    padding-left: 10px;
                    margin-left: 5px;
                }
            }

            .missing-value-target {
                display: flex;
                align-items: center;
                color: white;
            }
        }
    }

    .panel-body {
        .chart-totals {
            padding: 0;
            max-height: 100%;
        }

        .chart-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            width: 92%;

            &.full {
                width: 100%;
            }

            .chart-legend {
                margin-left: 55px;

                .label-tooltip .property-label {
                    text-decoration: underline dotted;
                }
            }

            .line-keys {
                display: flex;

                .label {
                    font-size: 12px;
                    margin-top: 5px;
                    margin-left: 15px;

                    span {
                        margin-left: 5px;
                    }

                    svg {
                        height: 5px;
                        width: 30px;

                        line {
                            stroke: $nl-light-gray-5;
                            stroke-width: 4px;
                            &.dashed {
                                // Must match the stroke-dasharray configuration in LineChart.tsx
                                stroke-dasharray: 2, 3;
                            }
                        }
                    }
                }
            }

            .delta-icons {
                margin-top: 5px;
                display: inline-flex;

                .icon-tooltip {
                    margin-top: -15px;

                    .chart-toggle {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        padding: 0px 20px 0px 20px;
                    }

                    div.enabled {
                        pointer-events: none;

                        svg {
                            fill: #14ccbd;
                        }
                    }

                    div.disabled {
                        background-color: transparent;

                        svg {
                            fill: #808fa3;
                        }
                    }
                }
            }
        }
    }
}

// globals - buttons

.chart-manager-button {
    background-color: #2c3741;
    margin-top: 1px;
    height: 75px;
    color: #53bb91 !important;
    font-size: 16px;

    svg {
        color: #53bb91 !important;
    }

    button {
        width: 96%;
        margin-left: 2%;
        height: 45px;
        margin-top: 15px;
        background-color: #33404c !important;

        &:hover {
            color: #53bb91 !important;
        }
    }
}

.remove-button {
    position: relative;
    display: inline-block;
    top: 26px;
    left: 95%;
    color: #828fa1;
    cursor: pointer;
}

.charter-remove-button {
    color: #828fa1;
    cursor: pointer;
    display: inline-block;
    position: relative;
    left: 15px;
}

.resize-map-button {
    position: absolute;
    top: 0;
    left: 0;
    margin: 8px;
    z-index: 1000;
    box-sizing: border-box;
}

.hour-picker {
    padding: 10px;
}

.speed-opt-nonideal {
    padding: 30px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.speed-opt {
    padding: 25px;
    padding-right: 0;
    & > * {
        margin-right: 25px;
    }
    display: flex;
    width: 100%;

    .speed-opt-table {
        flex-grow: 1;

        span {
            color: #bfccd6;
        }
        span:nth-child(2) {
            float: right;
            font-size: 12px;
            margin-bottom: 15px;
        }
    }
    table {
        width: 100%;
        td,
        th {
            padding: 4px;
        }
        th {
            color: #bfccd6;
            font-weight: normal;
            font-size: 12px;
        }
        tr {
            border-top: solid 1px var(--accent-dark);
            td:first-child {
                color: #bfccd6;
                font-size: 12px;
            }
        }
        tr.separator {
            border-top: none;
            td {
                padding: 20px 0;
            }
        }
        tr.separator + tr {
            border-top: none;
        }
        tr:first-child,
        tr:nth-child(2) {
            border-top: none;
        }
    }
    th div {
        text-align: right;
    }
    th div:last-child {
        font-size: 11px;
    }
    tr td:not(:first-child) {
        @include apply-font($font-nl-roboto);
        text-align: right;
        font-weight: lighter;
    }

    .rpm {
        padding-top: 30px;
        div {
            font-size: 13px;
            color: #bfccd6;
            padding-bottom: 5px;
        }
        .numerical-number {
            @include apply-font($font-nl-roboto);
            font-size: 24px;
            font-weight: lighter;
        }
    }
}

// globals

.plottable .x-gridlines line {
    stroke: #fff !important;
}

.chart-widget .plottable .axis-label text {
    font-size: 1.2rem;
}

.error-tooltip {
    width: 250px;
}

.charter-tooltip {
    max-width: 225px;
    display: flex;
    justify-content: space-between;
}

path.projected {
    stroke-dasharray: 5, 5;
    stroke-linecap: round;
}
