.app-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .app-body {
        display: flex;
        width: 100%;
        height: calc(100% - 60px);
    }
}
