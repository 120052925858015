.feature-charting-manager {
    height: 100%;
    width: 100%;

    .info-style {
        display: flex;
        align-items: center;

        .input {
            height: 30px;
            margin-right: 0.5em;

            &.ship-id-input {
                width: 150px;
            }

            &.feature-name-input {
                width: 400px;
            }

            &.domain-name-input {
                width: 250px;
            }
        }
    }
}
