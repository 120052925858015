@import "../styles/Color";

.nl-banner-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    min-height: 40px;

    word-wrap: normal;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    color: $nl-primary;

    .icon-container {
        margin: 12px;
        margin-right: 0;
    }
    .message-container {
        padding: 11px;
    }
}
