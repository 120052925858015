@import "../../styles/Color";
@import "../../styles/Header";

.vessel-detail-widget {
    @extend %page-container-style;
    padding: 5px;

    .vessel-detail-header {
        @extend %page-header-style;
        margin-bottom: 1px;
    }

    .vessel-detail-content {
        @extend %page-body-style;
        padding: 30px 50px;
        overflow: hidden scroll;
        background-color: var(--background-panel);

        .vessel-detail-cards {
            margin-top: 10px;
            box-shadow: none;
            background-color: var(--background-table-row-light);

            .vessel-detail-tables-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                flex: 1;
                justify-content: space-between;

                .vessel-detail-tables-container-col-2 {
                    flex: 1;
                }
            }
        }
    }
}
