@import "../../styles/Text";
@import "../../styles/Color";

%pie-chart-highlighted-value {
    @extend %heading-medium;
    color: $nl-primary;
}

%pie-chart-value {
    @extend %body-xs;
    color: $nl-primary;
}

%pie-chart-label {
    @extend %body-small;
    color: $nl-secondary;
}

%pie-chart-unit {
    @extend %body-xs;
    color: $nl-tertiary;
}

.pie-chart-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .pie-chart {
        position: relative;
        width: 100%;
        height: 100%;

        &.show-legend {
            width: calc(50% - 5px);
            margin-right: 5px;
        }

        .total-value-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            .total-value {
                @extend %pie-chart-highlighted-value;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 8px;
            }

            .total-label {
                @extend %pie-chart-label;
            }

            .total-unit {
                @extend %pie-chart-unit;

                &.prefix {
                    margin-right: 3px;
                }
            }
        }
    }

    .pie-chart-legend-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: calc(50% - 5px);
        height: 100%;
        margin-left: 5px;

        .pie-chart-legend {
            margin-bottom: 5px;

            .legend-container {
                display: flex;
                flex-direction: row;
                align-items: center;

                .circle {
                    width: 2px;
                    height: 2px;
                    margin-right: 3px;
                    border: 2px solid;
                    border-radius: 50%;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }

                .legend-key,
                .sector-info .sector-value-percentage {
                    @extend %pie-chart-label;
                }

                .legend-key {
                    display: block;
                    max-width: 60px;
                    @extend %text-ellipsis;
                }

                .bp4-popover-wrapper {
                    display: flex;
                    flex-direction: row;
                }

                .sector-info {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    align-items: baseline;
                    justify-content: flex-end;

                    .sector-value {
                        @extend %pie-chart-highlighted-value;
                    }

                    .sector-value-percentage {
                        margin-left: 3px;
                    }
                }
            }

            .value-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                height: min-content;

                .value {
                    @extend %pie-chart-value;
                }

                .unit {
                    @extend %pie-chart-unit;
                    @extend %text-ellipsis;
                }
            }
        }
    }
}
