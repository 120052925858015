@import "../styles/Color";

// TODO: move this to navigation/Header.scss ?
$nav-header-height: 60px;

.settings-page-container {
    width: 100%;
    height: 100%;

    .settings-page-header-container {
        width: 100%;
        height: $nav-header-height;
    }

    .settings-page-body-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: calc(100% - #{$nav-header-height});

        .nav-head {
            display: flex;
            padding: 21px;
            align-items: center;
            border-bottom: 1px solid $nl-dark-gray-1;
            svg {
                width: 60px;
                height: 60px;
                fill: white;
            }
        }

        .nav-head-content {
            margin-left: 14px;
            span {
                font-size: larger;
            }
        }

        .nav-links {
            flex: 1;

            .nav-link {
                display: flex;
                padding-left: 30px;
                padding-right: 10px;
                align-items: center;
                min-height: 55px;
                height: 55px;
                border-bottom: 1px solid $nl-dark-gray-1;
                font-size: 14px;
                cursor: pointer;
                user-select: none;

                &.active {
                    background-color: var(--background-nav-active);

                    .secondary-label {
                        opacity: 1;
                    }
                }

                // not active and on hover
                &:not(.active):not(.disabled):hover {
                    background-color: var(--background-panel);

                    .secondary-label {
                        opacity: 1;
                    }
                }

                .return-to-app-content {
                    margin-left: 10px;
                    color: $nl-cobalt-4;
                }
            }
        }

        .settings-page-content {
            display: flex;
            width: 100%;
            padding: 10px;
            overflow-y: auto;
        }
    }
}
