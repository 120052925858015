@import "../../styles/Header";
@import "../../styles/Color";
@import "../../styles/Text";

.performance-page {
    .date-range-control-container {
        align-items: baseline;
    }

    .cii-trajectory-widget {
        display: flex;
        flex-direction: column;
        @extend %page-body-style;
        padding: 7px;

        .top-panel-wrapper {
            height: 60%;

            .spinner-container {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .performance-model {
                padding: 25px;
                height: 100%;

                .performance-legend-container {
                    display: flex;
                    justify-content: space-between;
                    margin-left: 10%;
                    margin-right: 5%;
                }

                .chart-container {
                    display: flex;
                    flex-direction: column;

                    .mini {
                        height: 80px;

                        .gridlines {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
