@import "../../../styles/Color.scss";

.JsonBlock {
    box-sizing: border-box;
    display: block;
    width: 100%;
    min-height: 100%;
    overflow-y: scroll;
    outline: 1px solid #ccc;
    padding: 10px;
    margin: 0;

    .string {
        color: $green3;
    }
    .number {
        color: $nl-orange-3;
    }
    .boolean {
        color: $nautilus-blue;
    }
    .null {
        color: $nl-violet-4;
    }
    .key {
        color: $red3;
    }
}
