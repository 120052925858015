@import "../../styles/Color.scss";
@import "../../styles/Text.scss";

.prediction-at-label {
    background-color: var(--background-panel);
    display: flex;
    justify-content: flex-end;
    min-height: 50px;
    max-height: 50px;
    padding: 10px 0 10px 0;
}

.prediction-at-text {
    @include apply-font($font-nl-avenir-next);
    display: flex;
    font-size: 11px;
    margin-top: 10px;
    margin-right: 10px;
    white-space: nowrap;
    font-weight: 300;
}

.prediction-at-reset-button {
    text-align: center;
}

.recommendations-tooltip {
    cursor: initial;
    padding: 5px;

    .time {
        font-size: 16px;
        margin-bottom: 10px;
        display: flex;

        .bf {
            padding-left: 10px;
            color: #53bb91;
        }
    }

    .mean {
        text-align: initial;
        font-size: 14px;
        margin: 5px 30px;

        .name {
            color: $nl-gray-5;
        }

        .value {
            float: right;
            .favorable {
                color: $green5;
            }
            .adverse {
                color: $red5;
            }
        }
    }
}
