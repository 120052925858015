@import "../../styles/Text.scss";
@import "../../styles/Header.scss";

.cii-simulator-widget-container {
    position: relative;
    height: 100%;

    .cii-simulator-widget-body {
        padding: 7px;
        height: calc(100% - $header-height);

        .cii-simulator-panel {
            height: 100%;
            padding: 1em 2em 1em;
            overflow-y: auto;

            h1,
            h2 {
                @extend %heading-large;
                margin: 0.5em 0;
            }

            header {
                margin-bottom: 1.5em;

                .subtitle {
                    @extend %body-medium;
                    color: $nl-unit-light;
                    margin-bottom: 0;
                }
            }

            .spinner {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .cii-indicator {
                @extend %cii-colors;
                display: inline-block;
                width: 0.8em;
                height: 0.8em;
                margin-right: 0.5em;
                border-radius: 50%;
            }
        }
    }
}
