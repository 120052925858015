@import "../../styles/Text";

.RouteOptimizationsModule {
    display: flex;
    flex-direction: column;
    padding: 5px;
    height: 100%;
    min-width: 800px;

    .spinner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .panel {
        height: 100%;
        overflow-y: hidden;

        .panel-body {
            padding: 20px;

            h1 {
                margin: 0;
                font-size: 16px;
            }
        }
    }
}
