@import "../../../styles/Color";

$preview-disabled-dialog-width: 500px;
$preview-enabled-dialog-width: 868px;

$margin-between-config-and-preview: 5px;

.nl-table-column-manager-container {
    width: $preview-disabled-dialog-width;

    &.show-preview {
        width: $preview-enabled-dialog-width;

        .nl-table-column-manager-body-container {
            height: 500px;

            .nl-table-column-configuration-container {
                width: calc(50% - #{$margin-between-config-and-preview});
                margin-right: $margin-between-config-and-preview;
            }
        }
    }

    .nl-table-column-manager-body-container {
        display: flex;
        flex-direction: row;

        .nl-table-column-configuration-container {
            width: 100%;

            .show-preview-toggler-container {
                width: 100%;
                margin-bottom: 10px;

                .show-preview-toggler {
                    float: right;
                }
            }

            .bp4-form-content,
            .column-manager-form-content {
                flex-grow: 1;

                .cell-aggregation-select-form-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    line-height: 15px;

                    .aggregation-type-info-icon {
                        color: $nl-gray-5;
                    }
                }

                #voyage-column-manager-source-select {
                    .bp4-button {
                        display: inline-flex;
                        justify-content: space-between;
                        min-width: 150px;
                    }
                }

                .nl-dropdown-selector-container {
                    justify-content: flex-start;
                }
            }
        }

        .nl-table-column-preview-container {
            width: calc(50% - #{$margin-between-config-and-preview});
            margin-left: $margin-between-config-and-preview;
        }
    }
}
