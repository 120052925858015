@import "../../../styles/Color";

.performance-benchmark-detail-widget-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;

    &.loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .performance-benchmark-consumption-calulation-description {
        width: 800px;
        height: fit-content;
        margin-bottom: 20px;
        font-size: 14px;
        color: #bfccd6;
        word-wrap: break-word;
    }

    .performance-benchmark-detail-ranges-content-container {
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        .detail-ranges-header {
            margin-bottom: 10px;
        }

        .detail-ranges-content {
            box-shadow: none;
            background-color: var(--background-table-row-light);

            .detail-content-ranges {
                margin-right: 10px;
                color: $nl-gray-3;
                font-style: italic;
            }
        }
    }
}
