@import "./Color";
@import "./Text";

.nl-dropdown-selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
        margin-right: 10px;
    }

    .nl-dropdown-label {
        margin-right: 10px;
        color: $nl-light-gray-1;

        &.has-tooltip {
            @extend %tooltip-content;
        }
    }

    .bp4-popover-wrapper.fill {
        width: 100%;

        .nl-dropdown-button {
            width: 100%;
            justify-content: space-between;
        }
    }

    .nl-dropdown-button {
        display: flex;
        flex-direction: row;
        align-items: center;

        .bp4-button-text {
            min-width: 60px;
        }
        .bp4-icon {
            display: contents;
        }
    }
}

.nl-dropdown-popover-container {
    max-height: 500px;
    background-color: $nl-dark-gray-4;
    overflow-y: auto;

    .nl-dropdown-menu {
        padding: 8px;

        .nl-dropdown-menu-item {
            &.bp4-intent-primary.bp4-active {
                background-color: $nl-cobalt-4;
            }

            &.bp4-menu-item:hover {
                background-color: $nl-light-gray-hover;
            }

            // Align port information for each leg to right-side
            // This style needs adjustment if dropdown select is to support display
            // more than 2 info per row in the future.
            > div {
                display: flex;
                flex-direction: row;
            }
        }
    }
}

.nl-date-selector-container,
.nl-date-range-selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
}
