@import "../../styles/Header";
@import "../../styles/Color";
@import "../NonIdealState";

.performance-benchmark-page-container {
    @extend %page-container-style;
    background-color: var(--background-panel);

    &.non-ideal {
        @extend %non-ideal-state-container;
    }

    .performance-benchmark-page-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        @extend %page-header-style;
        padding: 10px;
        border-bottom: 1px solid $nl-dark-gray-1;
    }

    .performance-benchmark-page-body-container {
        @extend %page-body-style;

        &.draft-benchmark {
            height: calc(100% - #{$header-height} - 40px);
        }
    }
}
