@import "../../styles/Buttons";

.coq-database-view {
    display: flex;
    flex-direction: column;
    padding: 5px;
    height: 100%;

    .panel-header {
        padding: 10px 20px;

        .title {
            font-size: 16px;
        }
    }

    .add-cargo-composition-button {
        @extend %primary-button-minimal;
        padding: 0.75em 1.5em;
        border-radius: 4em;
    }

    .lng-cargo-compositions {
        height: calc(100% - 50px);
        padding: 0 20px 10px;

        // Overwrite the default table styles
        .table-controls-container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .table-wrapper {
                height: auto;
            }

            .pagination-controls {
                flex: 1;
                justify-content: flex-end;
            }
        }
    }
}
