%nl-event-circle-marker {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid 1px #ffffff;

    &.relative {
        flex-shrink: 0;
        margin-right: 20px;
        margin-top: 3px;
    }
}
