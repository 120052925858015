.date-range-picker {
    margin-right: 10px;
    display: flex;
    align-items: center;

    .date-divider {
        margin: 5px 5px 0 5px;
    }

    .right-most-button {
        margin-right: 5px;
    }
}
