@import "./Color.scss";

%pill-button {
    margin-top: 0.5em;
    padding: 0 2em;
    border-radius: 2em;
}

%inverse-button {
    background: transparent;
    border: 2px solid white;
    color: white;

    &:hover {
        background: white;
        color: $nl-dark-gray-2;
        cursor: pointer;
    }
}

%primary-button {
    background: var(--intent-primary-background);
    color: white;

    &:not(:disabled):hover {
        background: var(--intent-primary-background-hover);
        cursor: pointer;
    }

    &:disabled {
        background: var(--intent-primary-background);
    }
}

%primary-button-minimal:not(:disabled) {
    color: var(--intent-primary) !important;

    &:hover {
        color: var(--intent-primary) !important;
        background-color: var(--intent-primary-background-minimal-hover) !important;
    }

    svg {
        fill: var(--intent-primary) !important;
    }
}
