@import "../styles/Header";

/*
 * Noon panel styling
 */

.noon-widget-container {
    @extend %page-container-style;

    .noon-widget-header {
        @extend %page-header-style;
    }

    .noon-widget-body {
        display: flex;
        flex: 1;
        @extend %page-body-style;

        .noon-panel {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 8px;
            padding: 10px 20px;
            background-color: var(--background-panel);
        }

        .noon-details {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 8px;
            margin-left: 0;

            .noon-map {
                display: flex;
                flex: 1;
                height: 50%;
            }

            .chart {
                flex: 1;
                margin-top: 8px;
                height: 345px;
                padding: 20px 40px 20px 40px;
                z-index: 1;

                .chart-render-container {
                    .plottable:first-child {
                        width: 5%;
                        height: 80%;
                    }

                    .plottable:last-child {
                        width: 0%;
                    }

                    .plottable:nth-child(2) {
                        width: 95%;
                    }
                }
            }
        }
    }
}

/*
 * Noon table styling
 */

.noon-table {
    border-collapse: collapse;
    border-spacing: 0 5px;
    color: white;
    width: 99%;
    padding: 10px;

    th {
        font-size: 1.1rem;
        color: #b0b8c5;
    }

    td {
        border-right: 1px solid var(--accent-dark);
        border-left: 1px solid var(--accent-dark);
    }

    tr {
        border-top: 1px solid var(--accent-dark);
        border-bottom: 1px solid var(--accent-dark);
    }

    td:first-child {
        border-left: none;
    }

    td:last-child {
        border-right: none;
    }

    tr:first-child {
        border-top: none;
    }

    tr:last-child {
        border-bottom: none;
    }

    thead > tr {
        padding: 5px;
    }

    tbody > tr.noon-row--highlightable {
        cursor: pointer;
    }

    tbody > tr.noon-row--highlightable:hover {
        background-color: rgba(92, 112, 128, 0.3);
    }

    th,
    td {
        padding: 5px 10px 5px 10px;
        font-weight: normal !important;
    }
}

tr.noon-row--selected {
    background-color: #4f72f7;
}

.noon-row--selected > td {
    border: none !important;
}

.noon-table-header {
    color: #b0b8c5;
    padding-top: 20px;
    font-size: 1.3rem;
    font-weight: 600;
}

.noon-period-select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-weight: 600;
        margin: 0 10px;
    }

    .icon-wrapper {
        display: flex;
        justify-content: flex-end;
        min-width: 140px;
    }
}

.nl-tab-group {
    flex: 0 0 40px;
}

/*
 * Pill number display styling
 */

.pill--theme-measured {
    color: white;
}

.pill--warn {
    fill: #ffa623;
    color: #f5a623;
    padding: 0 5px;
    margin-right: -5px;
}

.pill--alert {
    fill: #ff5e3a;
    color: #ff5e3a;
    margin-left: 5px;
}

.noon-chart-header {
    text-transform: uppercase;
    text-align: center;
    color: #dddddd;
    font-size: 1.5rem;
    font-weight: normal;
}

/*
 * Noon details
 */

.noon-details > .widget {
    background-color: var(--background-panel);
}

.voyage-details {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    margin-bottom: 10px;
    color: white;
}

.voyage-details-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    font-size: 14px;
    color: #b0b8c5;
}

.voyage-detail {
    margin-right: 20px;
    display: flex;
}

.voyage-detail-info {
    font-weight: 600;
    color: white;
}

.header-bottom-pad {
    padding-bottom: 15px;
}

.noon-chart-alert {
    color: white;
    display: flex;
    font-size: 14px;
    padding-bottom: 15px;
}

.noon-chart-alert-glyphicon {
    font-size: 16px;
}

.chart {
    & > .panel-title {
        padding-bottom: 10px;
        margin-bottom: 25px;
        color: white;
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    .noon-horizontal {
        & path {
            stroke-dasharray: 4, 4;
            stroke: #14ccbd;
            stroke-width: 1px;
        }

        &.second path {
            stroke: #fbda88;
        }
    }
}

.noon-details > .noon-map {
    position: relative;
    height: calc(50% - 65px);

    .layer-control {
        top: 34px !important;
    }
}

.noon-title {
    font-weight: normal;
    font-size: 14px;
    border-right: 1px solid #1e222b;
    padding: 5px 10px 5px 0;
    margin: -5px 10px -5px 0;
}

.noon-remarks-button {
    color: #4f72f7;
}

.noon-chart-legend {
    display: flex;
    align-items: center;
    padding-right: 35px;
    font-size: 12px;

    .circle {
        margin: 0;
        margin-right: 5px;
        margin-left: 5px;
    }

    .current-value {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 16px;
    }
}

.circle {
    border: 3px solid #fbda88;
    background-color: #fbda88;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 5px 0 5px;
}

.sensor-circle {
    border-color: #14ccbd;
    background-color: #14ccbd;
}

.alert-circle {
    float: right;
    border: 3px solid;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 7px 5px 0 5px;
    padding: 0 !important;
}

.table-reported {
    border-right: none !important;
    border-left: none !important;
    width: 200px;
}

.table-unit {
    width: 125px;
}

.table-measured {
    width: 200px;
}

.table-key {
    width: 300px;
}

.reported-table-alert {
    border-left: none !important;
    padding: 5px 3px !important;
}

.noon-tables {
    overflow-y: auto;
}

.sensor-alert {
    padding: 15px 0 15px 10px;
    margin: 0 -20px;
    font-weight: bold;
    background-color: #e56666;
}

.loading {
    padding-top: 50px;
}

.noon-table-remarks-text {
    border: 1px solid var(--accent-dark);
    width: 99%;
    white-space: pre-wrap;
    padding: 10px;
    margin-top: 25px;
}
