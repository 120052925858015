@import "../../styles/Color";

$app-header-height: 60px;

.fleet-widget {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$app-header-height});
    width: 100%;
    position: relative;

    .fleet-table-panel {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        border: 1px solid #1f222a;
        background-color: var(--background-panel);
    }

    .new-table {
        width: 100%;
        height: 100%;

        .hover {
            .vessel-name-container {
                position: relative;
            }

            .name {
                .action-icon-container {
                    visibility: visible;
                    position: absolute;
                    right: 0;
                    top: 0;

                    .action-icon {
                        margin: 0px 8px;
                        :hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
        .name {
            position: relative;
            .action-icon-container {
                visibility: hidden;
                position: absolute;
                right: 0;
                .action-icon {
                    display: flex;
                    margin: 0px 8px;
                }
            }
        }

        .bp4-icon.star-button {
            color: #6b7d91;

            &:hover {
                color: #bfccd6;
            }

            &.active {
                color: #fbda88;
                &:hover {
                    color: #f2b824;
                }
            }
        }
    }

    .fleet-map {
        position: relative;
        height: 50%;
        max-height: 100%;
        flex: 1 1 auto;

        .ship-label {
            font-size: 12px;
            white-space: nowrap;
            background-color: white;
            color: #30404d;
            padding: 2px 4px;
            border-radius: 1px;
            box-shadow: 0 0 2px 0 #6b7d91;
            z-index: 98;

            &.starred {
                background-color: #fbda88;
                z-index: 99;
            }

            &.out-of-focus {
                opacity: 0.3;
                z-index: 97;
            }
            > :hover {
                z-index: 100;
            }
        }

        .hovered {
            z-index: 100;
        }

        &.weather {
            .ol-zoom {
                bottom: 67px;
            }

            .ol-attribution {
                bottom: 40px;
            }
        }

        .ol-zoom {
            bottom: 27px;
        }

        .ol-attribution {
            bottom: 0;
        }
    }

    .tooltip-content {
        max-width: 300px;
        display: flex;
        justify-content: space-between;

        :first-child {
            margin: 2px 10px 0;
        }
    }

    .bp4-dark .vessel-info-display .bp4-tab-indicator {
        background-color: #4f72f7;
        z-index: 98;
    }

    .bp4-dark .vessel-info-display .bp4-tab[aria-selected="true"],
    .bp4-dark .vessel-info-display .bp4-tab:not([aria-disabled="true"]):hover {
        color: white;
        z-index: 100;
    }

    .light-spinner.bp4-progress-bar {
        background: rgba(92, 112, 128, 0.2) !important;
    }

    .forecast-slider {
        display: flex;
        width: 450px;
        color: black;
        margin: 12px 10px;

        svg {
            cursor: pointer;
        }

        span.bp4-popover-target {
            vertical-align: middle;
        }

        .bp4-slider-axis {
            .bp4-slider-label {
                display: none;
            }
        }
        .bp4-slider-handle {
            top: 2px;
            background-color: white !important;

            .bp4-slider-label {
                bottom: 45px;
            }

            &:focus {
                outline: transparent;
            }

            .bp4-slider-label {
                white-space: nowrap;
            }
        }
        .bp4-slider-track {
            top: 6px;

            .bp4-slider-progress {
                border-left: 1px white solid;
                background-color: #669eff !important;
                border-right: 1px white solid;
            }
        }
    }
}
