@import "../../styles/Text";
@import "../../styles/Color";

$form-label-min-width: 50px;
$form-field-min-width: 250px;

.nl-form-wrapper {
    display: flex;
    flex-direction: row;

    &.minimal {
        .bp4-form-content > div:not(.bp4-intent-danger) {
            .bp4-input {
                &:not(:focus) {
                    box-shadow: none;
                    border-radius: unset;
                    border-bottom: 1px solid $nl-dark-gray-1;
                    background-color: transparent;

                    &:disabled {
                        border-width: 0;
                    }
                }
            }
        }

        .bp4-tag {
            box-shadow: none !important;
            border-radius: unset;
            border-bottom: 1px solid $nl-dark-gray-1;
            background-color: transparent !important;
        }
    }
    &:not(.collapse) {
        .bp4-form-group {
            .bp4-label {
                min-width: 255px;
            }
        }
    }

    .bp4-form-group {
        .bp4-label {
            min-width: $form-label-min-width;

            .bp4-popover-wrapper {
                display: inline-block;
            }
        }

        .bp4-input-group {
            input {
                min-width: $form-field-min-width;
            }
        }

        .nl-dropdown-button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            min-width: $form-field-min-width;
            border-bottom: 1px solid $nl-dark-gray-1;

            .bp4-button-text {
                flex: 1;
            }
        }
    }

    .nl-form-postfix {
        @extend %unit;
        font-style: unset;
        color: $nl-secondary;
        transform: translate(5px, 15px);
    }
}
