@import "../../styles/Color.scss";

.subscription-manager-container {
    .subscription-menu {
        background-color: $nl-dark-gray-4;
    }
    .grid-container {
        .selected {
            background-color: rgb(83, 187, 145);

            &:hover {
                background-color: #53bb91;
            }
        }
    }

    .add-subscription-button-container {
        width: 100%;
    }
}

.subscription-manager-delete-button-container {
    width: fit-content;
}

.subscription-modifier-dialog {
    .subscription-modifier-dialog-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 300px;

        .subscription-title-input {
            input {
                width: 400px;
            }
        }
    }
}

.subscription-selectors {
    height: 350px;
    overflow-y: scroll;
}
