@import "../../../styles/Color";

.intruction-edit-menu-popover {
    padding-left: 10px;
    padding-right: 10px;

    .instruction-value-slider {
        padding: 5px 7px;
        width: 200px;

        .critical-range-label {
            margin-bottom: 12px;
            font-size: 12px;
            font-style: italic;
            text-align: center;
        }

        &.shaft-power-setting {
            .bp4-label {
                position: relative;

                .bp4-slider .bp4-slider-handle {
                    z-index: 1;
                }

                .slider-exclusion-visual-break {
                    position: absolute;
                    top: 28px;
                    height: 6px;
                    background-color: $nl-dark-gray-4;
                    transform: skew(-35deg);
                }
            }
        }
    }
}
