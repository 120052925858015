@import "../../styles/Text.scss";

.highlight-container {
    .highlight {
        @extend %cii-colors;
        height: 150px;
        width: 150px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 2em;

        .cii {
            @extend %heading-xxl-bold;
            margin: 0;
        }

        .aer {
            @extend %heading-small;
        }
    }

    .ytd-cont,
    .ytd-values {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .ytd-cont {
        flex-direction: column;
        margin: 0.5em 2em 0 0;

        h4 {
            @extend %body-medium;
            margin-bottom: 0.5em;
        }

        .ytd-values p {
            @extend %body-medium;
            margin-bottom: 0;
        }
    }
}
