.chart-library .dialog-body {
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 500px;
}

.delete {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .delete-buttons {
        align-self: flex-end;

        button {
            margin-left: 10px;
        }
    }
}

.plot-card {
    height: 115px;
    width: 31%;
    margin: 1%;
    border: 1px #838fa1 solid;

    h6 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        overflow-wrap: normal;
        max-height: 36px;
        font-size: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .card-options {
        display: flex;
        position: relative;
        float: right;

        .selected-circle {
            border: 1px #828fa1 solid;
            height: 20px;
            width: 20px;
            border-radius: 10px;
            margin-top: -6px;
            margin-right: -6px;

            &.filled {
                background-color: #719ff8;
                border-color: #719ff8;
            }
        }

        .card-icons {
            color: #828fa1;
            margin-top: -5px;
            background-color: #33404c;
            padding: 2px 0 0 5px;

            svg {
                margin-right: 8px;
            }

            svg:hover {
                color: white;
            }
        }
    }

    .tick {
        padding-left: 2px;
        padding-top: 3px;
    }
}

.visible {
    border: 1px #719ff8 solid;
}

.deleting {
    border: 1px #d66d6a solid;
}

.create-chart {
    width: 31%;
    margin: 1%;
    border: 1px #9da9b4 dashed;
    height: 115px;
    color: #9da9b4 !important;
}
