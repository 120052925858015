@import "../../../styles/Color";
@import "../../../styles/Text";

.BoilOffSimulationsModule .summary-panel {
    width: 35%;
    min-width: 450px;
    height: 100%;
    overflow-y: auto;

    .summary-section {
        .title-container {
            display: flex;
            align-items: center;

            h3 {
                margin: 0;
            }

            .toggle-button {
                margin-right: 0.5em;
            }
        }

        .summary-fields {
            padding: 0 1em;

            .summary-field {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &:not(:first-of-type) {
                    padding-top: 7.5px;
                    margin-top: 7.5px;
                    border-top: 1px solid var(--accent-dark);
                }

                &:has(.target) {
                    dt,
                    dd {
                        white-space: nowrap;
                    }
                }

                .tooltip {
                    @extend %tooltip-label;
                }

                .target {
                    @extend %unit-light;
                    font-style: normal;
                    padding-top: 0.25em;
                    padding-left: 0.5em;
                    margin-top: 0;
                }

                .data-unit {
                    display: flex;
                    align-items: center;

                    .data {
                        text-align: right;
                        margin-bottom: -2px;

                        .highlight-success {
                            color: var(--intent-success);
                        }

                        .highlight-failure {
                            color: var(--intent-error);
                        }
                    }

                    .trend-icon-tooltip:hover {
                        cursor: help;
                    }

                    .trend-icon {
                        margin-left: 0.25em;

                        &.down {
                            color: var(--intent-success);
                        }
                        &.up {
                            color: var(--intent-error);
                        }
                    }
                }
            }

            .unit {
                @extend %unit-light;
                text-wrap: nowrap;
                margin-bottom: -2px;
                margin-left: 5px;
                text-align: right;
            }

            // Setpoint
            .detail {
                width: 100%;

                .label {
                    width: auto;
                }

                .value {
                    flex: 1;
                    text-align: right;
                }

                .unit {
                    padding-right: 0;
                }
            }
        }
    }
}
