@import "../../styles/Color";
@import "../../styles/Scrollbar";
@import "~@blueprintjs/core/lib/scss/variables";

.code-area {
    position: relative;

    width: 100%;
    height: 100%;
    min-height: 0;

    overflow: visible;

    border-radius: 3px;

    &.error::after {
        pointer-events: none;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
        width: 100%;
        height: 100%;
        border: 1px solid $pt-intent-danger;
    }

    .errors {
        position: absolute;
        margin-top: 5px;
        top: 100%;
        color: $pt-intent-danger;
        font-weight: 300;
    }

    .scroll-container {
        width: 100%;
        height: 100%;

        overflow: auto;

        .text-area-container {
            min-height: 100%;
            min-width: 100%;
            display: grid;
            grid-template-columns: 6ch 1fr;

            .gutter,
            .sizing,
            textarea {
                line-height: 1.5em;
            }

            .gutter {
                grid-area: 1 / 1 / 2 / 2;
                background-color: $nl-dark-gray-5;
                & > div {
                    color: $nl-gray-5;
                }

                position: sticky;
                left: 0;

                padding: 10px;

                text-align: right;
            }

            .sizing {
                visibility: hidden;
            }

            textarea {
                @extend %hide-scrollbar;
                border-radius: 0 3px 0 3px;
            }

            .sizing,
            textarea {
                grid-area: 1 / 2 / 2 / 3;

                padding: 10px;

                flex-grow: 1;
                resize: none;
                font-family: inherit;

                box-sizing: border-box;
                white-space: pre;
            }
        }
    }
}
