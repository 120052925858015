@import "../../../../styles/Text.scss";

.cii-tooltip {
    text-align: start;
    line-height: 1.5;
    padding: 5px;

    .date {
        @extend %heading-small;
        text-align: center;
    }

    .labeled-value {
        display: grid;
        grid-template-columns: 1fr 2fr;

        &.long-label {
            grid-template-columns: 1fr 1fr;
        }

        .label {
            @extend %heading-small;
            margin-right: 1em;
        }

        .value {
            @extend %body-medium;
            flex: 1;

            .unit {
                @extend %unit;
                @extend %space-left;
            }
        }
    }
}
