@import "../../styles/Text";
@import "../../styles/Color";

.single-value-card.card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .value-container {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .value {
            margin-right: 5px;
            @extend %heading-xl;
            color: $nl-primary;
        }

        .unit {
            @extend %body-small;
            color: $nl-secondary;
        }
    }
}
