@import "../../styles/Buttons.scss";
@import "../../styles/Text.scss";

.proj-scenario {
    .hidden {
        visibility: hidden;
    }

    .proj-content {
        display: flex;
        max-width: 65%;
    }

    .proj-detail {
        flex: 1;

        h3 {
            @extend %heading-medium;
            margin: 0.5em 0;
        }

        .container {
            margin-left: 1.5em;
            margin-bottom: 0.5em;
            display: flex;

            &:not(.stacked) {
                flex-direction: column;
                margin-bottom: 2em;

                .buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    margin-bottom: 1em;

                    .button {
                        @extend %pill-button;

                        &.reset {
                            @extend %inverse-button;
                            margin-right: 0.5em;
                        }
                    }
                }

                .fuel-note {
                    @extend %body-medium;
                    color: $nl-unit-light;
                    margin-bottom: 0;
                    width: 85%;
                }
            }

            table {
                width: 100%;

                tr {
                    &:not(:last-child) {
                        border-bottom: $bp-table-border;
                    }

                    th {
                        @extend %unit-light;
                        font-style: normal;
                    }

                    th,
                    td {
                        padding: 7px 14px;

                        &:first-child {
                            @extend %unit-light;
                            font-style: normal;
                        }

                        &:not(:first-child) {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}
