@import "../../styles/Color";
@import "../../styles/Text";

:root {
    --panel-gap: 1px;
    --module-height: calc(100% - 40px);
}

.VoyageSimulationsModule {
    display: flex;
    flex-direction: column;
    padding: 5px;
    height: 100%;
    min-width: 800px;

    .panel-container:not(.trace) {
        padding: 10px 20px;
    }

    .FormView {
        overflow-y: scroll;
    }

    .overview {
        display: flex;
        flex-direction: row;
        flex: 0 0 40px;

        & > span + span {
            margin-left: 20px;
        }
    }

    .overview + & {
        margin-top: 2px;
    }

    .details {
        grid-area: details;
    }

    .trace {
        grid-area: trace;
    }

    .chart {
        grid-area: chart;
        overflow-y: scroll;
    }

    .with-unit {
        display: flex;
        align-items: center;
    }

    .unit {
        margin-left: 5px;
        font-size: 11px;
        padding-left: 5px;
    }
}

.simulations-panel-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 24px;

    h1 {
        @include apply-font($font-nl-avenir-next);
        margin: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.FormView {
    // TODO: Find a better CSS class that doesn't reach into Blueprint CSS
    .bp4-form-group label {
        width: 250px;
    }

    section h3 {
        margin-top: 60px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--accent-dark);
    }

    form {
        position: relative;
        padding-bottom: 120px;
    }

    footer {
        border-top: 1px solid var(--accent-dark);
        padding: 20px 50px;
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.HistoryView {
    min-height: min-content;
    height: auto;
    overflow-y: scroll;

    table {
        text-align: left;
    }

    th,
    td {
        padding: 10px 0;
    }

    tr + tr {
        border-top: 1px solid #5c7080;
    }

    .actions a {
        color: $white;
        text-transform: uppercase;
        text-decoration: underline;

        &:hover {
            color: $nautilus-blue;
        }
    }
}

.MonitoringView {
    height: var(--module-height);
    flex: 1 1;
    display: grid;
    grid-template-areas:
        "details trace"
        "details chart";
    grid-template-columns: minmax(300px, 1fr) 2fr;
    grid-template-rows: 1fr 1fr;
    column-gap: var(--panel-gap);
    row-gap: var(--panel-gap);
}

.simulation-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > dt,
    & > dd {
        flex: 50%;
        margin: 0;
        padding: 20px 0;

        &:not(:first-of-type) {
            border-top: 1px solid var(--accent-dark);
        }
    }

    dd {
        text-align: right;
    }
}

.flex-table {
    text-align: left;
    width: 100%;

    th:last-child,
    td:last-child {
        text-align: right;
    }
}
