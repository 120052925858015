.event-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 60px;
    right: 0;
    height: calc(100% - 60px);
    width: 550px;
    background-color: var(--background-drawer);
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
    overflow-x: hidden;
    z-index: 150;

    .event-header {
        padding: 30px 30px 0px 30px;
    }

    .event-header-title {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        color: white;

        .filter-icon-active {
            svg {
                fill: var(--color-icon-active);
            }
        }
    }

    .event-header-body {
        margin-bottom: 10px;
    }

    .event-header-create-event-input {
        padding-top: 10px;
    }

    .event-row {
        padding: 30px 20px 10px 20px;
        &:hover {
            .event-redirect {
                visibility: visible !important;
            }
        }
        .event-redirect {
            height: 18px;
            visibility: hidden;
            a {
                float: right;
                color: #669eff;
            }
        }

        color: white;
        position: relative;
        cursor: default;
        .event-content-wrapper {
            display: flex;
        }
        .event-content {
            flex: 1 1 0;
            min-width: 0;
        }
        .event-title {
            font-size: 14px;
        }

        .event-row-header {
            font-size: 12px;

            color: #bfccd6;
            margin-top: 3px;
            margin-bottom: 3px;
        }

        .event-body {
            white-space: pre-wrap;
            margin-top: 3px;
        }
    }

    .event-replies {
        margin-top: 10px;
    }

    .panel-event-reply {
        padding: 3px 30px;
    }

    .border {
        border-bottom: 1px solid black;
        &.full {
            margin-left: -30px;
            margin-right: -30px;
        }
    }

    .event-marker {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: solid 1px #ffffff;
        &.relative {
            flex-shrink: 0;
            margin-right: 20px;
            margin-top: 3px;
        }
    }

    .event-filter-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 20px 0px;
    }

    .event-filter {
        width: 150px;
        justify-content: space-between;
    }

    .bp4-checkbox.bp4-control {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        height: 20px;
    }
}

.add-event {
    border-radius: 2px;
    width: 490px;
    height: 35px;
    color: var(--color-button-success) !important;
    .bp4-icon {
        color: var(--color-button-success) !important;
    }
}

.event-replies {
    margin-top: 10px;
}

.event-reply {
    border-width: 0px 0px 0px 1px;
    border-style: solid;
    border-color: #bfccd6;

    &:hover {
        background-color: var(--background-panel);
        .user-action-icon {
            visibility: visible;
        }
    }
}

.event-header-force-visible-icon {
    .user-action-icon {
        visibility: visible;
    }
}

.event-content-wrapper {
    &:hover {
        .user-action-icon {
            visibility: visible;
        }
    }
}

.user-favorite-icon {
    &.favorited {
        visibility: visible;
        color: #ffc940;
    }
}

.user-created-event {
    .enter-event {
        padding: 0px;
        margin-bottom: 20px;

        width: 200px;

        .bp4-button {
            justify-content: space-between;
        }
    }

    .enter-date {
        display: block;
        padding: 0px;
        margin: 20px 0px;
        background-color: var(--background-panel);

        .bp4-popover-target {
            display: flex;
            justify-content: space-between;
            height: 30px;

            .date-input {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 5px;

                span {
                    color: white;
                }
            }
        }
    }

    .event-ship-select {
        min-width: 200px;
        background-color: #394b59;
    }

    .create-event-header {
        margin: 5px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create-event-is-maintenance-checkbox {
        width: 100%;
        display: flex;
        .bp4-popover-wrapper {
            margin-left: 10px;
        }
    }

    .button-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .bp4-button {
            width: 100px;
            font-size: 12px;
        }
        .bp4-button:first-child {
            width: auto;
            color: #bfccd6;
        }

        align-items: center;

        div {
            color: #8e9baf;
        }
    }
}

.open-user-action-buttons {
    padding-top: 20px;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.reply-user-action-icons {
    display: flex;
    justify-content: space-between;
    width: 60px;
}

.user-action-icons {
    display: flex;
    justify-content: space-between;
    width: 85px;
}

.user-action-icon {
    height: 16px;
    width: 16px;
    color: #bfccd6;
    &:hover {
        cursor: pointer;
    }
    visibility: hidden;
}

.event-rows-container {
    overflow-y: auto;
    overflow-x: hidden;
}

.event-ship-item,
.event-ship-item:hover {
    color: white;
    min-width: 190px;
}

.events-loading-spinner {
    display: inline-block;
}

.seen-rect {
    padding: 2px 10px;
    border-radius: 2px;
    border: solid 1px #808fa3;
    color: #bfccd6;
    font-weight: 100;
    font-size: 14px;
}

.seen-line {
    height: 0px;
    border-bottom: solid 1px #808fa3;
    flex-grow: 99;
    margin-top: auto;
    margin-bottom: auto;
    &.right {
        flex-grow: 33;
    }
}

.event-date-input * {
    outline: none;
}

.event-tooltip-wrapper-modal-background {
    background-color: rgba(255, 255, 255, 0);
    position: fixed;
    overflow: auto;
    user-select: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.event-tooltip-wrapper {
    filter: drop-shadow(0 3px 14px rgba(0, 0, 0, 0.4));
    z-index: 101;
    box-sizing: border-box;

    .event-tooltip {
        font-size: 12px;
        width: 400px;
        background-color: #30404d;
        padding: 20px;
        border-radius: 3px;
        position: relative;

        max-height: 300px;

        &:hover {
            background-color: var(--background-panel);
        }

        &.small-charter {
            max-height: 300px;
        }

        .event-row-header {
            padding-top: 5px;
            font-size: 12px;
            color: #bfccd6;
        }

        .event-tooltip-attribution {
            display: flex;
            justify-content: space-between;
        }

        .event-tooltip-inner-body {
            &:not(:first-child) {
                border-top: 1px solid #808fa3;
                padding-top: 10px;
            }
            &:not(:last-child) {
                padding-bottom: 10px;
            }
        }

        .event-tooltip-display-time {
            color: #bfccd6;
        }

        .tooltip-event-reply {
            padding: 5px 0px 5px 10px;
        }

        .close-button {
            position: absolute;
            right: 25px;
            top: 10px;

            a {
                color: #c3c3c3;
                position: fixed;
            }
        }

        .scrollable {
            overflow: auto;
            max-height: calc(300px - 40px);

            &.multiple-events {
                margin-top: 10px;
                max-height: calc(300px - 40px - 40px);
            }

            &.small-charter {
                max-height: 200px;
            }

            &.small-charter.multiple-events {
                margin-top: 10px;
                max-height: calc(200px - 40px);
            }
        }

        .event-tooltip-view-all {
            position: absolute;
            right: 20px;
            bottom: 5px;
        }
    }

    .arrow-left {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        padding-left: 10px;

        border-right: 10px solid #30404d;
    }

    .arrow-bottom {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        padding-bottom: 10px;

        border-top: 10px solid #30404d;
    }
}

.at-mentions,
.at-mentions-read-only {
    margin: 10px 0px;
    textarea {
        color: white;
    }
    .at-mentions__highlighter,
    .at-mentions-read-only__highlighter {
        line-height: normal;

        strong {
            border-radius: 3px;
            background-color: #7d8d9a !important;
        }
    }
}

.at-mentions {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    background-color: rgba(16, 22, 26, 0.3);
    color: white;
    margin: 10px 0px;
}

.at-mentions__suggestions {
    .user {
        display: flex;
        justify-content: space-between;
        padding: 3px 15px;
        .name {
            color: #7d8d9a;
        }
        .handle {
            padding-right: 10px;
        }
    }

    background-color: #30404d !important;
    padding: 10px;
    color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    z-index: 101 !important;
}

.at-mentions__suggestions__item:hover {
    background-color: #5a67f6;
}

.at-mentions__suggestions__list {
    max-height: 200px;
    overflow-y: auto;
}
