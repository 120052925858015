@import "../../styles/Header";
@import "../../styles/Color";
@import "../../styles/Text";

$panel-header-height: 50px;
$left-axis-width: 80px;

.comparison-control-form {
    width: 300px;
    padding: 25px;
}

.performance-page {
    @extend %page-container-style;

    .anomaly-detected-container {
        display: flex;
        margin-top: 3px;

        .anomaly-detected {
            @extend %tooltip-content;
            margin-left: 3px;
        }
    }

    .switch {
        display: flex;
        align-items: center;

        .bp4-control {
            margin-bottom: 0;
        }
    }

    .page-settings {
        @extend %page-header-style;

        .date-range-control-container {
            margin-left: 10px;
            display: flex;
        }

        .engine-options {
            display: flex;
            align-items: center;
            margin-left: 30px;

            .leading-text {
                margin-right: 5px;
            }
        }
    }

    .performance-widget {
        display: flex;
        flex-direction: column;
        @extend %page-body-style;
        padding: 7px;

        .panel-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            &.top-panel-wrapper {
                flex: 1 1 40%;
                width: 100%;
                height: 40%;
                margin-bottom: 7px;
            }

            &.bottom-panel-wrapper {
                flex: 1 0 60%;
                width: 100%;
                height: 60%;
                max-height: 60%;

                .export-button {
                    float: right;
                    margin-left: 5px;
                }

                .performance-model {
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                    height: calc(100% - #{2 * $panel-header-height});

                    .table-container {
                        display: flex;
                        flex-direction: column;
                        width: 450px;
                        height: 100%;
                        border-left: 1px solid $nl-dark-gray-1;
                    }
                }

                .bottom-panel-title {
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
        }

        .spinner-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .panel-row {
            display: flex;
            user-select: none;
            margin-right: 70px;
        }

        .scatter-legend {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding-left: $left-axis-width;
            flex-wrap: wrap;

            .scatter-legend-item {
                white-space: nowrap;
                margin-right: 15px;
                font-size: 12px;
            }
        }

        /*********** Plottable CSS ********/
        .time-series {
            height: 100%;

            .plottable {
                cursor: grab;
            }
        }

        .chart-render-container {
            .left-axis {
                width: $left-axis-width;
                height: calc(100% - 60px);
            }

            .right-axis {
                display: none;
            }

            .component .gridlines {
                border: 1px solid #565c6b;
            }

            .label {
                text-align: left;
            }

            .hover-point {
                will-change: cx, cy;
                transition:
                    cy 0.3s ease,
                    cx 0.3s ease;
            }
        }
    }
}

.anomaly-detected-tooltip {
    white-space: break-spaces;
}
