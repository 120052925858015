@import "../../styles/Text";
@import "../../styles/Color";

.small-metric-label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .value {
        @extend %heading-medium;
        color: $nl-primary;
    }

    .label,
    .unit {
        @extend %body-small;
        color: $nl-secondary;
    }
}
