@import "../styles/Text";
@import "../styles/Color";

.nl-value-with-unit-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .value-container {
        @extend %number;
        color: $nl-primary;
    }

    .unit-container {
        @extend %unit;
        color: $nl-secondary;

        &.prefix {
            margin-right: 0.2em;
        }

        &.postfix {
            margin-left: 0.2em;
        }
    }
}
