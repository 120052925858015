@import "../../styles/Buttons";
@import "../../styles/Input";
@import "../../styles/Media";
@import "../../styles/Text";

.route-optimization-form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    justify-content: space-between;

    .section:first-of-type {
        h2 {
            margin-top: 0;
        }
    }

    h2 {
        margin: 1em 0 1em;
        font-size: 16px;
    }

    h3 {
        margin: 0 0 1em;
        font-size: 14px;
    }

    h2.no-margin-bottom,
    h3.no-margin-bottom {
        margin-bottom: 0;
    }

    .title-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .toggle-button {
            height: fit-content;
            margin-left: 0.5em;
        }
    }

    .subtitle {
        @extend %footnote-secondary;
        margin: 0 0 1em;
    }

    .section-content.collapsed {
        height: 0;
        overflow: hidden;
    }

    .subsection {
        margin: 0 2em;
    }

    .column-container {
        display: flex;
        flex-direction: row;
        padding: 0 2em;

        &:not(:first-child) {
            margin-top: 1em;
        }

        .column {
            width: 400px;
            margin: 0 1em 1em 0;

            &:last-of-type {
                width: auto;
            }

            &.full {
                width: 100%;
            }

            .label-wrap {
                display: inline-block;
                width: 200px;

                &.has-tooltip label {
                    @extend %tooltip-label;
                }
            }

            @include custom-media-query("screen and (max-width: 1415px)") {
                width: 275px;

                .label-wrap {
                    width: 150px;
                    min-width: 150px;
                }
            }

            .form-input-row {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 1em;
                }

                h3 {
                    font-size: 1em;
                }

                .label.error {
                    border-bottom: 1px solid var(--intent-error);
                }

                .full {
                    width: 100%;
                }

                .input {
                    @extend %number-hide-arrows;
                    width: 100px;
                    height: 30px;

                    &:disabled:hover {
                        cursor: not-allowed;
                    }

                    &.error {
                        border-color: $nl-danger;
                    }

                    &.text {
                        width: 200px;
                    }
                }

                .text-input-group {
                    display: flex;
                    align-items: center;

                    > :last-child {
                        margin: 0;
                    }

                    .input {
                        width: 250px;
                        margin-right: 10px;
                    }
                }

                .input-unit {
                    display: flex;
                    align-items: center;

                    .unit {
                        @extend %unit-light;
                        margin-left: 0.5em;
                        font-size: 0.9em;
                        letter-spacing: 0.1em;
                    }
                }
            }
        }
    }

    @include media-query("lt-md") {
        .column-container {
            flex-direction: column;

            .label-wrap {
                width: 250px;
            }
        }
    }
}
