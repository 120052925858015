@import "../../styles/Buttons";
@import "../../styles/Color";
@import "../../styles/Text";

.route-create-panel {
    height: 600px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    & * {
        font-weight: 200;
    }

    & > .side {
        width: 100%;
        height: 100%;
        padding: 20px;

        & > *:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .button {
        @extend %pill-button;

        &.add {
            @extend %primary-button;
        }

        &.generate-preview {
            @extend %primary-button-minimal;
        }
    }

    & > .left {
        display: flex;
        flex-direction: column;

        overflow: hidden;

        & > * {
            flex: 1 0 auto;
        }

        & > .instructions {
            @extend %body-small;
            color: $nl-secondary;
        }

        & > .port-input-label {
            @extend %heading-medium;
            margin-top: 0;
        }

        & > .port-inputs {
            display: flex;
            & > * {
                flex-grow: 1;
                margin-right: 10px;
            }
        }

        & > .code-area {
            flex-shrink: 1;
        }

        & > .generate-preview {
            align-self: flex-end;
        }
    }

    & > .right {
        border-left: solid 1px $nl-dark-gray-1;

        display: flex;
        flex-direction: column;

        & > .preview-container {
            flex-grow: 1;

            margin-bottom: 10px;

            display: flex;
            align-items: center;
            justify-content: center;

            & > .preview-placeholder {
                @extend %body-medium;
                color: $nl-secondary;

                width: 320px;
                text-align: center;
            }
        }

        & > .duplicate-note {
            @extend %body-small;
            padding: 8px;
            margin: 10px 0;
            border-radius: 8px;
            text-align: right;
            color: var(--accent-dark);
            background-color: $nl-blue-5;
        }

        & > .button-group {
            padding-top: 10px;

            display: flex;
            justify-content: flex-end;

            & > button {
                width: 125px;
            }

            & > *:not(:last-child) {
                margin-right: 12px;
            }
        }
    }
}
