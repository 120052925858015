@import "../../styles/Header";
@import "../../styles/Color";
@import "../../styles/Text";

$widget-header-height: 50px;
$widget-header-margin-bottom: 1px;

.rec-history-widget {
    padding: 5px;
    height: 100%;

    .widget-header-container {
        height: $widget-header-height;
        margin-bottom: $widget-header-margin-bottom;
        padding: 0 15px;
        background-color: var(--background-panel);
    }

    .nl-table-container {
        background-color: transparent;

        .nl-table-main-container {
            background-color: var(--background-table-container);
        }
    }

    .widget-body-container {
        height: calc(100% - #{$widget-header-height} - #{$widget-header-margin-bottom});
        padding: 30px 50px;
        background-color: var(--background-panel);

        .row-edit-delete-buttons {
            display: flex;
            justify-content: space-evenly;
        }
    }
}

.delete-dialog {
    padding-bottom: 0px;

    .dialog-body {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.edit-dialog {
    width: 800px;
    height: 700px;

    .content-container {
        .edit-dialog-row {
            display: flex;
            align-items: baseline;
            margin-bottom: 20px;
        }
    }

    .dialog-footer {
        text-align: right;
    }
}

.rec-dialog-toast {
    z-index: 1001 !important;
}
