@import "../../../styles/Color";

$card-metrics-panel-header-height: 20px;
$card-metrics-panel-footer-height: 40px;

.card-metrics-panel {
    position: relative;
    width: 100%;
    height: 100%;
    color: $nl-secondary;
    background-color: var(--background-drawer);

    .card-metrics-panel-header {
        width: 100%;
        height: $card-metrics-panel-header-height;
        padding: 5px 10px 0;

        .header-left {
            float: left;

            .info-icon-tooltip {
                &:focus {
                    outline: none;
                }
            }
        }

        .header-right {
            display: flex;
            justify-content: space-between;
            float: right;

            & > :hover {
                cursor: pointer;
            }

            > :last-child {
                margin-left: 10px;
            }
        }
    }

    .card-metrics-panel-body {
        width: 100%;
        height: calc(100% - #{$card-metrics-panel-header-height});
        overflow: hidden auto;

        &.edit-mode {
            height: calc(100% - #{$card-metrics-panel-header-height} - #{$card-metrics-panel-footer-height});

            .react-grid-item {
                cursor: pointer;

                .card-container:hover {
                    outline: 1px solid $nautilus-blue;
                }
            }
        }

        .react-grid-placeholder {
            background: $nl-dark-gray-5;
        }
    }

    .card-metrics-panel-footer {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        border-top: 1px solid $nl-dark-gray-4;
        z-index: 1;
        transition:
            height 1s,
            padding 1s;

        &.show {
            padding: 5px 0;
            height: $card-metrics-panel-footer-height;
        }

        > :last-child {
            margin: 0 10px;
        }
    }
}
