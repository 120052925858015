@import "../../../styles/Color";

$padding-top: 1em;
$padding-left: 20px;
$weather-selector-height: 1.5em;

.weather-selector-container {
    height: $weather-selector-height;
    padding-top: $padding-top;
    display: flex;
    flex-direction: row;

    .weather-legend {
        margin-right: 1.5em;

        .line {
            display: inline-block;
            width: 20px;
            height: 2px;
            background-color: $nl-turquoise-4;
            margin-right: 5px;
            margin-bottom: 4px;
        }
    }
}

.hourly-trace-panel {
    height: calc(100% - $padding-top - $weather-selector-height);
    overflow-y: auto;
    padding-left: $padding-left;

    .line-chart-container {
        height: 45%;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }
}
