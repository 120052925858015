$media_queries: (
    xs: "screen and (max-width: 599px)",
    sm: "screen and (min-width: 600px) and (max-width: 959px)",
    md: "screen and (min-width: 960px) and (max-width: 1279px)",
    lg: "screen and (min-width: 1280px) and (max-width: 1919px)",
    xl: "screen and (min-width: 1920px) and (max-width: 5000px)",
    lt-sm: "screen and (max-width: 599px)",
    lt-md: "screen and (max-width: 959px)",
    lt-lg: "screen and (max-width: 1279px)",
    lt-xl: "screen and (max-width: 1919px)",
    gt-xs: "screen and (min-width: 600px)",
    gt-sm: "screen and (min-width: 960px)",
    gt-md: "screen and (min-width: 1280px)",
    gt-lg: "screen and (min-width: 1920px)",
);

@mixin media-query($breakpoints) {
    $conditions: ();

    @each $breakpoint in $breakpoints {
        $conditions: append($conditions, #{map-get($media_queries, $breakpoint)}, comma);
    }

    @media #{$conditions} {
        @content;
    }
}

@mixin custom-media-query($condition) {
    @media #{$condition} {
        @content;
    }
}
