/**
 * These styles were pulled directly from compiled CSS on the Blueprint website. They're in Blueprint, but omitted from the source.
 * These can be removed after we either (a) Upgrade Blueprint or (b) Fix our asset pipeline to include Blueprint's Drawer component styles.
 */

.bp4-drawer {
    background: #fff;
    -webkit-box-shadow:
        0 0 0 1px rgba(16, 22, 26, 0.1),
        0 4px 8px rgba(16, 22, 26, 0.2),
        0 18px 46px 6px rgba(16, 22, 26, 0.2);
    box-shadow:
        0 0 0 1px rgba(16, 22, 26, 0.1),
        0 4px 8px rgba(16, 22, 26, 0.2),
        0 18px 46px 6px rgba(16, 22, 26, 0.2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding: 0;
    overflow: hidden scroll;
}

.bp4-drawer:focus {
    outline: 0;
}

.bp4-drawer.bp4-position-top {
    height: 50%;
    left: 0;
    right: 0;
    top: 0;
}

.bp4-drawer.bp4-position-top.bp4-overlay-appear,
.bp4-drawer.bp4-position-top.bp4-overlay-enter {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.bp4-drawer.bp4-position-top.bp4-overlay-appear-active,
.bp4-drawer.bp4-position-top.bp4-overlay-enter-active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer.bp4-position-top.bp4-overlay-exit {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.bp4-drawer.bp4-position-top.bp4-overlay-exit-active {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer.bp4-position-bottom {
    bottom: 0;
    height: 50%;
    left: 0;
    right: 0;
}

.bp4-drawer.bp4-position-bottom.bp4-overlay-appear,
.bp4-drawer.bp4-position-bottom.bp4-overlay-enter {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}

.bp4-drawer.bp4-position-bottom.bp4-overlay-appear-active,
.bp4-drawer.bp4-position-bottom.bp4-overlay-enter-active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer.bp4-position-bottom.bp4-overlay-exit {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.bp4-drawer.bp4-position-bottom.bp4-overlay-exit-active {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer.bp4-position-left {
    bottom: 0;
    left: 0;
    top: 0;
    width: 50%;
}

.bp4-drawer.bp4-position-left.bp4-overlay-appear,
.bp4-drawer.bp4-position-left.bp4-overlay-enter {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.bp4-drawer.bp4-position-left.bp4-overlay-appear-active,
.bp4-drawer.bp4-position-left.bp4-overlay-enter-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer.bp4-position-left.bp4-overlay-exit {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.bp4-drawer.bp4-position-left.bp4-overlay-exit-active {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer.bp4-position-right {
    bottom: 0;
    right: 0;
    top: 0;
    width: 50%;
}

.bp4-drawer.bp4-position-right.bp4-overlay-appear,
.bp4-drawer.bp4-position-right.bp4-overlay-enter {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

.bp4-drawer.bp4-position-right.bp4-overlay-appear-active,
.bp4-drawer.bp4-position-right.bp4-overlay-enter-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer.bp4-position-right.bp4-overlay-exit {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.bp4-drawer.bp4-position-right.bp4-overlay-exit-active {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(.bp4-position-right):not(
        .bp4-vertical
    ) {
    bottom: 0;
    right: 0;
    top: 0;
    width: 50%;
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(.bp4-position-right):not(
        .bp4-vertical
    ).bp4-overlay-appear,
.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(.bp4-position-right):not(
        .bp4-vertical
    ).bp4-overlay-enter {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(.bp4-position-right):not(
        .bp4-vertical
    ).bp4-overlay-appear-active,
.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(.bp4-position-right):not(
        .bp4-vertical
    ).bp4-overlay-enter-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(.bp4-position-right):not(
        .bp4-vertical
    ).bp4-overlay-exit {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(.bp4-position-right):not(
        .bp4-vertical
    ).bp4-overlay-exit-active {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(
        .bp4-position-right
    ).bp4-vertical {
    bottom: 0;
    height: 50%;
    left: 0;
    right: 0;
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(
        .bp4-position-right
    ).bp4-vertical.bp4-overlay-appear,
.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(
        .bp4-position-right
    ).bp4-vertical.bp4-overlay-enter {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(
        .bp4-position-right
    ).bp4-vertical.bp4-overlay-appear-active,
.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(
        .bp4-position-right
    ).bp4-vertical.bp4-overlay-enter-active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(
        .bp4-position-right
    ).bp4-vertical.bp4-overlay-exit {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.bp4-drawer:not(.bp4-position-top):not(.bp4-position-bottom):not(.bp4-position-left):not(
        .bp4-position-right
    ).bp4-vertical.bp4-overlay-exit-active {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition-delay: 0;
    transition-delay: 0;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-dark .bp4-drawer,
.bp4-drawer.bp4-dark {
    background: #30404d;
    -webkit-box-shadow:
        0 0 0 1px rgba(16, 22, 26, 0.2),
        0 4px 8px rgba(16, 22, 26, 0.4),
        0 18px 46px 6px rgba(16, 22, 26, 0.4);
    box-shadow:
        0 0 0 1px rgba(16, 22, 26, 0.2),
        0 4px 8px rgba(16, 22, 26, 0.4),
        0 18px 46px 6px rgba(16, 22, 26, 0.4);
    color: #f5f8fa;
}

.bp4-drawer-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0;
    -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    min-height: 40px;
    padding: 5px 5px 5px 20px;
    position: relative;
}

.bp4-drawer-header .bp4-icon,
.bp4-drawer-header .bp4-icon-large {
    color: #5c7080;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 10px;
}

.bp4-drawer-header .bp4-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    line-height: inherit;
    margin: 0;
}

.bp4-drawer-header .bp4-heading:last-child {
    margin-right: 20px;
}

.bp4-dark .bp4-drawer-header {
    -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4);
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4);
}

.bp4-dark .bp4-drawer-header .bp4-icon,
.bp4-dark .bp4-drawer-header .bp4-icon-large {
    color: #a7b6c2;
}

.bp4-drawer-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    line-height: 18px;
    overflow: auto;
}

.bp4-drawer-footer {
    -webkit-box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
    box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 10px 20px;
    position: relative;
}

.bp4-dark .bp4-drawer-footer {
    -webkit-box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4);
    box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4);
}
