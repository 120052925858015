@import "../../styles/Text";

.not-found-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-icon {
        display: flex;
        align-items: center;

        h2 {
            margin-left: 1em;
            font-size: 16px;
        }
    }

    .description {
        @extend %footnote-secondary;
        font-size: 14px;
    }
}
