@import "../../styles/Text";
@import "../../styles/Color";

.bar-chart-container {
    position: relative;
    width: 100%;
    height: calc(100% + 25px);

    /*********** Plottable CSS ***********/
    .chart-render-container {
        .left-axis {
            width: 100px;
            height: calc(100% - 45px);

            // Modify left axis tick mark label style
            .axis {
                text {
                    @extend %body-small;
                    fill: $nl-secondary;
                }
            }
        }

        .plottable {
            .component-group {
                .bar-plot {
                    // Modify bar label style
                    .bar-label-text-area {
                        text {
                            @extend %body-small;
                            fill: $nl-primary;

                            font-weight: 700;
                        }
                    }
                }
            }

            .axis {
                .tick-mark-container {
                    display: none;
                }

                line.baseline {
                    stroke-width: 0;
                }
            }

            .x-axis {
                .tick-label-container {
                    transform: translate(0, 5px) !important;
                }

                // Modify x axis tick mark label style
                text.tick-label {
                    @extend %body-xs;
                    fill: $nl-tertiary;
                }
            }
        }

        .right-axis {
            display: none;
        }
    }
}
