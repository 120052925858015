@import "../../styles/Color";
@import "../../styles/Text";

@keyframes spin {
    to {
        transform: rotate(-360deg);
    }
}

.panel.history-view {
    .new-simulation-button {
        padding: 0.75em 1.5em;
        border-radius: 4em;
    }

    .history-table-container {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;

        &:has(.sub-table-row) {
            margin-left: -20px;
            width: calc(100% + 20px);
            overflow-x: hidden;
        }

        .history-table {
            width: 100%;
            table-layout: fixed;

            tbody tr {
                height: 40px;
            }

            .sub-table tbody tr {
                height: 120px;
            }

            thead {
                tr:not(.divider) {
                    height: 40px;
                    th {
                        top: 0;
                    }
                }

                .divider {
                    height: 1px;
                    th {
                        z-index: 10;
                        top: 40px;
                    }
                }
            }

            tr {
                th {
                    text-align: left;
                    vertical-align: bottom;

                    &:not(:last-child) {
                        margin-right: 2em;
                    }
                }

                &.divider {
                    height: 1px;
                    margin-left: 0;
                }

                &:not(.divider) {
                    th,
                    td {
                        font-weight: 300;

                        &.pad-left {
                            padding-left: 1em;
                        }

                        &.last-fixed {
                            box-shadow: 0.5px 0 0 $nl-gray-3;
                        }

                        &.row-id {
                            @extend %small-unit;
                            line-height: inherit;
                            font-style: normal;
                        }
                    }
                }

                &.odd {
                    th,
                    td {
                        background-color: var(--background-panel);
                    }
                }

                &.even {
                    th,
                    td {
                        background-color: var(--background-table-row-light);
                    }
                }

                td {
                    &.toggle {
                        width: 30px;
                    }

                    &.status {
                        text-transform: capitalize;

                        &.draft {
                            color: $nl-intent-none;
                        }
                        &.simulating {
                            color: $nl-intent-warning;

                            span.simulating {
                                animation: spin 3s linear infinite;
                            }
                        }
                        &.complete {
                            color: $nl-intent-success;
                        }
                        &.error {
                            color: $nl-intent-danger;
                        }
                    }

                    &.delete {
                        height: 40px;
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        padding-right: 1em;

                        .delete-button {
                            width: fit-content;
                        }
                    }

                    &.action-buttons {
                        padding: 0 1em;
                        text-align: right;
                        text-wrap: nowrap;

                        &.hidden {
                            visibility: hidden;
                        }
                    }

                    .unit {
                        @extend %unit-light;
                        text-wrap: nowrap;
                        display: inline-block;
                        height: 1em;
                    }
                }
            }

            .sub-table-row {
                width: 100%;
                border-left: 3em solid transparent;
                border-bottom: 1em solid transparent;

                td .sub-table-container {
                    max-height: 400px;
                    border: none !important;
                    overflow-x: auto;

                    table {
                        min-width: 100%;
                        width: max-content;

                        thead {
                            th {
                                position: sticky;
                                top: 0;
                                z-index: 2;
                                font-size: 14px;
                                font-weight: 300;

                                &.fixed {
                                    z-index: 3;
                                }
                            }

                            .bold th {
                                font-weight: 800;
                            }
                        }

                        td {
                            &.fixed {
                                position: sticky;
                                z-index: 1;
                            }

                            .setpoints {
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }

        table:not(.sub-table) {
            > thead tr th {
                background-color: var(--background-panel);
                position: sticky;
                z-index: 10;
                vertical-align: bottom;

                &:last-child {
                    width: 100%;
                }
            }

            tr:not(.sub-table-row) td {
                background-clip: padding-box;
            }
        }

        .has-tooltip {
            @extend %tooltip-content;
        }
    }
}
