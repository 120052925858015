@import "../../styles/Header.scss";
@import "../../styles/Color.scss";

.module-header {
    width: 100%;
    height: $header-height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--background-panel);

    .module-header-title {
        @extend %page-header-title-style;
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 10px;
    }

    .module-header-left-section,
    .module-header-right-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
    }

    .module-header-left-section .bp4-divider {
        height: 24px;
    }

    .module-header-right-section {
        justify-self: flex-end;
    }
}
