@import "../../styles/Color";
@import "../../styles/Text";
@import "../../styles/Scrollbar";

$nl-table-border-width: 1px;
$nl-table-fixed-column-border-width: 2px;

$nl-table-actionables-height: 30px;
$nl-table-actionables-margin-bottom: 10px;

$nl-table-group-header-content-height: 35px;
$nl-table-header-content-height: 25px;
$nl-table-header-quick-sort-width: 14px;
$nl-table-header-line-height: 14px;

$nl-table-header-filter-content-height: 34px;
$nl-table-header-filter-line-height: 25px;

$nl-table-row-height: 35px;

$nl-table-scrollbar-buffer: $nl-scrollbar-size;
$bp-table-default-padding: 8px;
$nl-table-height-buffer: $nl-table-scrollbar-buffer - $bp-table-default-padding;

// TODO: make these dynamic (utilize flex box instead?)
$nl-table-footer-height: $nl-table-row-height * 2 + $nl-table-height-buffer;
$nl-table-footer-label-cell-width: 135px;

%nl-small {
    height: 24px;
    min-height: 24px;
    line-height: $nl-table-header-filter-line-height;
    font-size: 12px;
    padding: 0 10px;
}

.nl-table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--background-table-container);

    .nl-table-actionables-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        height: $nl-table-actionables-height;
        line-height: $nl-table-actionables-height;
        margin-bottom: $nl-table-actionables-margin-bottom;
    }

    .nl-tables-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border: var(--border-table);

        &.show-actionables {
            height: calc(100% - #{$nl-table-actionables-height} - #{$nl-table-actionables-margin-bottom});
        }

        .nl-table-main-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;

            &.show-footer {
                height: calc(100% - #{$nl-table-footer-height});

                .nl-table-main-content-container {
                    &:not(.fixed) {
                        @extend %hide-scrollbar;
                    }
                    &.fixed {
                        margin-bottom: 0;
                    }
                }
            }

            .nl-table-main-content-container {
                &:not(.fixed) {
                    overflow-x: scroll;
                    overflow-y: hidden;

                    .nl-table-body-container {
                        @extend %show-scrollbar;
                    }
                }
                &.fixed {
                    margin-bottom: $nl-scrollbar-size;

                    .nl-table-body-container {
                        @extend %hide-scrollbar;
                    }
                }

                tr {
                    th:not(:last-child),
                    td:not(:last-child) {
                        border-right: var(--border-table);
                    }
                }

                .nl-table-body-container {
                    tr:not(:last-child) {
                        th,
                        td {
                            border-bottom-width: $nl-table-border-width;
                        }
                    }
                }
            }
        }

        .nl-table-footer-container {
            width: calc(100% - #{$nl-table-footer-label-cell-width});
            height: $nl-table-footer-height;
            margin-left: $nl-table-footer-label-cell-width;
            overflow-x: scroll;
            overflow-y: hidden;

            tr {
                td {
                    font-weight: 600;
                    background-color: var(--background-panel-light) !important;

                    &.fixed {
                        position: fixed;
                        margin-left: -$nl-table-footer-label-cell-width;
                        border-right: var(--border-table);
                        border-width: $nl-table-fixed-column-border-width !important;
                        z-index: 1;
                    }

                    &:not(.fixed) {
                        padding-right: calc(#{$bp-table-default-padding} - 1px);
                    }
                }

                &:first-child {
                    td {
                        border-top: var(--border-table);
                    }
                }
                &:last-child {
                    td {
                        padding-bottom: $nl-table-scrollbar-buffer;
                    }
                }
            }
        }
    }

    .nl-table-head-container,
    .nl-table-body-container {
        overflow-x: hidden;

        & > table {
            width: 100%;
        }
    }

    .scrollbar-buffer {
        width: $nl-table-scrollbar-buffer;
    }

    .nl-table-head-container {
        overflow-y: hidden;

        tr {
            position: relative;

            th {
                border-bottom: var(--border-table);
                background-color: var(--background-table-row-light);

                &.scrollbar-buffer {
                    position: absolute;
                    right: 0;
                }

                &.compound-actionable {
                    .nl-table-header-cell-content-container {
                        .header {
                            margin-right: 3px;
                        }
                    }
                }

                &.nl-table-group-header-cell-container {
                    width: 100%;
                    height: $nl-table-group-header-content-height;
                    padding: 8px 8px 3px;
                    display: flex;
                    justify-content: space-between;

                    .note {
                        width: 60%;
                        text-align: right;
                        line-height: 0.6;

                        .asterisk {
                            vertical-align: sub;
                        }

                        .note-content {
                            font-size: 0.75em;
                            margin-left: 3px;
                            font-style: italic;
                        }
                    }
                }

                .nl-table-header-cell-content-container {
                    display: flex;
                    flex-direction: row;
                    height: $nl-table-header-content-height;

                    > span {
                        &:first-of-type {
                            flex: 1 1;
                        }

                        line-height: $nl-table-header-line-height;
                    }

                    .header {
                        padding: 8px 8px 3px;
                        white-space: break-spaces;

                        &.has-tooltip {
                            @extend %tooltip-content;
                        }
                    }

                    .header-compound-action-button {
                        min-width: 20px;
                        min-height: 20px;
                        margin: 3px 3px 0 0;

                        > span {
                            margin-top: 4px;
                        }
                    }
                }

                .nl-table-sub-header-cell-container {
                    width: 100%;
                    height: $nl-table-header-content-height;

                    .sub-header-content {
                        width: 100%;
                        height: 100%;
                        padding: 5px 8px 3px;
                        @extend %text-ellipsis;
                        font-style: italic;
                        line-height: $nl-table-header-line-height;
                    }
                }

                .nl-table-header-filter-container {
                    height: $nl-table-header-filter-content-height;
                    padding: 4px 8px 5px;
                    border-top: var(--border-table);
                    overflow: hidden;
                    @extend %text-ellipsis;

                    .nl-table-filter-container {
                        input {
                            @extend %nl-small;
                        }
                        // TODO: remove this once custom filters are moved to under DropdownContainer
                        button {
                            @extend %nl-small;
                        }
                    }
                }
            }
        }
    }

    .nl-table-body-container {
        overflow-y: scroll;
    }

    table {
        tr {
            overflow: hidden;

            th,
            td {
                justify-content: center;
                font-weight: 400;
                overflow: hidden;

                &:last-child {
                    &.fixed:not(.nl-table-group-header-cell-container) {
                        border-right: var(--border-table);
                        border-width: $nl-table-fixed-column-border-width !important;
                    }
                }

                .nl-cell-loading {
                    width: 100%;
                    height: 50%;
                }

                .nl-cell-prefixer {
                    @extend %currency;
                    width: min-content;
                    float: left;
                }

                .favorable-value {
                    color: #6fb994;
                }
                .unfavorable-value {
                    color: #e56666;
                }
            }

            &:nth-child(odd) {
                td {
                    background-color: var(--background-table-row-dark);
                }
            }
            &:nth-child(even) {
                td {
                    background-color: var(--background-table-row-light);
                }
            }
        }

        thead {
            height: 100%;

            tr {
                display: flex;
                flex-direction: row;
                height: 100%;

                th {
                    height: 100%;
                    padding: 0;
                    color: $nl-gray-5 !important;

                    .header {
                        display: flex;
                        flex-direction: row;
                        overflow: hidden;

                        &:focus {
                            outline: none;
                        }

                        &.quick-sortable {
                            cursor: pointer;
                        }
                    }

                    .sub-header {
                        font-size: 14px;
                        font-style: italic;
                    }
                }
            }
        }

        tbody {
            tr {
                display: flex;
                flex-direction: row;
                min-height: $nl-table-row-height;

                td {
                    display: block;
                    padding: 8px;
                    height: 35px;
                    @extend %text-ellipsis;

                    & > span {
                        display: block;
                        overflow: hidden;
                        @extend %text-ellipsis;
                    }
                }

                .show-on-hover > * {
                    visibility: hidden;
                }

                &:first-child {
                    td {
                        box-shadow: none !important;
                    }
                }

                &.hover {
                    background-color: var(--background-table-row-hover);

                    td {
                        background-color: transparent !important;

                        &.show-on-hover > * {
                            visibility: initial;
                        }
                    }
                }
                &.selected {
                    background-color: var(--background-table-row-hover);

                    td {
                        background-color: transparent !important;
                    }
                }
            }
        }

        &:not(.bp4-interactive) {
            tbody tr:hover {
                background-color: initial;
            }
        }
    }
}

.nl-table-header-cell-tooltip {
    max-width: 500px;

    .nl-table-collapsed-column-name {
        margin-bottom: 8px;
        color: $nl-secondary;
    }
}
