@import "../../../styles/Header";

.panel-header {
    display: flex;
    flex-direction: row;
    height: $header-height;
    border-bottom: 1px solid var(--accent-dark);
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-panel);

    .page-header {
        @extend %page-header-title-style;
        margin-right: 10px;
    }

    .title {
        font-size: 14px;
    }
}

.custom-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
}
