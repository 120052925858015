@import "../../styles/Header";
@import "../../styles/Scrollbar";
@import "../../styles/Media";
@import "../../styles/Text";

.historical-widget {
    @extend %page-container-style;

    .historical-widget-header {
        @extend %page-header-style;

        .exclude-wind-selector-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 10px;
        }
    }

    .historical-widget-body {
        display: flex;
        flex: 1;
        @extend %page-body-style;
    }
}

.historical-widget .side-panel {
    background-color: var(--background-panel);
    width: 275px;
    height: 100%;
    border: 1px solid var(--accent-dark);
    overflow: auto;
    @extend %hide-scrollbar;
}

.historical-widget .main-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 7px;
    position: relative;
    height: 100%;
}

.historical-widget .chart-panel {
    height: 100%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.historical-widget .main-panel .bottom-panel {
    display: block;
    flex: 1;
    max-height: 50%;
    border: 1px solid #1f222a;
    background-color: var(--background-panel);
    position: relative;
}

.historical-widget .chart-container {
    display: flex;
    flex-direction: column;
}

.historical-widget .mini {
    height: 80px;
    margin-top: -5px;
}

.historical-widget .mini .gridlines {
    display: none;
}

.historical-widget .chart-container.scatter {
    height: calc(50vh - 140px);
    margin: 20px 25px 10px 25px;
}

.historical-widget .main-panel .map-widget {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: var(--background-panel);
}

.historical-widget .main-panel .top-panel .left-panel,
.historical-widget .main-panel .top-panel .right-panel {
    background-color: var(--background-panel);
    margin-bottom: 7px;
}

.historical-widget .main-panel .top-panel .left-panel {
    width: 30%;
    margin-right: 7px;

    &.cii {
        display: flex;
        flex-direction: column;
    }
}

.historical-widget .main-panel .top-panel .right-panel {
    flex: 1;
}

.historical-widget .main-panel .scatter-widget {
    width: 100%;
    height: 100%;
}

.scatter-widget .label {
    text-align: left;
}

.historical-widget .plottable .axis-label text {
    letter-spacing: 0;
    font-family: inherit;
    font-size: 12px;
}

.historical-widget .plottable .component-overflow-visible {
    overflow: hidden;
}

.side-panel .plots {
    max-height: calc(80vh - 50px);
    overflow-y: auto;
    border-bottom: 1px solid black;
}

.side-panel-element {
    color: white;
    cursor: pointer;
    background-color: var(--background-panel);
    border: 1px solid var(--accent-dark);
    border-top: none;
    padding: 13px;
    padding-left: 35px;
    height: 55px;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
}

.side-panel-element.active {
    background-color: #4f72f7;
}

.side-panel .inputs {
    padding-bottom: 200px;
}

.side-panel .popover-target {
    margin-top: 10px;
    width: 80%;
    margin-left: 10%;
}

.side-panel .select-title {
    font-size: 16px;
    margin-top: 50px;
    margin-left: 35px;
}

.side-panel .select-title.second-title,
.side-panel .metric-submit,
.side-panel .metric-cancel {
    margin-top: 25px;
}

.metric-submit {
    color: white !important;
    background-image: none !important;
}

.metric-cancel {
    color: white !important;
    width: 100px;
    float: right;
    margin-right: 25px;
    margin-bottom: 5px;
}

.metric-close {
    background-color: #394b59 !important;
    color: white !important;
    background-image: none !important;
}

.panel-title {
    display: flex;
    height: 50px;
    border-bottom: 1px solid var(--accent-dark);
    font-size: 1.3rem;
    padding-left: 15px;
    font-weight: normal;
    align-items: center;

    .icons {
        padding: 0 10px;
    }

    div.left {
        flex: 1;
        display: flex;

        .tabs {
            display: none;
            margin-left: 20px;
            margin-top: -7px;
        }
    }
}

.map-widget div.left .tabs {
    display: none;
    margin-left: 20px;
    margin-top: 5px;
    height: 45px;
}

.map-widget .panel-title {
    display: none;
}

.scatter-widget .panel-title button {
    margin-right: 5px;
}

.panel-title .left {
    display: flex;
    justify-content: space-between;

    .title-cont {
        display: flex;
        flex-direction: row;
        height: 50px;
        align-items: center;
    }
}

.light {
    font-weight: 200 !important;
}

.panel-title .icons {
    display: flex;
}

.panel-title .button {
    margin-right: 10px;
    color: #9898a0;
}

.panel-title .cal-icon {
    cursor: pointer;
    color: rgb(176, 184, 197);
    padding: 15px;
    box-sizing: content-box;
    font-size: 18px;
}

.panel-title .noon-icon svg {
    width: 18px;
    height: 18px;
    margin: 15px 15px 10px 15px;
    fill: rgb(176, 184, 197);
}

.panel-title .noon-icon:hover {
    cursor: pointer;

    svg {
        fill: #14ccbd;
    }
}

.panel-title .cal-icon:hover {
    color: #14ccbd;
}

.panel-title .export-button {
    color: rgb(176, 184, 197);
    &:hover {
        color: #14ccbd;
    }
}

.bp4-button {
    outline: none !important;
}

.panel-body {
    height: 100%;
    max-height: calc(100% - 50px);
    display: flex;
    flex: 1;
    padding: 20px 30px 15px 30px;
    background-color: var(--background-panel);
}

.scatter-nonideal {
    width: 100%;
    height: 100%;

    > .less-than-two-values,
    .nothing-to-chart {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 12%;

        .create-report-button {
            padding: 0 5px;
            margin-top: 6px;
        }
    }
}

.chart-callout.bp4-callout.bp4-callout-icon {
    color: white;
    > .bp4-icon:first-child {
        color: #c0ccd6;
    }
    .bp4-heading {
        font-size: 16px;
    }
    background: none;
    width: auto;
    a {
        color: #669eff;
    }
}

span.pretend-link {
    text-decoration: none;
    color: #669eff;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #669eff;
    }
}

.overlay-container {
    flex: 1;
    position: relative;
    .chart-overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--background-chart-overlay);
        z-index: 100;
        height: 100%;
        width: 100%;
        > .nothing-to-chart {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.panel-body .chart {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.panel-body .chart .chart-header {
    display: flex;
    margin-bottom: 10px;
    min-height: 70px;
}

.date-selection {
    margin-right: 10px;

    .date-divider {
        margin: 5px 5px 0 5px;
    }

    .dark {
        color: #f5f8fa;
        background-color: #202d37;
        cursor: pointer;
    }
}

.panel-body .chart .chart-header .date-selection span.bp4-tag {
    margin-left: 10px;
    margin-right: 10px;
    background: #202d37;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.panel-body .chart-totals {
    position: relative;
    width: 260px;
    padding-left: 12px;
    overflow: hidden auto;
}

.chart-spinner {
    justify-content: center;
    display: flex !important;
    height: calc(100% - 120px);
    margin: auto;
    align-items: center;
}

.historical-widget .panel-body .chart-spinner {
    margin-top: 5%;
}

.side-panel .bp4-popover-target {
    display: inline;
}

.add-metric-menu {
    width: 355px;
    max-height: 190px;
    overflow: auto;
    background-color: rgba(16, 22, 26, 0.3);
    color: white;
}

.location-panel {
    top: 0;
    width: 250px;
    height: 250px;
    position: absolute;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
}

.location-panel-title {
    font-size: 1.3rem;
    font-weight: 600;
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid var(--accent-dark);
}

.location-panel-info {
    padding: 10px 0 0 25px;
    margin-left: -13px;
}

.location-panel-info .pair-key {
    color: #b0b8c5;
    width: 85px;
    padding-bottom: 5px;
}

.location-panel-info tbody {
    font-size: 14px;
}

.location-panel-legend {
    border: 1px solid white;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin-right: 10px;
}

.historical-widget .main-panel .top-panel {
    flex: 1;
    max-height: 50%;
    margin-bottom: 0;
    display: flex;
}

.compass {
    height: 250px;
}

.compass-ship-icon {
    padding-left: calc(50% - 12px);
    padding-top: 85px;
    position: relative;
}

.component axis y-axis unit-0 {
    width: 300px;
}

.panel-body {
    .chart-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 35px;
    }

    .chart-text {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
}

.create-report-button {
    svg {
        color: var(--color-button-success) !important;
    }

    .bp4-button-text {
        color: var(--color-button-success) !important;
    }

    ::before {
        color: var(--color-button-success) !important;
    }

    :hover {
        color: var(--color-button-success);
    }
}

.panel-body .chart-header .chart-legend {
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;
    font-size: 12px;
    margin-left: 43px;

    .add-value {
        display: inline-flex;
    }

    .series:not(.bp4-popover-wrapper),
    .series-content {
        display: inline-flex;
        align-items: center;
        color: var(--ha-feature);
        margin-right: 8px;
        margin-bottom: 1px;
        padding: 3px 7px;
        background-color: var(--ha-feature-background);
        font-size: 14px;

        .hovered-value,
        .numerical-number {
            font-size: 16px;
        }

        .label-tooltip:hover {
            cursor: pointer;
        }

        .bp4-popover-wrapper:not(.label-tooltip) {
            font-size: 10px;
            margin: 0px 5px 0px -3px;
        }

        span:not(.bp4-popover-target):not(.bp4-popover-wrapper) {
            color: white;
            margin-left: 1.5px;
        }
    }

    // Keep .circle border/background color aligned with
    // ln/web/sec/components/charts/constants.ts/STROKE_COLORS
    .series:nth-child(1) .circle {
        border-color: $nl-turquoise-4;
        background-color: $nl-turquoise-4;
    }
    .series:nth-child(2) .circle {
        border-color: $nl-violet-4;
        background-color: $nl-violet-4;
    }
    .series:nth-child(3) .circle {
        border-color: $nl-orange-3;
        background-color: $nl-orange-3;
    }
    .series:nth-child(4) .circle {
        border-color: $nl-gold-5;
        background-color: $nl-gold-5;
    }
    .series:nth-child(5) .circle {
        border-color: $nl-cobalt-4;
        background-color: $nl-cobalt-4;
    }
    .series:nth-child(6) .circle {
        border-color: #fff;
        background-color: #fff;
    }
    .series:nth-child(7) .circle {
        border-color: $nl-indigo-4;
        background-color: $nl-indigo-4;
    }
    .series:nth-child(8) .circle {
        border-color: $nl-lime-5;
        background-color: $nl-lime-5;
    }

    .noon-switch {
        margin-right: 45px;
        margin-top: -30px;
        font-weight: 300;
    }

    .delete-plotted {
        color: #bfccd6;
        font-size: 18px;
        margin-left: 4px;
        cursor: pointer;
    }
}

.panel-body .right-align-button {
    align-self: center;
    margin-right: 50px;
}

.rowkey-average {
    .large-num {
        @include apply-font($font-nl-roboto);
        font-size: 20px;
        font-weight: 100;
        line-height: 20px;
    }

    .key-name {
        font-size: 12px;
        color: #b0b8c5;
    }

    .unit-container.small-unit {
        @extend %small-unit;

        &.prefix {
            position: absolute;
            transform: translate(-1em, 0.4em);
        }
    }

    .average-info {
        display: flex;
        align-items: baseline;
        margin-top: 15px;
    }
}

.bp4-datepicker abbr[title],
abbr[data-original-title] {
    cursor: auto;
    border-bottom: none;
}

.bp4-datepicker .DayPicker-Day.DayPicker-Day--selected {
    background-color: var(--intent-primary) !important;
}

.bp4-overlay-scroll-container .bp4-overlay-content {
    position: fixed;
}

.create-modal .bp4-dialog-header {
    color: white;
    padding: 20px 20px 0 20px;
    box-shadow: none !important;
}

.create-modal .bp4-dialog-header h4 {
    font-size: 1.3rem;
}

.create-modal {
    width: 900px;
}

.create-modal .bp4-dialog-body {
    color: white;
    display: flex;
    padding: 15px;
}

.create-plot .dialog-body {
    flex-direction: column;
}

.create-modal .bp4-dialog-body .row {
    display: flex;
    margin-left: 0;
}

.dialog-column {
    margin-top: 15px;
    border: 1px #8a96a0 solid;
    padding: 20px;
    width: 400px;
    height: 310px;
}

.bp4-dialog-body .row {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.dialog-select .bp4-input {
    margin-bottom: 15px;
}

.dialog-selected {
    flex: 1;
    display: flex;
    flex-direction: column;

    .disclaimer {
        font-size: 12px;
        color: #b0b8c5;
    }
}

.dialog-selected .insight-selected {
    width: 350px;
    border-radius: 5px;
    margin: 5px 0;
    display: inline-flex;
    justify-content: space-between;
    color: white;
}

.insight-button {
    margin-left: calc(50% - 69px);
    margin-top: 30px;
}

@include custom-media-query("screen and (max-height: 775px)") {
    .historical-widget {
        .mini {
            display: block;
            height: 100px;
        }

        .main-panel {
            background-color: var(--background-panel);
        }

        .chart-container {
            height: calc(75vh - 170px);
            margin: 25px;
        }

        .chart-container.scatter {
            height: calc(100% - 100px);
            margin-top: 20px;
        }
    }

    .panel-title div.left .tabs {
        display: block;
    }

    .map-widget div.left .tabs {
        display: block;
    }

    .historical-widget .main-panel .map-widget {
        display: flex;
        flex-direction: column;

        #map {
            height: calc(100% - 50px);
        }
    }

    .panel-title .left span {
        display: none;
    }

    .historical-widget .main-panel .map-widget .panel-title {
        display: flex;
    }
}

@media (max-height: 500px) {
    .historical-widget {
        .mini {
            display: none;
        }

        .chart-container {
            height: 100%;
        }
    }
}

@media (max-height: 325px) {
    .historical-widget {
        .chart-container {
            display: none;
        }
    }
}
