@import "../../../styles/Text";

$weather-exclusion-widget-header-height: 102px;

.weather-exclusions-widget-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;

    .weather-exclusions-widgeth-header-container {
        width: 100%;
        height: $weather-exclusion-widget-header-height;
        padding: 35px;
        font-size: 18px;
        font-weight: 300;

        .weather-exclusion-description {
            height: 28px;
            padding-top: 10px;
            font-size: 14px;
            color: #bfccd6;
            overflow: hidden;
            @extend %text-ellipsis;

            .column-name {
                font-style: italic;
            }
        }
    }

    .weather-exclusions-widget-body-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: calc(100% - #{$weather-exclusion-widget-header-height});

        .weather-exclusions-widget-table-container {
            width: fit-content;
            height: 100%;

            .nl-table-container .nl-tables-wrapper {
                border: none;
            }
        }
    }
}
