$map-height: 300px;

.panel.results-view {
    display: flex;
    flex-direction: row;
    padding: 20px;

    .panel-container,
    &.panel-container {
        border: none;
    }

    .map-trace-container {
        width: 100%;

        .map-container {
            width: 100%;
            min-width: 300px;
            height: $map-height;

            .map {
                height: 100%;
            }
        }
    }

    .detail-container {
        height: calc(100% - $map-height);
        overflow-y: hidden;
        padding-top: 20px;

        .fill-height {
            height: 100%;
            box-sizing: content-box;
        }
    }
}
