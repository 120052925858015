.table-section {
    width: 100%;
    height: 100%;
    padding: 15px 30px 15px 30px;
    border-left: 1px solid var(--accent-dark);

    .perf-table {
        width: 100%;
        height: calc(100% - 20px);
    }

    .perf-table-benchmark-footnote {
        height: 15px;
        margin-top: 5px;
        float: right;
        font-size: 1.1rem;
        font-style: italic;
    }
}
