@import "../../../styles/Text";
@import "../../../styles/Color";

.speed-consumption-warranties-form-container {
    .sc-form-table-container {
        max-width: unset;
        width: 100%;
    }

    .selection-summary {
        color: $nl-secondary;
    }

    .voyage-duration-exclusion-checkbox {
        display: flex;
        flex-direction: row;
        padding-top: 15px;

        > label {
            align-self: flex-end;
            margin-right: 5px;
            margin-bottom: 12px;
        }
    }

    // TODO: move to nlkit
    .nl-form-label-with-tooltip {
        @extend %tooltip-content;
    }
}
