.port-manager {
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        margin-top: 0;
    }

    .add-port-button {
        width: fit-content;
        margin-bottom: 1em;
    }

    .table-controls-container {
        flex: 1;
    }
}

.port-dialog {
    width: 600px;
}
