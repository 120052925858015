@import "../../styles/Buttons";
@import "../../styles/Color";

.table-controls-container {
    width: 100%;
    height: 80%;

    .table-controls {
        & > div {
            margin-bottom: 1em;
        }

        .page-size-button {
            margin: 0 4px;
        }
    }

    .table-wrapper {
        overflow-y: auto;
        height: inherit;

        .table {
            width: max-content;
            min-width: 100%;

            thead {
                tr:not(.divider) {
                    height: 60px;
                    th {
                        top: 0;
                    }
                }

                .divider {
                    height: 1px;
                    th {
                        z-index: 2;
                        top: 60px;
                    }
                }

                th {
                    background-color: var(--background-panel);
                    position: sticky;
                    z-index: 1;
                    vertical-align: bottom;
                }
            }

            tbody tr {
                height: 40px;

                &:nth-child(odd) td {
                    background-color: var(--background-panel);
                }

                td {
                    background-color: var(--background-table-row-light);

                    &.fixed {
                        position: sticky;
                        z-index: 1;

                        &.last-fixed {
                            box-shadow: 0.5px 0 0 $nl-gray-3;
                        }
                    }
                }
            }

            th.delete-column,
            td.delete-column,
            th.edit-column,
            td.edit-column {
                width: 30px;
                padding: 0;
                border: 0;

                .delete-button,
                .edit-button {
                    padding: 0;
                }
            }

            th,
            td {
                font-weight: 300;
                padding: 0 1em;
                text-align: left;

                button {
                    padding-left: 0;

                    &.file-download-button {
                        @extend %primary-button-minimal;
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    .pagination-controls {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1em;

        div {
            margin-bottom: 0.5em;
        }
    }
}
