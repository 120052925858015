@import "../../styles/Color.scss";
@import "../../styles/Text.scss";
@import "../../styles/Media.scss";

$emissions-panel-padding: 50px 25px;

.emissions-panel-container {
    padding: $emissions-panel-padding;
    flex: 1;

    table {
        width: 100%;
        height: 100%;

        th:not(:first-child),
        td:not(:first-child) {
            border-left: 2px solid $nl-dark-gray-1;
        }

        .emissions-panel-col-header,
        .emissions-panel-metric-title {
            @extend %heading-medium;
        }

        .emissions-panel-metric-title {
            @extend %tooltip-content;
        }

        .emissions-panel-metric-unit {
            @extend %unit;
            color: $nl-secondary;
        }

        .emissions-panel-metric {
            @extend %heading-xl;
        }

        .prev-year {
            text-align: center;
            padding: 0 20px;
        }

        .ytd {
            display: grid;
            grid-template-columns: 1fr 60px;
            place-items: center;

            .emissions-panel-trend {
                justify-self: flex-start;
                display: flex;
                align-items: center;

                &.favorable {
                    color: var(--intent-success);
                }
                &.unfavorable {
                    color: var(--intent-error);
                }

                .emissions-panel-trend-value {
                    margin-left: 5px;
                }
            }
        }
    }
}

.emissions-panel-minimal {
    display: flex;

    div {
        margin-right: 25px;
    }

    .time-label {
        @extend %heading-large;
    }

    .data {
        .metric {
            @extend %heading-large;
        }

        .unit {
            @extend %space-left-italic;
        }

        .unit,
        .label {
            @extend %unit;
            color: $nl-secondary;
        }

        .label {
            font-style: normal;
        }
    }
}

@include custom-media-query("screen and (max-height: 775px)") {
    .emissions-panel-container {
        padding: 15%;
    }
}
