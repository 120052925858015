@import "../Color";

// #region Legacy Colors
$blue-dark-accent: #182026;
$nl-dark-gray-3: #293742; // BP3 dark-gray3
$nl-intent-primary-rgb: 45, 114, 210;
// #endregion

// #region Theme Colors
.nautilus {
    --accent-dark: #{$blue-dark-accent};
    --background-chart-overlay: rgba(44, 55, 65, 0.9);
    --background-dialog: #{$nl-dark-gray-2};
    --background-dialog-header: #{$nl-dark-gray-3};
    --background-dropdown: #{$nl-dark-gray-3};
    --background-drawer: #{$nl-dark-gray-1};
    --background-inset: #{$nl-dark-gray-2};
    --background-nav: #{$nl-dark-gray-2};
    --background-nav-sub: #{$nl-dark-gray-1};
    --background-nav-active: #{$nautilus-blue};
    --background-nav-hover: #{$nl-dark-gray-1};
    --background-panel: #{$nl-dark-gray-3};
    --background-site: #{$blue-dark-accent};
    --background-table-container: #{$nl-dark-gray-3};
    --background-table-row-dark: #{$nl-dark-gray-3};
    --background-table-row-light: #{$nl-dark-gray-4};
    --background-table-row-hover: #{$nl-dark-gray-2};
    --border-table: 1px solid #{$nl-dark-gray-1};
    --color-button-success: #{$nl-intent-success};
    --color-icon-active: #14ccbd;
    --color-link: #{$nl-intent-primary};
    --ha-feature: #{$nl-gray-5};
    --ha-feature-background: #3d4a54;
    --intent-danger: #{$intent-danger};
    --intent-primary: #{$nl-intent-primary};
    --intent-primary-background: #{$nautilus-blue};
    --intent-primary-background-hover: rgba(#{$nautilus-blue-rgb}, 0.8);
    --intent-primary-background-minimal-hover: rgba(#{$nl-intent-primary-rgb}, 0.2);
    --item-active: #{$nl-intent-primary};
    --item-active-background: rgba(45, 114, 210, 0.2);
    --scrollbar-color: #{$nl-dark-gray-1};
    --scrollbar-background: #{$nl-dark-gray-4};
    --vo-rec-selected: #33404c;
}
// #endregion
