@import "./Color";
@import "./Scrollbar";
@import "./Text";
@import "./themes/danelec-dark";
@import "./themes/nautilus-legacy";
@import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

html {
    font-size: 10px;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font-nl-main;

    &.danelec {
        font-family: $font-danelec;
    }

    * {
        @extend %nl-scrollbar;
    }
}

.app {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background-color: var(--background-site);
}

// #region Plottable overrides

.plottable .axis text {
    fill: #bfccd6;
}
.plottable .axis-label text {
    fill: #bfccd6;
    text-transform: none;
}

.plottable .gridlines line {
    stroke: #ccc;
}

// #endregion

// #region BlueprintJS overrides

.bp4-form-content {
    .bp4-form-helper-text {
        font-size: 14px;
    }
}

.bp4-tooltip {
    max-width: 300px;

    .bp4-popover-content {
        background: #30404d !important;
        color: #e1e8ed !important;
    }

    .bp4-popover-arrow-fill {
        fill: #30404d !important;
    }
}

// Tabs
.bp4-tab-list {
    .bp4-tab-indicator {
        background-color: var(--intent-primary);
    }

    .bp4-tab:not([aria-disabled="true"]):hover,
    .bp4-tab[aria-selected="true"] {
        color: var(--intent-primary);
    }
}

// Spinner
.bp4-spinner {
    &.bp4-intent-primary .bp4-spinner-head {
        stroke: var(--intent-primary);
    }
    &.bp4-intent-danger .bp4-spinner-head {
        stroke: var(--intent-danger);
    }
}

// Dropdown menu items
.bp4-menu {
    .bp4-menu-item.bp4-active,
    .bp4-menu-item.bp4-active:hover {
        color: var(--item-active);
        background-color: var(--item-active-background);
    }

    .bp4-menu-item.bp4-intent-primary.bp4-active {
        background-color: $nautilus-blue;
    }

    .bp4-menu-item:hover {
        background-color: $nl-light-gray-hover;
    }
}
.bp4-popover .bp4-popover-content,
.bp4-menu,
.bp4-menu-header {
    background-color: var(--background-dropdown) !important;
}

.nl-date-picker-popover.bp4-popover .bp4-popover-content {
    background-color: $nl-dark-gray-3 !important;
}

// Toggle switch
.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator {
    background-color: var(--intent-primary) !important;
}

// Dialog box
.bp4-portal .bp4-dialog-container .bp4-dialog {
    background-color: var(--background-dialog);

    .bp4-dialog-header {
        background-color: var(--background-dialog-header);
    }
}

.bp4-portal {
    z-index: 1000 !important;
}

.bp4-overlay {
    z-index: 1000;
}

.bp4-dark .bp4-button.bp4-minimal {
    align-items: center;
}

// #endregion

/**
 * Ensure the compass surrounding div does not
 * block map pointer events.
 **/
.compass-widget {
    pointer-events: none;
}

.compass-widget * {
    pointer-events: all;
}

.open > .dropdown-menu {
    z-index: 10001;
}

table {
    border-collapse: collapse;
}

a.no-style,
a.no-style:hover {
    color: inherit;
    text-decoration: inherit;
}
