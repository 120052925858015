.detail {
    display: flex;
    flex-direction: row;

    .icon-container {
        display: flex;
        align-items: center;

        .icon {
            margin: 0 0.5em 0 0;
            color: var(--color-icon-muted);

            &.hidden {
                visibility: hidden;
            }
        }
    }

    .label {
        width: 130px;
    }

    .value {
        flex: 1;
    }

    .unit {
        margin-left: 0.5em;
        padding-right: 1em;
    }
}
