@import "../Color";

// #region Danelec Colors
// Reds
$d-red-140: #23100e;
$d-red-130: #25110f;
$d-red-120: #291210;
$d-red-110: #2e1512;
$d-red: #331714;
$d-red-rgb: 51, 23, 20;
$d-red-90: #472e2b;
$d-red-80: #5c4543;
$d-red-70: #705d5b;
$d-red-60: #857472;
$d-red-50: #998b8a;

// Oranges
$d-orange-120: #992f19;
$d-orange-110: #cc3e22;
$d-orange: #ff4e2a;
$d-orange-rgb: 255, 78, 42;
$d-orange-90: #fc6f50;
$d-orange-80: #f98e78;
$d-orange-70: #f7ae9e;
$d-orange-60: #f4cec5;
$d-orange-50: #f2ded9;

// Neutrals
$d-neutral-dark-gray-1: #3b2a26;
$d-neutral-dark-gray-2: #4d3b33;
$d-neutral-dark-gray-3: #5d4a40;
$d-neutral-dark-gray-4: #6e5a4f;
$d-neutral-dark-gray-5: #7e6b5e;
$d-neutral-mid: #bdb6b0;
$d-neutral-mid-75: #cfc9c4;
$d-neutral-mid-50: #e0dcd8;
$d-neutral-mid-25: #e9e6e3;
$d-neutral-light: #f1eeec;
$d-neutral-light-75: #f6f4f2;
$d-neutral-light-50: #faf9f9;
$d-neutral-light-25: #fff;

// #endregion

// #region Theme Colors
:root,
.danelec {
    --accent-dark: #{$d-red-120};
    --background-chart-overlay: rgba(#{$d-red-rgb}, 0.8);
    --background-dialog: #{$d-red-140};
    --background-dialog-header: #{$d-red};
    --background-dropdown: #{$d-red-90};
    --background-drawer: #{$d-neutral-dark-gray-1};
    --background-inset: #{$d-red};
    --background-nav: #{$d-red};
    --background-nav-sub: #{$d-red-140};
    --background-nav-active: #{$d-red-80};
    --background-nav-hover: #{$d-red-90};
    --background-panel: #{$d-red-140};
    --background-panel-light: #{$d-red-90};
    --background-site: #{$d-neutral-dark-gray-1};
    --background-table-container: #{$d-red-90};
    --background-table-row-dark: #{$d-red};
    --background-table-row-light: #{$d-red-90};
    --background-table-row-hover: #{$d-red-120};
    --border-table: 1px solid #{$d-red-140};
    --color-breadcrumb: #{$d-neutral-mid};
    --color-breadcrumb-hover: #{$d-orange};
    --color-button-success: #{$d-orange};
    --color-icon-active: #{$d-orange};
    --color-icon-muted: #{$d-red-50};
    --color-link: #{$d-orange-70};
    --color-secondary: #{$d-neutral-mid};
    --color-unit-light: #{$d-neutral-mid};
    --ha-feature: #{$d-neutral-mid-50};
    --ha-feature-background: #{$d-neutral-dark-gray-2};
    --intent-danger: #{$intent-danger};
    --intent-primary: #{$d-orange};
    --intent-primary-background: #{$d-orange-120};
    --intent-primary-background-hover: #{$d-orange-110};
    --intent-primary-background-minimal-hover: rgba(#{$d-orange-rgb}, 0.1);
    --intent-success: #{$nl-intent-success};
    --intent-error: #{$nl-intent-danger};
    --item-active: #{$d-neutral-light};
    --item-active-background: rgba(#{$d-orange-rgb}, 0.35);
    --scrollbar-color: #{$d-red-120};
    --scrollbar-background: #{$d-red-90};
    --vo-rec-selected: #{$d-red};
}
// #endregion
