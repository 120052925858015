@import "../../nlcomponents/nl";
@import "../../styles/Text";
@import "../../styles/Events";
@import "../../styles/Color";

.half-pie-chart-card-container {
    .header-container {
        .header {
            @extend %tooltip-content;
        }
    }

    .more-info {
        text-decoration: underline;
    }

    .event-container {
        display: flex;
        align-items: center;

        .event-sub-header {
            @extend %body-small;
            margin-left: 4px;
            color: $nl-tertiary;
        }
    }

    .value-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .half-chart-with-bound-labels-container {
            position: relative;
            width: 100%;
            height: 50%;

            .half-chart-with-bound-labels {
                display: flex;
                align-items: baseline;
                width: 100%;
                height: 100%;

                .bound-label {
                    width: 30px;
                    @extend %body-xs;
                    flex-basis: 30px;
                    color: $nl-tertiary;
                }

                .lower-bound {
                    text-align: right;
                }

                .half-pie-chart-total-value-container {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .divide {
        width: 1px;
        height: 100%;
        float: left;
        background-color: var(--background-drawer);
    }

    .divide.horizontal {
        display: block;
        height: 1px;
        margin: 1em 0.5em;
        padding: 0;
        border: 0;
        border-top: 1px solid $nl-dark-gray-1;
    }

    .metrics-breakdown {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}
