@import "../../styles/Text.scss";

.value-label-container {
    display: flex;

    &.stacked {
        flex-direction: column;
        margin-bottom: 0.5em;

        .value {
            margin-bottom: 0.25em;
        }

        .unit::before {
            content: "\A";
            white-space: pre;
        }
    }

    &.inline {
        flex: 1 0 25%;

        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        margin-top: 0.5em;

        .value {
            margin-right: 0.25em;
        }

        .unit::before {
            content: " ";
        }
    }

    .value {
        @extend %body-large-bold;
    }
    .label {
        @extend %unit-light;
        font-style: normal;
        margin-left: 0;
        margin-right: 1em;
    }
}
