@import "../../styles/Buttons";
@import "../../styles/Color";

.cii-simulator-form {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 50;
    height: 100%;
    max-width: 40%;
    background-color: var(--background-drawer);
    padding: 2em;

    .title-cont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .close-button {
            padding: 0;
            color: white;
            border-radius: 50%;
            margin-left: 2em;

            &.hidden {
                display: none;
            }
        }
    }

    .scenario {
        .title-cont {
            justify-content: flex-start;

            h3 {
                margin-right: 1em;
            }

            .target-switch {
                margin-bottom: 0;
            }
        }

        .radio-option {
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            input {
                margin-top: 0;
                margin-right: 0.5em;

                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }
        }

        .sub-option {
            margin-left: 2em;
            margin-bottom: 1em;

            .target-label {
                display: block;
                margin-bottom: 0.5em;
            }

            .input {
                width: 50%;
            }
        }
    }

    .conditions {
        .input-field {
            display: flex;
            align-items: center;
            line-height: 2;

            > label,
            .label {
                width: 40%;

                &.tooltip label {
                    padding-bottom: 1px;
                    border-bottom: 1px dashed $nl-primary;

                    &:hover {
                        cursor: help;
                    }
                }
            }

            .input {
                margin-right: 1em;
                width: 40%;

                &:disabled {
                    cursor: not-allowed;
                }

                &.error {
                    border-color: $nl-danger;
                }
            }
        }
    }

    .button-cont {
        display: flex;
        align-items: center;

        .run-button {
            @extend %pill-button;
            @extend %primary-button;
        }

        .sm-spinner {
            display: inline-block;
            height: 100%;
            margin-top: 0.5em;
            margin-left: 1em;
        }
    }

    .error-msg {
        color: $nl-danger;
        margin: 0 0 0 1em;

        &:last-child {
            margin: 0.5em 0;
        }
    }
}
