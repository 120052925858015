@import "../../../styles/Color";
@import "../../../styles/Text";
@import "../../../styles/Media";

.boil-off-warranties-form-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    @include custom-media-query("screen and (max-width: 1599px)") {
        flex-direction: column;
    }

    .section-container {
        flex: 1;

        .section-header {
            .header-text {
                margin-right: 5px;
            }

            .cargo-capacity-info-icon {
                color: $nl-tertiary;
                cursor: pointer;
            }

            // TODO:
            // consider apply this style globally
            // (maybe already been removed in the latest blueprint version?)
            // This adjusts wrapping span elements to not take more height
            // than its content (icon/button)
            .bp4-popover-wrapper {
                .bp4-popover-target {
                    display: inline-flex;
                }
            }
        }
    }
}
