@import "../styles/Color";
@import "./nl";

.nl-date-range-picker-wrapper {
    width: 200px;
    margin-right: 10px;

    .bp4-popover-target {
        width: 100%;

        .nl-date-range-picker-button {
            @extend %nl-tag-button;
        }
    }
}

.nl-date-range-picker-popover-wrapper {
    .nl-date-range-picker-container {
        display: flex;
        flex-direction: column;

        .date-selector-container {
            position: relative;
            display: flex;
            flex-direction: row;

            .date-range-picker-end-date-disable {
                position: absolute;
                top: 0;
                right: 0;
                padding: 5px 5px;
                width: 220px;
                height: 100%;
                background-clip: content-box;
                background-color: $nl-dark-gray-4;
                opacity: 0.6;
            }
        }

        .date-selector-footer-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 0 15px;
        }
    }
}
