@import "../../styles/Text";
@import "../../styles/Color";

$card-header-container-height: 30px;
$card-header-container-margin-bottom: 5px;

.card-container {
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: var(--background-panel);

    &.chart-container {
        display: flex;
        flex-direction: column;

        .header-container {
            width: 100%;
            height: $card-header-container-height;
            margin-bottom: $card-header-container-margin-bottom;
        }

        .value-container {
            width: 100%;
            height: calc(100% - #{$card-header-container-height} - #{$card-header-container-margin-bottom});
            flex: 1;
        }
    }

    .header-container {
        display: flex;
        flex-direction: column;

        .header {
            @extend %heading-small;
            color: $nl-secondary;
        }

        .sub-header {
            @extend %body-xs;
            color: $nl-tertiary;
        }
    }

    .value-container {
        position: relative;
    }
}

.tooltip-content {
    padding: 15px;
    white-space: break-spaces;

    .more-info {
        margin-left: 2px;
        text-decoration: underline;
    }
}
