@import "../../../styles/Color.scss";

.JsonInput,
.JsonInput * {
    box-sizing: border-box;
}

.JsonInput {
    margin: 0;
    min-width: 400px;

    .icon-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0.5em;

        .error {
            color: var(--intent-error);
            text-decoration: underline dotted;

            .error-icon {
                margin-left: 0.5em;
            }
        }
    }
}

.edit-block {
    display: block;
    max-height: 100%;
    width: 100%;
    outline: 1px dashed $nl-light-gray-1;
    padding: 10px;
    background: transparent;
    color: $white;
    line-height: inherit;
}
