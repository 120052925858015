.fleet-location-monitor {
    height: 100%;
    width: 100%;

    .branding {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 15px 30px;
        border-radius: 0 0 15px 0;
        // This z-index value is excessive, but I don't want it to be overridden
        z-index: 100;

        .name {
            font-size: 1.25em;
            color: #000;
        }

        .spacer {
            background-color: rgba(0, 0, 0, 0.2);
            width: 1px;
            height: 50px;
            margin: 0 10px;
        }
    }

    .map {
        height: 100%;
    }
}
