@import "../../styles/Color";
@import "../../styles/Text";

.outerDiv {
    position: relative;
    bottom: 0;
    z-index: 1000;
    padding: 20px 20px 0 20px;
    padding-bottom: 10px;
    width: 100%;
    height: 240px;
    color: white;
    background-color: #303741;
}

.noon-switch {
    float: right;
}

.outerDiv .chart-title {
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.chart-container {
    flex: 1;
    &.interactable {
        cursor: pointer;
        cursor: -webkit-grab;
        cursor: -moz-grab;
    }
}

.chart-container.voyage {
    height: calc(100% - 30px);
}

.plottable {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.plottable .baseline line {
    stroke: white;
    stroke-linecap: round;
}

.plottable .axis text {
    @include apply-font($font-nl-roboto);
}

.plottable .selected-point path {
    fill: white;
    stroke: white;
    opacity: 1;
    size: 10;
    stroke-width: 3px;
}

.left-axis {
    .table {
        right: 0px !important;
        left: auto !important;
    }
}

.noonChart {
    color: white;
    height: calc(100% - 90px);
    width: 100%;

    .scatter-plot path {
        opacity: 0;
    }

    .main-plot path {
        stroke: #3bc0d4;
    }
}

.plottable foreignObject {
    overflow: visible;
}

.chart-tooltip {
    position: relative;
    z-index: 100;
    margin-left: -100px;
    line-height: 16px;
    background-color: #202d37;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    padding: 7px;
}

.chart-tooltip .header {
    text-align: center;
}

.chart-tooltip .values {
    display: flex;
    margin-top: 10px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
}

.chart-tooltip .values div {
    width: 50%;
    margin-left: 10px;
    text-align: center;
}

.chart-tooltip .values div:last-child {
    margin-right: 10px;
}

.chart-tooltip .labels {
    display: flex;
    margin-top: 5px;
    color: #b0b8c5;
    font-size: 12px;
    align-items: center;
    justify-content: center;
}

.chart-tooltip .labels div {
    width: 50%;
    text-align: center;
}

.plottable .selection-box-layer .selection-area {
    fill: #ccc;
    fill-opacity: 0.2;
}

// Keep stroke colors aligned with ./constants.ts/STROKE_COLORS
.unit-0 text {
    stroke: $nl-turquoise-4;
}
.unit-1 text {
    stroke: $nl-violet-4;
}
.unit-2 text {
    stroke: $nl-orange-3;
}
.unit-3 text {
    stroke: $nl-gold-5;
}
.unit-4 text {
    stroke: $nl-cobalt-4;
}
.unit-5 text {
    stroke: #fff;
}
.unit-6 text {
    stroke: $nl-indigo-4;
}
.unit-7 text {
    stroke: $nl-lime-5;
}

.unit-merged text {
    stroke: #bfccd6;
}

.scatter {
    cursor: pointer;

    .chart-render-container {
        .plottable:first-child {
            width: 15%;
            height: calc(100% - 60px);
        }

        .plottable:last-child {
            width: 0%;
        }

        .plottable:nth-child(2) {
            width: 85%;
        }
    }
}

.line-chart {
    .chart-render-container {
        .plottable:first-child {
            width: 10%;
            height: calc(100% - 53px);
        }

        .plottable:last-child {
            width: 100px;
            height: calc(100% - 53px);
        }
    }
}

.charter-widget {
    .charts-panel {
        .chart-render-container {
            .plottable:first-child {
                width: 10%;
                height: calc(100% - 53px);
            }

            .plottable:last-child {
                width: 15%;
                height: calc(100% - 53px);
            }

            .plottable:nth-child(2) {
                width: 75%;
            }
        }
    }
}

.chart-event {
    height: 10px;
    width: 10px;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 50%;
    z-index: 100;
    display: flex;

    .event-count {
        margin: auto;
    }
}

@media (max-width: 1400px) {
    .scatter {
        .chart-render-container {
            .plottable:first-child {
                width: 20%;
            }

            .plottable:nth-child(2) {
                width: 80%;
            }
        }
    }

    .line-chart {
        .chart-render-container {
            .plottable:first-child {
                width: 15%;
                height: calc(100% - 44px);
            }

            .plottable:last-child {
                width: 50px;
                height: calc(100% - 44px);
            }
        }
    }

    .charter-widget {
        .charts-panel {
            .chart-render-container {
                .plottable:first-child {
                    width: 15%;
                    height: calc(100% - 53px);
                }

                .plottable:last-child {
                    width: 22.5%;
                    height: calc(100% - 53px);
                }

                .plottable:nth-child(2) {
                    width: 62.5%;
                }
            }
        }
    }
}

.event-context-menu {
    z-index: 150;
    position: absolute;

    .create-event-container {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: #30404d;
        box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);

        width: 500px;
        padding: 15px 30px;

        .bp4-input {
            resize: none;
            margin: 15px 0px;
        }

        .create-event-header {
            margin: 5px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .create-event-is-maintenance-checkbox {
            width: 100%;
            display: flex;
            .bp4-popover-wrapper {
                margin-left: 10px;
            }
        }
        .create-event-footer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .bp4-button {
                width: 100px;
                font-size: 12px;
            }
            .bp4-button:first-child {
                width: auto;
                color: #bfccd6;
            }

            align-items: center;

            div {
                color: #bfccd6;
            }
        }
    }
}
