@import "../../../../styles/Color";

.voyage-overview {
    display: flex;
    flex-direction: row;
    height: 200px;
    width: 100%;
    background-color: var(--background-panel);

    .left-panel {
        margin-right: 1px;

        &.grid-support {
            display: grid;
            align-items: baseline;
            grid-template-columns: 1fr 4fr 1fr 4fr;
            grid-gap: 10px;
        }

        &:not(.grid-support) {
            // This is for IE ONLY
            display: inline-block;

            label,
            span {
                float: left;
                height: 25%;
                margin-bottom: 2%;
                display: flex;
                align-items: flex-end;
            }

            label {
                width: 12%;
                margin-right: 10px;
            }
            span {
                width: calc(38% - 10px);
            }
        }
    }

    .kpi-container {
        display: flex;
        flex-direction: row;

        & > * {
            margin: 15px;
            max-width: 200px;
            padding-top: 50px;
        }
    }

    .boiloff-rating {
        display: flex;
        flex-direction: column;

        .actual-value {
            display: flex;
            align-items: baseline;
            font-size: 14px;

            .actual-percent {
                font-size: 36px;
            }
        }

        .boiloff-explanation {
            color: #b9cdd8;
            text-decoration-line: underline;
            text-decoration-style: dotted;
            cursor: pointer;
        }

        .bp4-popover-wrapper {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.boiloff-explanation-tooltip {
    div {
        padding: 5px;
    }

    .value {
        color: white;
    }
}

@media (max-width: 1400px) {
    .voyage-overview {
        flex-direction: column;
        height: 400px;

        .left-panel {
            max-height: 200px;
            margin-right: 0;
        }

        .kpi-container {
            border-top: 1px solid $nl-dark-gray-1;
            justify-content: space-around;
        }
    }
}
