@import "../../styles/Buttons";
@import "../../styles/Text";

.BoilOffSimulationsModule {
    display: flex;
    flex-direction: column;
    padding: 5px;
    height: 100%;
    min-width: 800px;

    .spinner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    a button#back-button {
        @extend %primary-button;
    }

    .overview {
        flex: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: auto;
        padding: 10px 20px;
        font-size: 16px;

        .item:not(:last-child) {
            color: var(--color-breadcrumb);

            &.breadcrumb-link {
                text-decoration: underline;

                &:hover {
                    color: var(--color-breadcrumb-hover);
                }
            }
        }

        .separator {
            margin: 0 10px;
            color: var(--color-breadcrumb);
        }

        .right-content {
            @extend %footnote-secondary;
            font-style: normal;
        }
    }

    .overview + & {
        margin-top: 2px;
    }

    .panel {
        height: 100%;
        overflow-y: hidden;

        .panel-header .title {
            padding-left: 5px;

            h1 {
                margin: 0;
                font-size: 16px;
            }

            p {
                @extend %footnote-secondary;
                margin-bottom: 0;
                font-style: italic;
            }
        }

        .panel-body {
            padding: 20px;
        }

        .new-simulation-button,
        .new-passage-plan-button,
        .toggle-button {
            @extend %primary-button-minimal;
        }
    }
}
