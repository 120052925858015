.performance-tooltip {
    text-align: start;
    line-height: 16px;
    width: 160px;
    padding: 3px;

    .date {
        font-size: 14px;
        text-align: center;
    }

    .score {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;

        .value {
            font-size: 16px;
            font-weight: bold;
            margin-right: 5px;
        }

        .fraction {
            display: inline-flex;
            flex-direction: column;
            font-size: 10px;
            text-align: center;

            .numerator {
                border-bottom: 1px solid white;
            }
        }
    }

    span.value {
        float: right;
        font-size: 14px;
    }
}
