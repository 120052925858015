@import "../../styles/Color.scss";

.dropdown-container {
    span.bp4-popover-target {
        display: inline-flex;
    }

    .environment-label {
        color: $nl-gray-5;
    }
}
