$benchmark-widget-header-height: 100px;
$benchmark-widget-footer-height: 40px;

.benchmark-widget-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;

    .benchmark-widget-header-container {
        width: 100%;
        height: $benchmark-widget-header-height;
        padding: 35px;
        font-size: 18px;
        font-weight: 300;
    }

    .benchmark-widget-body-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-height: calc(100% - #{$benchmark-widget-header-height} - #{$benchmark-widget-footer-height});

        .benchmark-widget-table-container {
            width: 100%;
            height: 100%;

            .nl-table-container .nl-tables-wrapper {
                border: none;

                .benchmark-name-cell-content {
                    display: flex;

                    .benchmark-name {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .benchmark-widget-footer-container {
        width: 100%;
        height: $benchmark-widget-footer-height;
        padding: 5px 0;
    }
}
