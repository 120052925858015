@import "../styles/Color";

$empty-content-indicator-color: $nl-gray-1;
$selected-content-indicator-color: $nl-light-gray-5;

// Currently designed for usage as filters in nl-table sub header section
%nl-tag-button {
    width: 100%;
    height: 24px;
    min-height: 24px;
    line-height: 25px;
    font-size: 12px;
    cursor: pointer;

    $bp-background-override-color: rgba(16, 22, 26, 0.3);
    $bp-box-shadow-override-color: rgba(16, 22, 26, 0.4);

    &.bp4-tag {
        color: $empty-content-indicator-color;
        background-color: $bp-background-override-color;
        box-shadow:
            inset 0 0 1px 0 $bp-box-shadow-override-color,
            inset 0 1px 1px 0 $bp-box-shadow-override-color;
    }

    &.disabled {
        cursor: not-allowed;

        span {
            color: $nl-disabled;
        }
    }

    &.selected {
        &.bp4-tag {
            color: $selected-content-indicator-color;
        }
    }
}

/*
 * NlButton Styling
 */
button.reset {
    outline: none;
    background: none;
    border: 0;
    color: inherit;
    cursor: default;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
    -moz-user-select: none;
    -ms-user-select: none;
}

button.nl-button {
    padding: 3px 7px;
    display: inline-block;
    border-radius: 20px;
    border: solid #5671ef 1px;
    color: #5671ef;
    text-transform: uppercase;
    cursor: pointer;
    margin: 5px;
    font-size: 1.2rem;
    background: #2f3742;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

button.nl-button:hover {
    color: white;
}

button.nl-button:disabled {
    border-color: $nl-disabled;
    color: $nl-disabled;
}

/*
 * Noon picker styling
 */
.nl-select {
    font-size: 1.2rem;
    display: flex;
    margin-right: 15px;

    span {
        margin-left: 10px;

        .icon {
            height: 20px;
            margin-top: 5px;
            &:not(.disabled) {
                cursor: pointer;
            }
            &.disabled {
                color: #5c7080;
            }
        }
    }
}

.nl-select > .nl-select-button:disabled {
    background-color: #4a505c;
    cursor: default;
}

.nl-select > .nl-select-button-left {
    border-radius: 3px 0 0 3px;
    border-right: #808fa3 1px solid !important;
}

.nl-select > .nl-select-button-right {
    border-radius: 0 3px 3px 0;
}

.nl-select > .nl-select-button {
    float: left;
    display: inline-block;
    color: #808fa3;
    padding: 3px;
    text-align: center;
    line-height: 9px;
    width: 40px;
    height: 30px;
    cursor: pointer;
    background-color: rgba(16, 22, 26, 0.3);

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.nl-select > .nl-select-field {
    float: left;
    display: flex;
    justify-content: center;
    height: 30px;
    font-size: 13px;
    color: #f1f1f1;
    padding: 7px 10px;
    margin: 0 5px;
    width: 305px;
    background-color: rgba(16, 22, 26, 0.3);
    border-radius: 2px;
}

.no-underline:hover {
    text-decoration: none;
}

.nl-select span.chevron {
    display: inline-block;
    -webkit-transform: scale(1, 2); /* Safari and Chrome */
    -moz-transform: scale(1, 2); /* Firefox */
    -ms-transform: scale(1, 2); /* IE 9 */
    -o-transform: scale(1, 2); /* Opera */
    transform: scale(1, 2); /* W3C */

    font-size: 15px;
    padding-top: 1px;
    margin-left: 0px;
}

.calendar-button {
    background: transparent;
    border: none;
}

.calendar-span {
    color: white;
    font-size: 15px;
    margin-left: 8px;
}

/* Overriding popover and calendar styles */
.bp4-datepicker .DayPicker-Day--disable {
    background: none !important;
    color: rgba(191, 204, 214, 0.5) !important;
    cursor: not-allowed;
}

.bp4-datepicker * {
    outline: none;
}

/**
 * Manually fix ugly date picker month dropdown icon spacing
 */
.bp4-datepicker-caption select + .bp4-icon {
    right: 2px !important;
}

.default-tag {
    margin: 10px;
    background-color: var(--background-panel);
    color: $nl-gray-3;
    padding: 2px 6px;
    border-radius: 2px;
    cursor: default;
}
.admin-tag {
    margin: 10px;
    background-color: $nautilus-blue;
    color: $nl-light-gray-5;
    padding: 2px 6px;
    border-radius: 2px;
    cursor: default;
}
.beta-tag {
    margin: 10px;
    background-color: #23605b;
    color: #00b88a;
    padding: 2px 6px;
    border-radius: 2px;
    cursor: default;
}

.nl-intent-success {
    background-color: $nl-success;
}

.nl-intent-warning {
    background-color: $nl-warning;
}

.nl-intent-danger {
    background-color: $nl-danger;
}
