@import "../../styles/Color.scss";
@import "../../styles/Text.scss";

.alt-scenarios {
    max-width: 65%;

    table.alt-table {
        width: 100%;

        tr:not(:last-child) {
            border-bottom: $bp-table-border;
        }

        th,
        td {
            &:not(:last-child) {
                border-right: $bp-table-border;
            }
        }

        th {
            @extend %unit-light;
            font-style: normal;
        }

        td {
            width: 7dvw;
        }
    }
}
