@import "../../styles/Header";

.vessel-details-widget {
    @extend %page-container-style;
    display: flex;
    flex-direction: column;
    padding: 5px;

    .vessel-details-body {
        @extend %page-body-style;
        padding: 20px 50px;
        background-color: var(--background-panel);

        .vessel-details-content {
            width: 100%;
            height: 100%;
            padding-right: 10px;
            overflow: hidden auto;
        }
    }
}
