@import "../../styles/Color";

.app-header {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    min-height: 60px;
    max-height: 60px;
    padding: 10px;
    border-bottom: 1px solid $nl-dark-gray-1;
    background-color: var(--background-panel);
    z-index: 10;

    .dvi-container {
        display: flex;
        align-items: center;
        padding-right: 1em;
        margin-right: 1em;
        border-right: 1px solid $nl-dark-gray-1;
    }

    .app-header-sections {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .app-header-left-section,
        .app-header-right-section {
            display: flex;
            flex-direction: row;
        }

        .app-header-left-section {
            .event-icon {
                position: relative;

                .unseen-notification {
                    position: absolute;
                    left: 20px;
                    width: 8px;
                    height: 8px;
                    border: solid 2px #e56666;
                    border-radius: 50%;
                    background-color: #e56666;

                    &.hidden {
                        visibility: hidden;
                    }
                }
            }
        }

        .app-header-right-section {
            .icon {
                margin-right: 7px;
                margin-left: 1px;
            }

            .event-icon-svg {
                width: 16px;
                height: 16px;
            }
            .bp4-disabled {
                .event-icon-svg {
                    filter: opacity(0.5);
                }
            }

            .app-header-user-dropdown-button {
                line-height: 30px;
            }
        }
    }
}

.header-node {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;

    &.voyage-plan-dropdown {
        min-width: 100px;
    }

    > span:first-child {
        min-width: 60px;
    }
}

$filter-height: 48px;

.header-dropdown-menu {
    background-color: var(--background-dropdown) !important;
    padding: 8px;
    max-height: 500px;
    overflow-y: auto;

    &.vessel-popover {
        padding-top: $filter-height;
    }

    .filter-input-container {
        background-color: transparent !important;
        position: fixed;
        top: 0;
        height: $filter-height;
        width: 90%;
        padding-top: 8px;

        .bp4-input-action {
            height: 100%;
            display: flex;
            align-items: center;
            padding-right: 8px;
        }
    }
}
