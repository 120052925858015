@import "../../styles/Color";
@import "../../styles/Text";

.vessel-detail-table-container {
    @include apply-font($font-nl-avenir-next);
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 50%;
    height: fit-content;
    margin: 20px 20px 40px;

    .vessel-detail-table-header {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #394b59;

        .vessel-detail-table-title {
            font-size: 16px;
            font-weight: 300;
        }
    }

    .vessel-detail-table-table {
        width: 500px;
        height: 100%;

        tr:first-child {
            td {
                box-shadow: none !important;
            }
        }

        .vessel-detail-table-row {
            height: fit-content;

            td {
                padding: 4px 10px 4px 4px;
                font-size: 14px;
                vertical-align: bottom;
            }

            .vessel-detail-table-name {
                width: 250px;
                color: $nl-gray-5;
                font-weight: 300;
            }

            .vessel-detail-table-value {
                width: 250px;
                color: $nl-light-gray-5;
            }
        }
    }
}
