@import "../../styles/Buttons";
@import "../../styles/Header";

.vessel-settings-view {
    @extend %page-container-style;
    display: flex;
    flex-direction: column;
    padding: 5px;

    .button {
        @extend %pill-button;

        &.cancel-button {
            margin-right: 0.5em;
        }

        &.save-button,
        &.edit-button {
            @extend %primary-button;
        }
    }
}
