@import "../../styles/Buttons";
@import "../../styles/Input";
@import "../../styles/Media";
@import "../../styles/Text";

.vessel-settings-form {
    padding: 0 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    overflow-y: auto;

    .section {
        margin-top: 1em;

        &:first-of-type {
            margin-top: 0;
        }

        .title-container {
            margin-bottom: 0.5em;
            display: flex;
            flex-direction: row;
            align-items: center;

            .toggle-button {
                height: fit-content;
                margin-left: 0.5em;
            }
        }

        .section-content {
            margin-top: 0.5em;
        }
    }

    h2 {
        margin: 0;
        font-size: 16px;
    }

    h3 {
        margin: 0 0 1em;
        font-size: 14px;
    }

    h2.no-margin-bottom,
    h3.no-margin-bottom {
        margin-bottom: 0;
    }

    .subtitle {
        @extend %footnote-secondary;
        margin: 0 0 1em;
    }

    .section-recommendation-button {
        @extend %primary-button-minimal;
        font-size: 12px;

        svg {
            height: 12px;
        }
    }

    .section-content.collapsed {
        height: 0;
        overflow: hidden;
    }

    .subsection {
        margin: 0 2em;
    }

    .column-container {
        display: flex;
        flex-direction: row;
        padding: 0 2em;

        &:not(:first-child) {
            margin-top: 1em;
        }

        .column {
            width: 400px;
            margin: 0 1em 1em 0;

            &:last-of-type {
                width: auto;
            }

            &.full {
                width: 100%;
            }

            .label-wrap {
                display: inline-block;
                width: 200px;

                &.has-tooltip label {
                    border-bottom: 2px dotted $nl-primary;

                    &:hover {
                        cursor: help;
                    }
                }
            }

            @include custom-media-query("screen and (max-width: 1415px)") {
                .label-wrap {
                    width: 150px;
                    min-width: 150px;
                }
            }

            .form-input-row {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 1em;
                }

                h3 {
                    font-size: 1em;
                }

                .label.error {
                    border-bottom: 1px solid $nl-danger;
                }

                .full {
                    width: 100%;
                }

                .input {
                    @extend %number-hide-arrows;
                    width: 100px;
                    height: 30px;

                    &:disabled:hover {
                        cursor: not-allowed;
                    }

                    &.error {
                        border-color: $nl-danger;
                    }

                    &.text {
                        width: 200px;
                    }

                    &[type="checkbox"] {
                        height: 1.5em;
                        width: 1.5em;
                        margin-left: 0;
                    }

                    &.select {
                        width: auto;
                    }
                }

                .text-input-group {
                    display: flex;
                    align-items: center;

                    > :last-child {
                        margin: 0;
                    }

                    .input {
                        width: 250px;
                        margin-right: 10px;
                    }
                }

                .input-unit {
                    display: flex;
                    align-items: center;

                    &.array {
                        flex-direction: column;

                        .array-field {
                            margin-bottom: 0.5em;
                        }
                    }

                    .unit {
                        @extend %unit-light;
                        margin-left: 0.5em;
                        font-size: 0.9em;
                        letter-spacing: 0.1em;
                    }
                }
            }
        }
    }

    @include custom-media-query("screen and (max-width: 1300px)") {
        .column-container {
            flex-direction: column;

            .label-wrap {
                width: 250px;
            }
        }
    }
}
