@import "../../styles/Color";
@import "../../styles/Text";

$nl-table-actionables-height: 30px;
$nl-table-actionables-margin-bottom: 10px;

.nl-table-light-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .nl-table-light-actionables-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        height: $nl-table-actionables-height;
        line-height: $nl-table-actionables-height;
        margin-bottom: $nl-table-actionables-margin-bottom;

        > * {
            margin-right: 5px;
        }
    }

    .nl-table-light-header-container {
        width: 100%;
        height: fit-content;
        overflow-x: hidden;
        // This will add scrollbar buffer without actually showing the scrollbar
        overflow-y: scroll;

        tr {
            display: flex;
            flex-direction: row;

            th {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
        }
    }

    .nl-table-light-body-container {
        flex: 1 1;
        width: 100%;
        height: fit-content;
        overflow-x: hidden;
        overflow-y: scroll;

        .nl-table-light {
            table-layout: fixed;

            tr:first-child {
                td {
                    box-shadow: none !important;
                }
            }
        }
    }

    .nl-table-light {
        width: 100%;

        th {
            padding: 4px 10px 4px 4px;

            .nl-table-light-header,
            .nl-table-light-sub-header {
                font-size: 1.1rem;
            }

            .nl-table-light-sub-header {
                color: #bfccd6;
                font-style: italic;
            }
        }

        .nl-table-light-cell {
            @include apply-font($font-nl-roboto);
            padding: 4px 10px 4px 4px;
            font-weight: 300;
        }

        tr {
            &:nth-child(odd) {
                td {
                    background-color: var(--background-panel);
                }
            }
            &:nth-child(even) {
                td {
                    background-color: var(--background-table-row-light);
                }
            }

            th,
            td {
                .favorable-value {
                    color: #6fb994;
                }
                .unfavorable-value {
                    color: #e56666;
                }
            }
        }
    }
}
