@import "../styles/Color";

$menu-width: 355px;
$menu-min-width: 180px;
$menu-max-height: 190px;

.nl-filterable-select-container {
    padding: 15px 0;

    .nl-filterable-select-input {
        width: $menu-width;
        min-width: $menu-min-width;
        max-height: $menu-max-height;
        margin-bottom: 5px;
    }
    .filterable-select-menu {
        width: $menu-width;
        min-width: $menu-min-width;
        max-height: $menu-max-height;
        padding: 5px;
        color: white;
        overflow: auto;
    }
}
