@import "../.../../../../styles/Header";
@import "../.../../../../styles/Color";
@import "../.../../../../styles/Text";

.dialog-header-container {
    display: flex;
    justify-content: space-between;
}

.email-maker-toaster {
    z-index: 1001 !important;
}

.email-maker-modal {
    width: 800px;
    height: 700px;
}

.email-maker-body-container {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    margin: unset;
}

.email-multiselect-container {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;

    .leading-text {
        width: 25px;
        margin-right: 10px;
    }

    .email-multiselect {
        flex-grow: 1;
    }
}

.email-multiselect-footer {
    display: flex;
    align-items: baseline;
    margin-bottom: 25px;

    .save-recipients {
        padding-left: 0px;
    }
}

.notice-type-row {
    margin-bottom: 10px;
}

.recipients-container {
    margin-bottom: 10px;

    .recipients-header-container {
        margin-bottom: 10px;

        .recipients-header {
            display: flex;
            margin-bottom: 5px;
            align-content: flex-end;

            .recipients-heading {
                @extend %heading-medium;
                margin-right: 10px;
            }
        }

        .selection-direction {
            @extend %body-small;
            color: $nl-secondary;
        }
    }
}

.content-container {
    .content-heading {
        @extend %heading-medium;
        margin-bottom: 10px;
    }

    .email-maker-row {
        display: flex;
        align-items: baseline;
        margin-bottom: 20px;

        .subject-input {
            width: 100%;
        }
    }

    .content-leading-text {
        width: 80px;
        margin-right: 20px;
    }

    .email-body {
        height: 230px;
        width: 100%;
        resize: none;
    }

    .action-buttons-container {
        text-align: right;
        .generate-email {
            width: 140px;
            margin-right: 15px;
        }

        .cancel {
            width: 90px;
        }
    }
}

.saved-rec-link {
    text-decoration: underline;
    font-weight: bold;
    padding-left: 0px;
    padding-right: 0px;
}
