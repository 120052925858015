@import "../../../styles/Text";

.tce-optimization-widget.non-ideal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 30px;
}

.bp4-datepicker-caption select + .bp4-icon {
    right: 2px !important;
}

.pdf-display {
    padding: 24px 12px;
    width: 300px;

    .probability-stats {
        padding: 6px;
        color: #bfccd6;

        .prob-percent {
            @include apply-font();
            display: inline-block;
            width: 5ch;
            color: #f5f8fa;
            text-align: right;
        }

        .arrival-time {
            color: #f5f8fa;
        }

        .probability-warning {
            margin-top: 6px;
            font-size: 12px;
            font-style: italic;
            color: #bfccd6;
        }
    }

    .pdf-chart {
        height: 200px;
        width: 100%;
        .hidden {
            display: none;
        }
        .plottable:last-child {
            width: 10%;
        }
    }
}
.instruction-recommendation-table {
    display: flex;
    .instruction-recommendations {
        display: flex;
        flex-direction: column;
        min-width: 700px;
        .instruction-recommendations-header {
            border-bottom: 1px solid #4a555e;
            font-weight: lighter;
            margin: 0 20px;
            padding: 20px 0 10px 0;
            display: flex;
            justify-content: space-between;
        }
        .instruction-recommendations-body {
            display: flex;
        }
        .error-item {
            margin-bottom: 20px;
        }
    }
}

.instruction-recommendation-column {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 255px;
    padding: 20px;
    margin: 20px;

    &.instruction-recommendation-label-column {
        padding-top: 95px;
        width: 265px;
    }
}

.instruction-recommendation-selected {
    background-color: var(--vo-rec-selected);
}

.eta-value-picker {
    padding: 5px;

    .instruction-value-save {
        padding: 5px;
    }

    .timezone-picker {
        padding: 10px 15px 5px 15px;
        & > label {
            margin-bottom: 0;
        }
    }
}

.tce-edit-value-button {
    margin-left: 5px;
    position: absolute;
    top: -5px;
}

.specialized-row-value {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.instruction-value-save {
    text-align: right;
}

.instruction-recommendation {
    border-radius: 5px;

    .instruction-header {
        height: 30px;
        border-bottom: 1px solid #4a555e;
        margin-bottom: 15px;

        & > label {
            color: #bfccd6;

            & > .instruction-header-label {
                display: flex;

                & > .weather-warning {
                    margin-left: 0.5em;
                }
            }
        }
    }

    .instruction-value .bp4-spinner {
        height: 60px;
    }

    .instruction-value-container {
        display: flex;
        justify-content: space-between;
        align-content: center;

        .instruction-value {
            @include apply-font();
            font-size: 24px;
            font-weight: normal;
            min-height: 48px;
            height: 32px;
            margin-bottom: auto;
        }

        .instruction-value.error {
            span {
                margin-top: 5px;
                text-align: center;
            }
            align-items: center;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .instruction-edit-container {
            display: flex;
        }
    }

    .instruction-value-subtitle {
        font-size: 12px;
        font-style: italic;
        color: #bfccd6;
    }
}

.email-maker-menu {
    padding: 6px;
    textarea {
        width: 100%;
    }

    .generate-email {
        text-align: right;
    }
}

.yoy-data {
    display: grid;
    grid-template-columns: 3fr 2fr;

    .timeframe {
        margin-right: 1em;
    }
}

.divider {
    color: $nl-gray-3;
    margin: 0 0.3em;
}

.bp4-dark table.optimization-table {
    flex: 1;
    width: 100%;

    tbody,
    tr,
    td {
        width: 100%;
    }

    tr {
        &.label-row {
            display: flex;

            &.indent {
                margin-left: 1em;
            }
        }

        &.optimization-header-row {
            &:not(.subsection) > td {
                font-weight: bold;
                padding: 12px 0;
            }

            > td:empty:after {
                content: "\00a0";
            }
        }

        .bp4-popover-wrapper:hover {
            cursor: pointer;
        }
    }

    td {
        padding: 6px 0;
    }

    td,
    .emissions-label {
        display: flex;
        flex-direction: row;
        align-items: center;

        color: #bfccd6;
        font-weight: lighter;
        white-space: nowrap;

        &.small-unit {
            @extend %small-unit;
            justify-content: end;
            margin-left: 1em;
            padding-top: 8px;
        }

        .value-display {
            position: relative;
            display: inline-block;
            width: calc(100% - 30px);
            text-align: end;

            .currency {
                @extend %currency;
                position: absolute;
                left: 0;
            }
        }
    }

    tbody > tr:first-child {
        th,
        td {
            box-shadow: none;
        }
    }
}
