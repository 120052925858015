@import "../../styles/Color";

$icon-button-width: 30px;
$table-actionables-height: 40px;

.voyage-legs-summary-table-container {
    position: relative;
    width: 100%;
    height: 100%;

    .voyage-legs-summary-template-picker-container {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        width: calc(100% - #{$icon-button-width});
        height: $table-actionables-height;
        justify-content: space-between;
        background-color: var(--background-panel);
    }

    .voyage-legs-summary-table {
        height: calc(100% - #{$table-actionables-height});

        .nl-table-container {
            background-color: var(--background-panel);
        }
    }

    .voyage-legs-summary-add-column-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        height: $table-actionables-height;
        padding-top: 10px;
        line-height: 30px;
    }

    .percent-traffic-light-cell-container {
        display: flex;
        flex-direction: row;

        .divider {
            width: 10px;
            color: $nl-gray-3;
        }

        .base-value {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 5px;
            width: 70px;

            &.comparison-value {
                color: $nl-gray-3;
            }

            &.diff-value {
                width: 95px;
            }
        }

        .percent-sign {
            color: $nl-gray-3;
            margin-left: 4px;
        }

        .green-comparison {
            color: $nl-table-cell-text-green;
        }

        .yellow-comparison {
            color: $nl-table-cell-text-orange;
        }

        .red-comparison {
            color: $nl-table-cell-text-red;
        }
    }
}
