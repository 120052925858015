@import "../../../styles/Text";
@import "../../../styles/Color";

$footer-height: 30px;
$footer-padding: 15px;

.speed-consumption-form-container {
    width: 100%;
    height: 100%;

    .speed-consumption-form-body {
        width: 100%;
        height: calc(100% - #{$footer-height} - #{$footer-padding * 2});
        padding: 15px;
        overflow: hidden auto;

        .date-range-section {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
                margin-bottom: 0;
            }

            .date-range-selector-form-wrapper {
                margin-left: 5px;
            }
        }

        .form-card-container {
            margin-top: 15px;
            border: 1px solid $nl-dark-gray-1;
            background-color: $dark-gray4;

            .form-card-header-container {
                @extend %heading-medium;
                padding: 15px;
                border-bottom: 1px solid $nl-dark-gray-1;
                text-transform: uppercase;
            }

            .form-card-body-container {
                padding: 15px 0;
            }

            .section-container {
                margin: 15px;
                max-width: calc(50% - 30px);
                min-width: 520px;

                .section-header {
                    @extend %heading-medium;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid $nl-gray-1;
                }

                .section-body {
                    padding-right: 15px;
                }
            }
        }
    }

    .speed-consumption-form-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: $footer-padding;
        background-color: $dark-gray3;

        .save-button {
            margin-left: 5px;
        }
    }
}
