@import "../../styles/Color";
@import "../../styles/Text";

.LandingPage {
    background-image: url("./assets/map-static.png");
    background-size: 150%;
    background-position-x: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 350px;
    }

    .NavigationMenu {
        @include apply-font($font-nl-avenir-next);
        background: var(--background-panel);
        margin-top: 32px;
        padding: 32px 32px 24px;
        min-width: 396px;
        max-height: calc(100vh - 300px); // Account for logo, logo margin, and page padding
        overflow-y: scroll;
        border-radius: 3px;
        box-shadow: 0px 4px 12px $nl-light-gray-hover;

        ul {
            list-style: none;
            padding: 0;
        }
    }

    .title {
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        margin: 0 0 8px;
    }

    .subtitle {
        color: $nl-light-gray-5;
        margin-bottom: 24px;
    }

    .vessel {
        padding: 8px 0 12px;
        display: flex;
    }

    .vessel a {
        color: #fff;
        text-decoration: underline;
        flex: 1 0 calc(50% - 20px);
        margin-right: 40px;
    }

    .vessel .vessel-category {
        flex: 1 0 calc(50% - 20px);
        color: $nl-gray-5;
    }

    .vessel + .vessel {
        border-top: 1px solid $nl-dark-gray-1;
    }
}
