@import "../nl";
@import "./NlTableFilter";

.nl-table-number-filter-wrapper {
    @extend %nl-table-filter-wrapper;

    .bp4-popover-target {
        height: 24px;
    }

    .number-filter-tag-button {
        @extend %nl-tag-button;
    }

    .nl-table-number-filter-container {
        width: $nl-table-filter-content-width;
        padding: 10px;

        & > * {
            display: flex;
            flex-direction: column;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}
