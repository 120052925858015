.admin-page {
    h1 {
        font-size: 24px;
    }

    a,
    a:hover {
        color: var(--color-link);
    }

    .nav-head {
        display: flex;
        padding: 21px;
        align-items: center;
        border-bottom: 1px solid var(--accent-dark);

        svg {
            width: 80px;
            height: 80px;
            fill: white;

            &.danelec-brandmark {
                width: 60px;
                margin: 0 10px;
            }
        }
    }

    .nav-head-content {
        margin-left: 14px;
        span {
            font-size: larger;
        }
    }

    .nav-links {
        flex: 1;
        .nav-link {
            display: flex;
            padding-left: 30px;
            padding-right: 10px;
            align-items: center;
            min-height: 55px;
            height: 55px;
            border-bottom: 1px solid var(--accent-dark);
            font-size: 14px;
            cursor: pointer;
            user-select: none;

            &.active {
                background-color: #4f72f7;
                .secondary-label {
                    opacity: 1;
                }
            }

            // not active and on hover
            &:not(.active):not(.disabled):hover {
                background-color: var(--background-panel);
                .secondary-label {
                    opacity: 1;
                }
            }
        }
    }
}

.admin-page-content {
    display: flex;
    width: 100%;
    padding: 36px;
    background-color: var(--background-panel);
    overflow-y: auto;
}
