@import "../../styles/Color";
@import "../../styles/Header";
@import "../../styles/Text";

$border-color: $nl-dark-gray-1;

.data-dictionary-widget {
    @extend %page-container-style;
    padding: 5px;

    .widget-header {
        @extend %page-header-style;
        @include apply-font($font-danelec);
        line-height: 50px;
        font-size: 16px;
        font-weight: 300;
        padding-left: 15px;
        background-color: var(--background-panel);
    }

    .content {
        @extend %page-body-style;
        padding: 0px 50px 0px;
        margin-top: 1px;
        background-color: var(--background-panel);
        overflow-y: auto;

        .bp4-tabs {
            padding-top: 30px;
        }
        .bp4-tab-list > *:not(:last-child) {
            margin-right: 50px;
        }

        .border {
            border-top: 1px solid $border-color;
        }

        table {
            width: 100%;
            margin-bottom: 20px;

            // Complicated border styling rules because of box-shadow in blueprint's Table implementation
            thead.raw-sensor-table tr:last-child th {
                // horizontal above table header
                box-shadow: inset 0 1px 0 0 $border-color;
                // horizontal and right
                &.right-border {
                    box-shadow: inset -1px 1px 0 0 $border-color !important;
                }
            }
            tr:first-child {
                td {
                    // horizontal after table header
                    box-shadow: inset 0 1px 0 0 $border-color !important;
                }
                .right-border {
                    // horizontal and right
                    box-shadow: inset -1px 1px 0 0 $border-color !important;
                }
            }

            tr .right-border {
                // right
                box-shadow: inset -1px 0 0 $border-color;
            }

            td {
                @include apply-font($font-nl-roboto);
                color: white;
                font-weight: 100;
            }
            tbody {
                tr:nth-child(even) {
                    background-color: var(--background-table-row-light);
                }
            }
            th {
                @include apply-font($font-danelec);
                color: #bfccd6;
                font-size: 14px;
                font-weight: 300;
                &[colspan="2"] {
                    text-align: center;
                    font-style: italic;
                }
            }
        }

        .inner-tab-content {
            .data-dictionary-table-header {
                @include apply-font($font-danelec);
                padding: 30px 0px 10px;
            }
            .data-dictionary-table-title {
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
            }
            .data-dictionary-table-subtitle {
                padding: 10px 0px;
                max-width: 600px;
                color: #bfccd6;
                font-size: 14px;
                font-weight: 300;
            }
        }

        .dependency-table-cell {
            display: block;
        }
    }

    .bp4-tabs {
        .bp4-tab-panel {
            margin-top: 8px;
        }
        .bp4-tab {
            &:focus {
                outline: 0;
            }
            &[aria-selected="false"][aria-disabled="false"] {
                color: #bfccd6;
            }
            &[aria-selected="true"],
            &:not([aria-disabled="true"]):hover {
                color: white;
            }
        }
    }
}
