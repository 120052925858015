@import "../../../styles/Color";

.map-banner-layer-container {
    position: absolute;
    top: 5%;
    right: 50px;
    width: 320px;
    height: 30px;
    padding: 0 10px;
    border-radius: 3px;
    font-size: 14px;
    text-align: center;
    color: $nl-dark-gray-2;
    z-index: 10;

    .map-banner-layer-wrapper {
        margin-top: 6px;

        .map-banner-icon {
            margin-right: 5px;
            margin-bottom: -3px;
        }
    }
}
