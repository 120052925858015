@import "./Color";
@import "./Text";

$nav-side-bar-width: 240px;
$nav-side-bar-minimal-width: 65px;

$header-height: 50px;
$footer-height: 50px;

%page-container-style {
    width: 100%;
    height: 100%;
}

%page-header-style {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: $header-height;
}

%page-header-title-style {
    @include apply-font($font-nl-avenir-next);
    font-size: 16px;
    font-weight: 300;
}

%page-body-style {
    width: 100%;
    height: calc(100% - #{$header-height});
}

.dropdown-menu > .disabled div {
    color: #5c7080 !important;
}

.nav-with-company-title {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.nav-side-bar {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: $nav-side-bar-width;
    background-color: var(--background-nav);

    &.minimal {
        width: $nav-side-bar-minimal-width;

        & + .content {
            width: calc(100% - #{$nav-side-bar-minimal-width});
            max-width: calc(100% - #{$nav-side-bar-minimal-width});
        }

        .logo-link-container {
            margin: 0;
            padding-left: 0;
            padding-right: 0;

            svg {
                margin-right: 0;
            }
        }
    }

    & + .content {
        width: calc(100% - #{$nav-side-bar-width});
        max-width: calc(100% - #{$nav-side-bar-width});
        flex: 1 1;
    }

    .collapse-nav-hamburger {
        color: #ced9e0;
        height: 40px;
        padding-top: 10px;
        padding-left: 25px;

        .bp4-icon:hover {
            cursor: pointer;
        }
        &.open {
            padding-left: 210px;
        }
    }

    .nav-links {
        flex: 1;
        overflow-y: auto;

        .nav-link {
            display: flex;
            padding-left: 25px;
            padding-right: 10px;
            &.minimal {
                padding-right: 0px;
            }
            align-items: center;
            min-height: 55px;
            height: 55px;
            border-bottom: 1px solid var(--background-nav-sub);
            font-size: 14px;
            cursor: pointer;
            user-select: none;
            color: #e1e8ed;
            &.active {
                color: #ffffff;
                background-color: var(--background-nav-active);
                .secondary-label {
                    opacity: 1 !important;
                }
            }

            // not active and on hover
            &:not(.active):not(.disabled):hover {
                background-color: var(--background-nav-hover);
                .secondary-label {
                    opacity: 1 !important;
                }
                color: #ffffff;
                .icon {
                    color: #ffffff;
                }
            }

            &.disabled {
                cursor: default;
                color: #5c7080;
            }

            &.expanded .chevron {
                transform: rotate(90deg);
            }

            .icon {
                height: 15px;
                margin-right: 17px;
                color: #bfccd6;

                &.disabled {
                    color: #738694;
                }
                &.active {
                    color: #ffffff;
                }
            }

            .top-level {
                width: 100%;
                div {
                    display: inline;
                    width: 100%;

                    .secondary-label {
                        opacity: 0;
                        float: right;
                    }
                }
                .chevron {
                    float: right;
                    transition: transform 0.2s;
                }
            }

            &.create-report-button {
                .child {
                    width: auto;
                }
            }

            .child {
                display: flex;
                width: 100%;
                justify-content: space-between;

                .secondary-label {
                    display: flex;
                    opacity: 0;
                    width: 35px;
                }
            }
        }
    }

    .bp4-collapse-body {
        max-height: calc(100vh - 600px);
        min-height: 50px;
        overflow: auto;

        .nav-link {
            background-color: var(--background-nav-sub);
            color: #ced9e0;
            min-height: 40px;
            height: 40px;
            padding-left: 50px;

            &:not(.active):not(.disabled):hover {
                color: white;
                background-color: var(--background-nav-hover);
            }

            &.active {
                color: white;
            }

            &.create-report-button * {
                color: var(--color-button-success) !important;
            }

            .icon {
                margin-right: 5px;
            }
        }
    }

    footer {
        .logo-link-container {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid var(--background-nav-sub);
            padding: 10px;

            &:hover {
                background-color: var(--background-nav-hover);
            }
        }

        svg {
            height: 60px;
            width: 60px;
            margin-right: 10px;
            fill: white;
        }

        .help-icon svg {
            height: 16px;
            width: 16px;
        }

        a,
        a:hover {
            line-height: 40px;
            margin-left: 6px;
            color: #bfccd6;
        }
    }
}

.page-not-found {
    display: flex;
    margin-top: 25vh;
    flex-direction: column;
    font-size: 20px;
    font-weight: lighter;
    align-items: center;

    p {
        padding-top: 5px;
        max-width: 650px;
    }
}

.error-page {
    margin-left: calc(50% - 325px);
    color: #bfccd6;

    div {
        display: flex;
        margin-top: 20vh;
        font-size: 16px;
        margin-bottom: 20px;

        span {
            margin-right: 15px;
        }
    }

    p {
        margin-left: 31px;
    }

    a {
        color: #669eff;
    }
}
