@import "~@blueprintjs/core/lib/scss/variables";

$nautilus-blue: #4580e6;
$nautilus-blue-rgb: 69, 128, 230;

$nl-primary: $white;
$nl-secondary: #c5cbd3; // BP4 gray5;
$nl-tertiary: $gray2;

$nl-success: $green3;
$nl-warning: $orange3;
$nl-danger: #cd4246; // BP4 $red3
$nl-disabled: #95989a;

$intent-danger: #cd4246; // BP4 $red3

$nl-unit-light: var(--color-unit-light, #b0b8c5);

$nl-light-gray-1: #b9cdd8;
$nl-light-gray-5: $light-gray5;
$nl-light-gray-hover: rgba(138, 155, 168, 0.15);

$nl-gray-1: #5f6b7c; // BP4 gray1
$nl-gray-3: #8f99a8; // BP4 gray3
$nl-gray-5: #c5cbd3; // BP4 gray5

$nl-blue-5: #8abbff; // BP4 blue5

$nl-dark-gray-1: #1c2127; // BP4 dark-gray1
$nl-dark-gray-2: #202b33; // BP3 dark-gray2
$nl-dark-gray-3: #2f343c; // BP4 dark-gray3
$nl-dark-gray-4: #30404d; // BP3 dark-gray4
$nl-dark-gray-5: #404854; // BP4 dark-gray5

$nl-turquoise-4: $turquoise4; // #13C9BA

$nl-violet-4: $violet4;

$nl-orange-3: $orange3;

$nl-gold-5: $gold5;

$nl-cobalt-4: $cerulean4;
$nl-cobalt-5: $cerulean5;

$nl-indigo-4: $indigo4;

$nl-lime-5: $lime5;

$bp-table-border: solid 1px rgba(255, 255, 255, 0.15);

// Blueprint Intent Colors - https://blueprintjs.com/docs/versions/4/#core/colors.core-colors
$nl-intent-primary: $nl-blue-5;
$nl-intent-success: #72ca9b; // BP4 $green5
$nl-intent-warning: $orange5;
$nl-intent-danger: #fa999c; // BP4 $red5
$nl-intent-none: $nl-primary;

// Nl Table cell text
$nl-table-cell-text-red: $red5;
$nl-table-cell-text-orange: $orange5;
$nl-table-cell-text-green: #72ca9b; // BP4 $green5

$nl-cii-colors: (
    "a": #0ca788,
    "b": #cee93d,
    "c": #ffae35,
    "d": #f34625,
    "e": #b455ff,
);

@mixin nl-cii-bg($grade, $opacity: 1) {
    background-color: map-get($nl-cii-colors, $grade);
    opacity: $opacity;
}

%cii-colors {
    @each $grade, $color in $nl-cii-colors {
        &.cii-#{$grade} {
            @include nl-cii-bg($grade);
            @if $grade == "b" or $grade == "c" {
                color: --black-accent;
            }
        }
    }
}
