@import "./Color";

$nl-scrollbar-size: 12px;

%nl-scrollbar {
    scrollbar-color: var(--scrollbar-color) var(--scrollbar-background);
    scrollbar-track-color: var(--scrollbar-background);
    scrollbar-3dlight-color: var(--scrollbar-background);
    scrollbar-darkshadow-color: var(--scrollbar-background);
    scrollbar-shadow-color: var(--scrollbar-background);
    scrollbar-arrow-color: var(--scrollbar-color);
    scrollbar-face-color: var(--scrollbar-color);
    scrollbar-width: $nl-scrollbar-size;

    &::-webkit-scrollbar {
        width: $nl-scrollbar-size;
        height: $nl-scrollbar-size;
        background: transparent;

        * {
            background: transparent;
        }
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background: var(--scrollbar-color);
    }
}

%show-scrollbar {
    scrollbar-width: initial; /* Firefox */
    -ms-overflow-style: initial; /* IE 10+ */
}

%hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    /* WebKit */
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}
