@import "../../styles/Buttons";
@import "../../styles/Color";
@import "../../styles/Header";
@import "../../styles/Text";

%leg-selector-style {
    .voyage-leg-header-wrapper {
        display: flex;
        flex-direction: row;

        .leg-header-wrapper {
            display: flex;
            flex-direction: row;
        }

        .name {
            min-width: 50px;
        }

        .dates {
            @extend %secondary;
            margin-left: 5px;
        }

        .ports {
            max-width: 150px;
            margin-left: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
        }
    }
}

.voyage-level-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    padding: 10px;
    background-color: var(--background-panel);

    .page-header-title {
        margin-top: 5px;
        margin-right: 10px;
        @extend %page-header-title-style;
    }

    .page-header-left-section,
    .page-header-right-section {
        display: flex;
        flex-direction: row;
    }

    .page-header-left-section {
        @extend %leg-selector-style;
    }

    .page-header-right-section {
        display: flex;
        justify-self: flex-end;
    }
}

.header-dropdown-menu {
    @extend %leg-selector-style;

    .bp4-menu-item.bp4-disabled {
        .voyage-leg-header-wrapper .leg-header-wrapper .dates {
            color: inherit;
        }
    }

    .voyage-selector-menu-items {
        max-height: 300px;
        overflow-y: auto;

        li {
            margin-right: 4px;
        }

        .new-voyage {
            @extend %primary-button-minimal;
        }
    }
}
