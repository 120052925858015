@import "../../components/navigation/VoyageHeader";
@import "../../styles/Buttons";
@import "../../styles/Color";
@import "../../styles/Header";
@import "../../styles/Media";
@import "../../styles/Text";

.planning-form {
    display: flex;
    flex-direction: column;
    @extend %page-container-style;
}
.bp4-disabled .product-model-metadata {
    color: rgba(191, 204, 214, 0.5);
}
.product-model-metadata {
    font-size: 12px;
    color: $nl-light-gray-5;

    .no-product-models {
        font-style: italic;
    }
    .product-model-metadata-item {
        display: flex;
        justify-content: space-between;
        div:first-child {
            margin-right: 20px;
        }
    }
}

.voyage-planning-widget-header {
    display: flex;
    flex-direction: row;
    @extend %page-header-style;
    padding: 10px 0 10px 10px;
    background-color: var(--background-panel);
}

.planning-body {
    @extend %page-body-style;
    padding: 5px;

    .planning-content {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
        color: $nl-light-gray-5;
        background-color: var(--background-panel);
    }

    td .bp4-popover-target {
        display: block;
    }

    input.bp4-input,
    .numeric-input input.bp4-input {
        border-radius: 0;
        background: none;
    }

    .numeric-input .bp4-input-group {
        flex-grow: 1;
    }

    .tooltip-title {
        @extend %tooltip-content;
    }

    .bp4-select select {
        border-radius: 0;
    }

    .intent-danger input,
    .intent-danger input[readonly],
    .intent-danger select {
        box-shadow:
            0 0 0 0 rgba(219, 55, 55, 0),
            0 0 0 0 rgba(219, 55, 55, 0),
            inset 0 0 0 1px #db3737,
            inset 0 0 0 1px rgba(16, 22, 26, 0.15),
            inset 0 1px 1px rgba(16, 22, 26, 0.2) !important;
    }

    :not(.bp4-intent-danger) > input.bp4-input {
        &:not(:focus) {
            box-shadow: none;
        }
        &:disabled {
            color: $nl-light-gray-1;
        }
    }
    .bp4-input[readonly]:focus {
        box-shadow:
            0 0 0 1px #137cbd,
            0 0 0 1px #137cbd,
            0 0 0 3px rgba(19, 124, 189, 0.3),
            inset 0 0 0 1px rgba(16, 22, 26, 0.3),
            inset 0 1px 1px rgba(16, 22, 26, 0.4);
    }

    input,
    select {
        @include apply-font($font-nl-roboto);
        background-color: unset;
        border: none;
        color: white;
        font-size: 13px;
        font-weight: lighter;
        width: 100%;
    }

    .form-container {
        position: relative;

        .form {
            width: 100%;
            height: 100%;
            padding: 30px 30px $footer-height + 10px 30px;
            overflow: hidden scroll;

            .column-container {
                display: flex;
                flex-wrap: wrap;

                .column {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                }
            }
            .voyage {
                .header-section {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 25px;
                }
            }
            @include media-query("lt-md") {
                .voyage-form,
                .column-container .column {
                    width: 100%;
                }
            }
        }

        .planning-footer {
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 15px);
            height: $footer-height;
            padding: 0 30px;
            border-top: 1px solid $nl-dark-gray-1;
            background-color: var(--background-panel);

            .left-hand {
                @include apply-font($font-nl-avenir-next);
                font-size: 12px;
                font-style: italic;
                font-weight: 300;
                color: $nl-light-gray-5;
            }

            .submit {
                button {
                    min-width: 90px;
                    margin-right: 10px;
                }
            }
        }
    }

    .sub-form {
        padding: 20px;
        table {
            width: 100%;
            margin: 20px 0;
        }
        tr.critical-range-indent {
            td:first-child {
                padding-left: 26px;
            }
        }
        td:not(.critical-range) {
            &:first-child {
                padding-right: 20px;
                width: 1px;
                white-space: nowrap;
            }
            &:nth-child(2) {
                border-bottom: 1px solid var(--accent-dark);
            }
            &:last-child {
                border-bottom: 1px solid var(--accent-dark);
                font-size: 11px;
                text-align: left;
                width: 1px;
                padding-left: 5px;
            }

            .header-node {
                width: 100%;
            }

            padding: 3px 0;
        }

        .subtitle {
            margin-top: 7px;
            font-size: 12px;
        }
    }

    .voyage-form {
        display: flex;
        flex-wrap: wrap;
        margin: 1px;
        width: 33%;
    }

    .departures {
        display: flex;
        flex-direction: column;
        width: 450px;
        margin-top: 25px;
        margin-left: 25px;
        div:first-child {
            margin-bottom: 10px;
        }
        input {
            margin-left: 20px;
            border-bottom: 1px solid var(--accent-dark);
            width: 250px;
        }
        .bp4-input-group {
            display: inline-block;
        }
    }

    .leg-data {
        display: flex;
        flex-wrap: wrap;

        & > div {
            &:first-child {
                padding-right: 25px;
            }
            &:last-child {
                padding-left: 25px;
            }

            flex: 1 0 400px;

            table {
                width: 100%;
            }
        }

        .inputs {
            td:not(.checkbox-input) {
                &:first-child {
                    width: 1px;
                    padding-right: 20px;
                    white-space: nowrap;
                }

                &:nth-child(2) {
                    border-bottom: 1px solid var(--accent-dark);
                    width: 100%;
                }

                &:nth-child(3) {
                    font-size: 11px;
                    border-bottom: 1px solid var(--accent-dark);
                    text-align: left;
                    width: 100%;
                    padding-left: 5px;
                }
                padding: 3px 0;
            }
        }
    }
}

.add-leg {
    display: flex;
    padding: 5px 0 10px 0;
    margin: 10px 0;

    hr {
        height: 1px;
        width: 42%;
        border-style: dashed;
        border-color: rgba(255, 255, 255, 0.25);
        margin-top: 14px;
    }

    button {
        white-space: nowrap;
        padding-bottom: 10px;
        color: #53bb91 !important;
        svg {
            color: #53bb91 !important;
        }
    }
}

.leg-departure.leg-departure-full {
    margin-top: 20px;
}

.leg-departure {
    margin-bottom: 20px;
    width: 100%;
    span {
        padding-right: 10px;
    }
    span:nth-child(2) {
        font-weight: lighter;
        color: white;
    }
    svg {
        float: right;
        cursor: pointer;
    }
}

.button {
    @extend %pill-button;
    margin-top: 0;

    &.create-voyage,
    &.create-leg {
        @extend %primary-button-minimal;
    }

    &.save {
        @extend %primary-button;
    }
}

.create-voyage-form {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    color: $nl-light-gray-5;

    .form-fields {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 2fr;
        row-gap: 10px;

        .label.disabled {
            color: $nl-gray-3;

            &:hover {
                cursor: not-allowed;
            }
        }

        .input-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            input {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid var(--accent-dark);
                margin: 0;
                color: white;
                font-size: 15px;
            }
        }
    }

    .create-btn {
        width: 100%;
        margin-top: 10px;
    }
}

.leg-display-container {
    padding: 25px;
    border: 1px solid var(--accent-dark);
    background-color: var(--background-panel);

    .leg-visibility-toggle-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .leg-id-config {
            display: flex;
            align-items: center;
            @extend %leg-selector-style;
        }

        .enable-tce {
            display: flex;
            align-items: center;
        }

        .pull-right {
            margin-left: auto;
        }

        .bp4-control {
            margin-bottom: unset;
        }
    }
}

.date-input-timezone-value {
    display: flex;
    align-items: center;
    height: 30px;
}
