@import "../../styles/Color.scss";

.user-widget {
    height: 100%;
    width: 100%;
    align-items: center;
    h1 {
        display: inline-block;
    }
    .data-updated {
        float: right;
        i {
            font-size: smaller;
            padding-bottom: 4px;
            padding-right: 4px;
        }
    }
    .table-controls > div {
        margin-bottom: 12px;
    }
    .filter-and-create {
        display: flex;
        button {
            min-width: unset;
            white-space: nowrap;
        }
        input {
            margin-left: 12px;
        }
    }
    .page-size-control span {
        vertical-align: middle;
    }
}

.page-size-menu {
    min-width: unset !important;
}

.user-table {
    width: 100%;
    tbody {
        tr:not(:first-child):hover {
            cursor: pointer;
            background-color: var(--background-table-row-light);
        }
        tr:first-child td {
            padding: 0;
            hr {
                margin: 4px 0;
            }
        }
    }
    th button {
        width: 100%;
        white-space: pre;
        span {
            width: unset;
        }
    }
    tr {
        td {
            padding: 8px;
        }
        .company_id,
        .is_super_user,
        .enabled,
        .created_at,
        .updated_at {
            text-align: center;
        }
    }
    caption {
        caption-side: bottom;
        margin-top: 12px;
        & > div {
            margin-bottom: 12px;
        }
    }
    .search-match {
        color: $nl-table-cell-text-green;
    }
}

.magic-link {
    padding: 20px;
    padding-bottom: 0;
    text-align: center;
    textarea {
        width: 100%;
        height: 6em;
        resize: none;
        margin-bottom: 10px;
    }
}

.create-user,
.update-user {
    padding: 8px 20px 20px 20px;
    table {
        width: 100%;
        border-spacing: 0px 16px;
        border-collapse: separate;

        .create-user-company-select-dropdown {
            min-height: 50px;
            max-height: 250px;
            overflow-y: scroll;

            .search-icon {
                margin: 8px 6px 0;
            }
        }
    }
    .field {
        width: 1px;
        white-space: nowrap;
    }
    .value {
        padding-left: 20px;
    }
    .buttons {
        text-align: right;
        button {
            margin-left: 12px;
        }
    }
    .edit-string {
        padding: 6px;
        width: 100%;
    }
}
