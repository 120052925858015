@import "../../styles/Color.scss";
@import "../../styles/Text";

.preview-map {
    flex: 1 0 400px;

    display: flex;
    align-items: center;
    justify-content: center;

    & > .preview-placeholder {
        @extend %body-medium;
        color: $nl-secondary;

        width: 320px;
        text-align: center;
    }
}
