@import "../../styles/Color.scss";

.table-header {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
    pointer-events: none;
    font-size: medium;
    background: $nl-dark-gray-4;
    padding: 10px;
    border-radius: 3px;
    margin: 0px;
}

.content-display-flex {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .access-menu {
        background: $nl-dark-gray-4;

        .user-header {
            border-top: none;
        }
    }
}

.fill-horizontal-space {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.delete-button {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px;
}

.user-select-container {
    width: 30%;
    margin: 0 auto;
    padding-bottom: 30px;
    padding-top: 2px;
    align-items: center;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    flex-grow: 1;
    overflow: hidden;
}

.overflow-scroll {
    overflow-y: auto;
}

.selected-cell {
    display: flex;
    background-color: rgb(83, 187, 145);
}

.selected-cell:hover {
    background-color: #53bb91;
}
