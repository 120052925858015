@import "../../styles/Color";
@import "../NonIdealState";

.weather-exclusions-widget-container {
    width: 100%;
    height: 100%;
    background-color: var(--background-panel);

    &.non-ideal {
        @extend %non-ideal-state-container;
    }
}
