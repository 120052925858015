.checkbox-input {
    display: flex;
    align-items: center;
    padding: 5px 0;

    // This should be the Checkbox component from Blueprint
    // Didn't want to reach in and use blueprint's internal CSS classes since that changes
    > :first-child {
        margin: 5px 0;
    }
}
