#map {
    cursor: default;
    &:active {
        cursor: grabbing;
    }
    &.event-hover {
        cursor: pointer;
    }
}

.route-marker {
    stroke-opacity: 0;
}

.route-marker:hover {
    stroke-opacity: 1;
    fill-opacity: 1;
}

.route-marker.selected {
    stroke-opacity: 1;
    fill-opacity: 1;
}

.event-marker.selected {
    opacity: 0;
}

.ship-name {
    z-index: 999;
    color: white;
    padding: 3px;
    margin: 10px;
    font-size: 13px;
    background-color: rgb(41, 55, 66);
    border-radius: 5px;
    white-space: nowrap;
}

.marker {
    display: flex;
}

.marker-pane {
    z-index: 399;
}

.route-pane {
    z-index: 398;
    g path {
        stroke-width: 1.5px;
    }
    g path.faded {
        stroke-opacity: 0.4;
    }
    g path.selected {
        stroke-width: 2px;
    }
}

.ol-attribution {
    height: unset !important;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    color: #b0b8c5;
    background-color: var(--background-panel) !important;
    opacity: 0.7;

    ul li {
        font-size: 11px;
        text-shadow: none;
        color: #b0b8c5;
    }
}

.event-popup {
    bottom: 0px !important;
}

.gps-tooltip {
    z-index: 1003;
    background-color: rgba(48, 64, 77, 0.2);
    text-align: center;
    position: absolute;
    font-size: 14px;
    color: #202b33;
    border-radius: 3px;
    left: calc(50% - 320px / 2);
    top: 15%;
    width: 320px;
    height: 30px;
}

.no-gps-tooltip {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    .no-gps-tooltip-text {
        background-color: #30404d;
        padding: 10px;
        border-radius: 3px;
    }
    .arrow-bottom {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        padding-bottom: 10px;
        border-top: 10px solid #30404d;
    }
}

.reset-button {
    position: absolute;
    top: 0;
    left: 0;
    margin: 8px;

    .vessel-reset-tag {
        height: 28px;
        padding: 5px;
        opacity: 0.9;
        font-size: 1.1rem;
        font-weight: 600;
        border-radius: 3px;
        color: #b0b8c5;
        background-color: var(--background-panel);
        cursor: pointer;
    }
}

.map-buttons {
    box-sizing: border-box;
    position: absolute;
    margin: 8px;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
    &.bottom {
        top: auto;
        bottom: 0;
        margin: 0;
        width: 100%;
    }

    .bp4-popover-target {
        display: flex;
    }
}

.map-button {
    width: 28px;
    height: 28px;
    opacity: 0.9;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: var(--background-panel);
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    color: #b0b8c5;
}

.ol-zoom {
    top: auto;
    left: auto;
    right: 8px;
    padding: 0;
    bottom: 17px;
    opacity: 0.9;
    background-color: unset;
    &:hover {
        background-color: unset;
    }
    button {
        margin: 0;
        height: 28px;
        width: 28px;
        background-color: var(--background-panel) !important;
        color: #b0b8c5 !important;
        cursor: pointer;
    }
    .ol-zoom-out {
        border-radius: 0 0 3px 3px;
    }
    .ol-zoom-in {
        border-radius: 3px 3px 0 0;
        border-bottom: 1px solid black;
    }
}

.weather {
    .ol-zoom {
        bottom: 57px;
    }

    .ol-attribution {
        bottom: 40px;
    }
}

.zone-layer-legend {
    display: flex;
}
