@import "../../../../styles/Color.scss";
@import "../../../../styles/Header";

// TODO: refactor styling file structure - https://github.com/nautiluslabsco/ln/pull/6095/files#r924930228
.form-field {
    display: grid;
    grid-template-columns: 2fr 4fr;
    align-items: center;
    justify-items: start;

    &.left-indent-sm > :first-child {
        margin-left: 1.5em;
    }

    &.left-indent-md > :first-child {
        margin-left: 3.25em;
    }

    .form-field-title {
        .tooltip-title {
            text-decoration: dotted underline;
        }
    }

    .input-wrapper {
        justify-self: stretch;

        .input-field {
            flex: 1;
            margin: 0;

            .bp4-input-group,
            .bp4-popover-target {
                width: 100%;
            }
        }
    }
}
