@import "../../../../styles/Text";
@import "../../../../styles/Color";

.event-panel-settings-container {
    margin-top: 15px;

    .event-panel-settings-wrapper {
        padding: 0 20px;

        &.event-panel-user-settings-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 5px;

            .event-filter-header {
                @extend %tooltip-content;
                width: 70px;
                color: $nl-gray-5;
            }

            .event-alert-header {
                @extend %tooltip-content;
                width: 80px;
                color: $nl-gray-5;
            }
        }

        &.event-panel-user-settings-body {
            max-height: 500px;
            margin-top: 10px;
            overflow: hidden auto;

            %event-row-style {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 4px;

                &:hover {
                    background-color: $nl-dark-gray-2;
                    cursor: default;
                }
            }

            .event-category-container {
                margin: 5px 0;

                .event-category-row {
                    @extend %event-row-style;
                    width: 100%;
                    margin-bottom: 5px;
                    padding-bottom: 5px;

                    .event-marker {
                        margin: 0 10px;
                    }

                    .event-category-name {
                        flex: 1;
                        font-size: 14px;
                        color: $nl-gray-5;
                    }
                }

                .event-setting-row {
                    @extend %event-row-style;
                    width: calc(100% - 20px);
                    margin-left: 20px;
                    padding-left: 10px;
                    padding-bottom: 6px;

                    .event-display-name {
                        flex: 1;
                    }
                }

                .event-user-settings-checkbox-container {
                    .event-filters {
                        margin-right: 50px;
                    }

                    .event-alerts {
                        margin-right: 30px;
                    }
                }

                .event-user-settings-checkbox-container {
                    .event-user-settings-checkbox {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
