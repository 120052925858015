.detail-panel {
    .detail-tabs {
        padding: 0 0 0 20px;
        display: flex;
        flex-direction: column;

        // "tablist" is specific to BlueprintJS's Tabs - it may change in the future
        div[role="tablist"] {
            text-transform: uppercase;
        }

        // "tabpanel" is specific to BlueprintJS's Tabs - it may change in the future
        .detail-tab[role="tabpanel"] {
            margin-top: 0;
            flex-grow: 1;
            max-height: calc(100% - 30px);
        }
    }
}
