@import "../../styles/Buttons";

.route-planning-selector-modal {
    width: 1100px;
    margin-bottom: 0;
    padding-bottom: 0;

    .dialog-main {
        margin: 24px;
        background-color: var(--background-inset);

        .dialog-body {
            display: grid;
            grid-template-columns: auto 450px;
            height: 400px;
            max-height: 400px;
            margin-bottom: 24px;
        }

        .dialog-footer {
            display: flex;
            justify-content: flex-end;

            .button {
                @extend %pill-button;

                &.add {
                    @extend %primary-button;
                    margin-left: 12px;
                }

                &.upload-route {
                    @extend %primary-button-minimal;
                    word-wrap: none;
                    margin-right: auto;
                    margin-left: 0;
                }
            }
        }
    }
}

.leg-option {
    font-weight: lighter;
    span:first-child {
        margin: 0 6px;
    }
    .bp4-icon {
        float: right;
        cursor: pointer;
    }
    padding: 3px;
    border-radius: 3px;
}
.leg-option:hover:not(.leg-option-selected) {
    background-color: rgba(138, 155, 168, 0.15);
}
.leg-option-selected {
    background-color: var(--item-active-background);
    color: var(--item-active);
}
.leg-option:not(.leg-option-selected) {
    cursor: pointer;
}
.route-item-header {
    display: grid;
    grid-template-columns: 50% auto auto;
    margin: 12px 0;
    width: 550px;
    div:first-child {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    div:not(:last-child) {
        color: #b9cdd9;
    }
}

.route-item {
    border-bottom: 1px solid #505c69;
    .route-item-legs {
        margin: 6px 0;
    }
    > .route-item-header {
        div:last-child {
            font-weight: lighter;
        }
    }
}

.route-menu {
    > .route-item-header {
        color: #bcccd7;
        font-weight: 400;
        margin-bottom: 0;
    }
    .route-list:focus {
        outline: none;
    }
    .bp4-spinner {
        width: 100%;
        height: 100%;
    }

    .bp4-menu-header {
        padding-bottom: 10px;
    }

    .bp4-menu-item {
        height: 25px;
        align-items: center;
    }
}
